import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import CloudinaryMedia from '@stubhub/react-cloudinary';

import './index.scss';

const SCROLL_TIMEOUT = 6000;
const REDIRECTION_TIMEOUT = 500;
const CAROUSEL_TIMEOUT = 100;
const STYLE_TIMEOUT = 1000;
const DEFAULT_TIMEOUT = 40;

const PERFORMERS_LENGTH_ADJUST = 0.5;
const MARGIN_LEFT_ADJUST = -656;

class JumbotronCarousel extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {};
    this.scrollTimer = null;
    this.performerList = [];
  }

  /*
   * Function to collect variant display data from Jumbotron AB test,
   *   returns modified Jumbotron data obj
   */
  assignABVariantValues(augmentedPerfList, toggleData) {
    const validAbAttributeChanges = [{}, {}, {}, {}, {}];

    // Identify which attributes are valid and assign to correct object
    for (const variantData in toggleData) {
      const index = parseInt(variantData && variantData.slice(-1));
      const variantAttribute = variantData.slice(0, -1);

      if (Number.isInteger(index) && index > 0 && index <= validAbAttributeChanges.length) {
        if (toggleData[variantData] !== '') {
          validAbAttributeChanges[index - 1][variantAttribute] = toggleData[variantData];
        }
      } else {
        return false;
      }
    }

    // Assign modified attributes to original jumbotron content
    validAbAttributeChanges.forEach((slot, index) => {
      if (augmentedPerfList && augmentedPerfList.length && index < augmentedPerfList.length - 1) {
        for (const attribute in slot) {
          // Should do nothing if attributes doesn't match
          /* istanbul ignore else */
          if (attribute in augmentedPerfList[index]) {
            augmentedPerfList[index][attribute] = slot[attribute];
          }
        }
      }
    });

    return augmentedPerfList;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {performers} = this.props;
    if (performers !== nextProps.performers) {
      this.setPerformers(nextProps.performers);
    }
  }

  setPerformers(performers) {
    const {performers: initialPerformers, toggle} = this.props;
    let perfList = performers || initialPerformers;
    const toggleData = toggle;
    let augmentedPerfList = [];

    // Enter this condition if any values are passed from variants
    if (toggleData) {
      augmentedPerfList = this.assignABVariantValues(perfList, toggleData);
      if (augmentedPerfList) {
        perfList = augmentedPerfList;
      }
    }

    this.performerList = perfList.concat(perfList);

    const bannerImageTransforms = {
      enableCloudinary: true,
      defaultImg: 'defaultBlank.jpg',
      useNamedTransforms: false,
      width: 656,
      height: 220,
    };

    const cloudinaryFolder = '/homepage/jumbotron';
    this.performerList = this.performerList.map((performer, index) => {
      if (performer.image) {
        if (performer.image.indexOf('https://') === 0) {
          const imageParts = performer.image.split(cloudinaryFolder);
          if (imageParts.length === 2) {
            performer.image = cloudinaryFolder + imageParts[1];
          }
        }
        if (performer.image.indexOf('/') !== 0) {
          performer.image = `/${performer.image}`;
        }
      }

      return (
        <div
          className="jumbotron-carousel--performer"
          key={`jumbotron-carousel--performer-${index}`}
          onClick={this.redirectToEntityPage.bind(this, performer, index % 5)}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span className="jumbotron-carousel--performer-title" dangerouslySetInnerHTML={{__html: performer.name}} />
          <div className="jumbotron-carousel--performer-cta">{performer.buttonCopy}</div>
          <CloudinaryMedia
            className="jumbotron-carousel--performer-image"
            src={performer.image}
            alt={performer.imgAlt}
            extension={performer.fileExtension}
            {...bannerImageTransforms}
          />
        </div>
      );
    });

    /* istanbul ignore next */
    if (__CLIENT__ && window) {
      if (this.scrollTimer) {
        clearInterval(this.scrollTimer);
      }
      this.scrollTimer = setInterval(() => {
        this.scrollCarousel('right', false);
      }, SCROLL_TIMEOUT);
    }
  }

  redirectToEntityPage(entity) {
    if (entity.webUri && typeof entity.webUri.indexOf === 'function') {
      if (entity.webUri.indexOf('/') !== 0) {
        const parts = entity.webUri.split('/');
        let skipPrefix = 0;
        if (entity.webUri.indexOf('https:') === 0) {
          skipPrefix = 3;
        } else if (entity.webUri.indexOf('www.') === 0) {
          skipPrefix = 1;
        }
        entity.webUri = `/${parts.slice(skipPrefix).join('/')}`;
      }
      if (entity.webUri.indexOf('/campaign') === 0 && entity.webUri[entity.webUri.length - 1] === '/') {
        entity.webUri = entity.webUri.slice(0, -1);
      }
    }

    /* istanbul ignore next */
    setTimeout(() => {
      if (__CLIENT__ && window) {
        window.location = `${window.location.origin}${entity.webUri}`;
      }
    }, REDIRECTION_TIMEOUT);
  }

  /* istanbul ignore next */
  scrollCarousel(direction, userClicked) {
    if (!__CLIENT__) {
      return;
    }

    const performers = document.querySelectorAll('.jumbotron-carousel--performer');
    const container = document.querySelector('.jumbotron-carousel--performers');
    if (!performers || !performers.length || !container) {
      clearInterval(this.scrollTimer);

      return;
    }

    if (userClicked) {
      clearInterval(this.scrollTimer);
      this.scrollTimer = setInterval(() => {
        this.scrollCarousel('right', false);
      }, SCROLL_TIMEOUT);
    }

    if (direction === 'right') {
      performers[0].style.width = '0';

      setTimeout(() => {
        const [performer] = performers;

        container.removeChild(performer);
        container.appendChild(performer);
        performer.style.width = '656px';
      }, STYLE_TIMEOUT);
    } else {
      const performer = performers[performers.length - 1];

      performer.style.width = '0';
      container.removeChild(performer);
      container.insertBefore(performer, performers[0]);

      setTimeout(() => {
        performer.style.width = '656px';
      }, DEFAULT_TIMEOUT);
    }
  }

  scrollCarouselByClick = debounce((direction) => {
    /* istanbul ignore next */
    this.scrollCarousel(direction, true);
  }, CAROUSEL_TIMEOUT);

  componentWillUnmount() {
    clearInterval(this.scrollTimer);
  }

  render() {
    const {performers} = this.props;
    if (!performers) {
      return null;
    } else if (this.performerList.length === 0) {
      this.setPerformers();
    }

    const marginLeft = (performers.length + PERFORMERS_LENGTH_ADJUST) * MARGIN_LEFT_ADJUST;

    return (
      <div className="Homepage__JumbotronCarousel" namespace="app.home">
        <div className="jumbotron-carousel-controls">
          <div className="jumbotron-carousel--scroll-left" onClick={() => this.scrollCarouselByClick('left', true)}>
            <span />
          </div>
          <div className="jumbotron-carousel--scroll-right" onClick={() => this.scrollCarouselByClick('right', true)}>
            <span />
          </div>
        </div>
        <div className="jumbotron-carousel--performers" style={{marginLeft: `${marginLeft}px`}}>
          {this.performerList}
        </div>
      </div>
    );
  }
}

JumbotronCarousel.defaultProps = {
  className: '',
  deviceType: 'desktop',
};

JumbotronCarousel.propTypes = {
  /**
   * The Class Name used to override the Styling for the component.
   *
   */
  className: PropTypes.string,

  /**
   * Device type
   */
  deviceType: PropTypes.string,

  /**
   * Array of performers
   */
  performers: PropTypes.array,
  toggle: PropTypes.array,
};

export default JumbotronCarousel;
