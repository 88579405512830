import React from 'react';
import PropTypes from 'prop-types';

import {ControllerContainer} from '@stubhub/react-store-provider';

import controllerFactory from './controller';

import EntityList from './';

const EntityListContainer = function (props) {
  const {type, title: initialTitle} = props;
  let title;
  if (initialTitle.props) {
    title = initialTitle.props.id;
  } else {
    title = initialTitle.replace(/ /g, '_'); // Unit tests might not have title object
  }

  return (
    <ControllerContainer
      namespace={`${type}:title-${title}`}
      factory={controllerFactory}
      component={EntityList}
      {...props}
    />
  );
};

EntityListContainer.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
};

export default EntityListContainer;
