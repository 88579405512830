import React from 'react';
import {FormattedMessage} from 'react-intl';

import EntityListContainer from '../../modules/react-entity-list/container';
import GenreModuleContainer from '../../modules/react-genre-module';
import {CMA_DEFAULTS, DEFAULT_GEOID_BY_STORE} from '../../utils/constants';

import MainContent from './main/mainContent';

// Currently the Genre Modules are shown only for US(.com)
const shouldShowGenreModules = (storeId) => {
  const storeIds = [1];

  return storeIds.indexOf(storeId) !== -1;
};

export const buildHomePageContent = ({
  genreModuleVariantsFeatureToggle,
  desktopGenreModules,
  title,
  popularTitle,
  categoryId,
  urlEventStartDate,
  urlEventEndDate,
  storeId,
  deviceType,
  performerByGenreApiEnabled,
}) => {
  // If category/hub page, display old performer cards
  if (!genreModuleVariantsFeatureToggle || categoryId) {
    return (
      <MainContent
        title={title}
        popularTitle={popularTitle}
        categoryId={categoryId}
        eventStartDate={urlEventStartDate}
        eventEndDate={urlEventEndDate}
      />
    );
  }
  if (shouldShowGenreModules(storeId)) {
    // Genre module titles
    const sportsTitle = <FormattedMessage id="Homepage.GenreModule.Sports.Title" defaultMessage="Sports" />;
    const concertsTitle = <FormattedMessage id="Homepage.GenreModule.Concerts.Title" defaultMessage="Concerts" />;
    const theaterTitle = <FormattedMessage id="Homepage.GenreModule.Theater.Title" defaultMessage="Theater & Comedy" />;
    const searchAPIEnabled = performerByGenreApiEnabled && deviceType === 'phone';
    const entityContainerType = 'performer';
    const radius = 50;

    return desktopGenreModules ? (
      <div className="HomePage__entityListContainer">
        <GenreModuleContainer genreModuleCategory="concerts" radius={radius} />
        <GenreModuleContainer genreModuleCategory="sports" radius={radius} />
        <GenreModuleContainer genreModuleCategory="theater" radius={radius} />
      </div>
    ) : (
      <div className="HomePage__entityListContainer">
        <EntityListContainer
          type={entityContainerType}
          categoryId={28}
          title={sportsTitle}
          passUserInfo={false}
          useCarousel={false}
          eventStartDate={urlEventStartDate}
          eventEndDate={urlEventEndDate}
          performerAPIExpEnabled={false}
          searchAPIEnabled={searchAPIEnabled}
          radius={radius}
        />
        <EntityListContainer
          type={entityContainerType}
          categoryId={1}
          title={concertsTitle}
          passUserInfo={false}
          useCarousel={false}
          eventStartDate={urlEventStartDate}
          eventEndDate={urlEventEndDate}
          performerAPIExpEnabled={false}
          searchAPIEnabled={searchAPIEnabled}
          radius={radius}
        />
        <EntityListContainer
          type={entityContainerType}
          categoryId={174}
          title={theaterTitle}
          passUserInfo={false}
          useCarousel={false}
          eventStartDate={urlEventStartDate}
          eventEndDate={urlEventEndDate}
          performerAPIExpEnabled={false}
          searchAPIEnabled={searchAPIEnabled}
          radius={radius}
        />
      </div>
    );
  }

  return <MainContent title={title} popularTitle={popularTitle} categoryId={categoryId} />;
};

/**
 * Retrieves the geographic ID based on the provided parameters.
 *
 * @param {boolean} hideCbtEvents - Flag indicating whether to hide cross-border trade events.
 * @param {boolean} showPopularInCountry - Flag indicating whether to show popular events in the country.
 * @param {string} shstore - The store identifier used to determine the default geographic ID.
 * @returns {number|undefined} The geographic ID based on the provided parameters, or undefined if not applicable.
 */
export function getGeoId(hideCbtEvents, showPopularInCountry, shstore) {
  const fallbackGeoId = hideCbtEvents ? CMA_DEFAULTS.GEO_ID : undefined;

  return showPopularInCountry ? DEFAULT_GEOID_BY_STORE[shstore] : fallbackGeoId;
}
