import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {FormattedMessage, FormattedNumber, FormattedDate} from 'react-intl';

import {Icon} from '@stubhub/bamboo';
import {ENTITY_TYPE_EVENT, ENTITY_TYPE_PERFORMER, ENTITY_TYPE_CITY, ENTITY_TYPE_VENUE} from '@stubhub/entity-helper';
import CBTRedirection from '@stubhub/react-cbt-redirection';
import {CloudinaryImage, helper} from '@stubhub/react-cloudinary';
import {getEventDate, getEventTime} from '@stubhub/react-event-helper';
import Link from '@stubhub/react-formatted-link';

import {buildEventsJSONLD, SCHEMA_ORG_PREFIX, checkJSONLDAndMicroDataEnabled} from '../../helpers/jsonld-helper';
import {DEFAULT_IMAGE_CONFIG} from '../../utils/cloudinaryConfigs';
import Card, {CardImage} from '../react-card';
import EventMicroData from '../react-event-micro-data';
import FormattedLocation from '../react-formatted-location';

import {entityCardMapping} from './entityCardMapping';

import './entity_card_carousel.scss';
import './entity_card_stacked.scss';
import './index.scss';

const GB_COUNTRY = 'GB';

function getImageUrl(/* istanbul ignore next */ entityData = {}) {
  /*
   * The images array is the correct field to look for the image URL.  The top-level
   * imageUrl field is deprecated.
   */
  const imgUrl = entityData.image
    ? entityData.image.url
    : entityData.images && (entityData.images[0].urlSsl || entityData.images[0].url);

  return imgUrl;
}

function getContent(entityData = {}, type, hideEventVenue, showSubGenre, context, className, isRedirectModalEnabled) {
  const {name, venue = {}, ticketInfo = {}, images = []} = entityData;
  const imageUrl = getImageUrl(entityData);
  const image = images.length ? images[0] : entityData.image || {};
  const publicIdSrc = image.cloudinaryPublicId || '';
  // Get the display flags to show attributes based on mapping helper entityCardMapping.js
  const {showEntityGenre, showEntityTime, showEntityName, showEventVenue, showEventLocation, showFromPrice} =
    entityCardMapping[type];
  const venueName = !hideEventVenue && showEventVenue && venue.name;

  const globalRegistry = context && context.globalRegistry;
  const isPriceIncludeFees = globalRegistry && globalRegistry.getFeatureValue('common.priceIncludeFees');

  const eventDate = getEventDate(entityData);
  const eventDateMarkup = eventDate && !isNaN(eventDate) && (
    <FormattedDate value={eventDate} weekday="short" month="short" day="2-digit" year="numeric" />
  );

  const cardData = entityData;
  const src = imageUrl;
  let microDataMarkup = {};
  let jsonld;
  let microDataToggle;
  if (type === ENTITY_TYPE_EVENT) {
    if (cardData) {
      cardData.webURI = !cardData.webURI ? `/event/${cardData.id}` : cardData.webURI;
    }
    ({microDataToggle} = checkJSONLDAndMicroDataEnabled(globalRegistry, entityData) || {});
    const webTLD = globalRegistry.getDefaultWebTLD() /* istanbul ignore next */ || 'com';
    const redirectionDomain =
      globalRegistry.getPropertyValue(`tb.eventpage.url.domain.${webTLD}`) /* istanbul ignore next */ || '';

    /* istanbul ignore else */
    if (microDataToggle) {
      const eventJSONLD = buildEventsJSONLD([entityData], webTLD, 1, redirectionDomain);
      /* istanbul ignore else */
      if (eventJSONLD && eventJSONLD.length && eventJSONLD[0]) {
        [jsonld] = eventJSONLD;
        microDataMarkup = {
          itemType: `${SCHEMA_ORG_PREFIX}${jsonld['@type']}`,
        };
      }
    }
  }

  let subGenre = '';
  if (showSubGenre) {
    const categories = cardData.categories || [];
    subGenre = (categories[1] && categories[1].name) || '';
  }

  const entityImageOptions = {
    src,
    publicIdSrc,
    ...DEFAULT_IMAGE_CONFIG,
  };
  const entityImageUrl = helper.getCloudinaryImage(entityImageOptions);
  const entityImage = entityImageUrl ? (
    <CloudinaryImage className="entity-card__bkg-img" image={entityImageUrl} alt={name} useLazyLoad />
  ) : null;

  const showCBTIcon = isRedirectModalEnabled && !!venue && venue?.country.toUpperCase() !== GB_COUNTRY;

  const content = (
    <Card
      {...microDataMarkup}
      itemScope={Boolean(microDataMarkup.itemType)}
      image={
        entityImage ? (
          <CardImage
            className={cx('entity-card__banner', className)}
            image={entityImage}
            alt={name}
            useSkeleton={false}
            lazyLoading={false}
          />
        ) : null
      }
    >
      {microDataToggle && jsonld && <EventMicroData eventJSONLD={jsonld} />}
      <div className="entity-card__contents">
        {showEntityTime && (
          <div className="entity-card__time">
            <time>
              {eventDateMarkup}
              {eventDateMarkup && ' • '}
              {eventDateMarkup ? getEventTime(cardData) : null}
            </time>
          </div>
        )}
        {showEntityGenre && <div className="entity-card__genre">{cardData.genre}</div>}
        {showSubGenre && <div className="">{subGenre}</div>}
        {showEntityName && (
          <div className="entity-card__title">
            <div className="entity-card__name">{name}</div>
            {showCBTIcon && (
              <div className="entity-card__cbt">
                <Icon name="ExternalLink" size="lg" className="cbt-redirection__icon" />
              </div>
            )}
          </div>
        )}
        {showEventVenue && <div className="entity-card__venue">{venueName}</div>}
        {showEventLocation && (
          <div className="entity-card__location">
            <FormattedLocation location={venue} format="medium" type={ENTITY_TYPE_VENUE} />
          </div>
        )}
        {showFromPrice && (
          <div className="entity-card__price">
            <FormattedMessage
              id="EventCard.Price.From"
              defaultMessage="from {minPrice}"
              values={{
                minPrice: (
                  <FormattedNumber
                    style="currency" //eslint-disable-line
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    currencyDisplay="symbol"
                    currency={ticketInfo.currencyCode}
                    useGrouping
                    value={isPriceIncludeFees ? ticketInfo.minPrice : ticketInfo.minListPrice}
                  />
                ),
              }}
            />
          </div>
        )}
      </div>
    </Card>
  );

  return content;
}

function handleClickPerformer(onClick) {
  return function () {
    onClick();

    // Scroll to top of page after link click
    if (__CLIENT__ && window && window.scrollTo && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  };
}

function getEventLink(
  entityData,
  type,
  onClick,
  hideEventVenue,
  showSubGenre,
  className,
  isRedirectModalEnabled,
  context
) {
  const content = getContent(
    entityData,
    type,
    hideEventVenue,
    showSubGenre,
    context,
    className,
    isRedirectModalEnabled
  );
  const {venue: {country} = {}, webURI} = entityData;

  return (
    <CBTRedirection
      className="entity-card__link"
      isEnabled={isRedirectModalEnabled}
      location={ENTITY_TYPE_CITY}
      countryCode={country}
      uri={webURI}
      mode="optional"
      onClick={onClick}
      showIcon={false}
    >
      {content}
    </CBTRedirection>
  );
}

function getPerformerLink(
  entityData,
  type,
  onClick,
  hideEventVenue,
  showSubGenre,
  className,
  isRedirectModalEnabled,
  context
) {
  const content = getContent(
    entityData,
    type,
    hideEventVenue,
    showSubGenre,
    context,
    className,
    isRedirectModalEnabled
  );
  const {webURI, id, type: entityType} = entityData;

  const linkTo = !webURI ? (entityType ? `/${entityType}/${id}` : `/${ENTITY_TYPE_PERFORMER}/${id}`) : webURI;

  const link = (
    <Link href={linkTo} onClick={handleClickPerformer(onClick)}>
      {content}
    </Link>
  );

  return link;
}

const EntityCard = (
  {
    hideEventVenue,
    entityData,
    type = ENTITY_TYPE_EVENT,
    onClick = () => {
      // Do nothing
    },
    showSubGenre,
    className,
    isRedirectModalEnabled,
  },
  {globalRegistry}
) => {
  // If entity has venue, treat it like an event
  const isEvent = !!entityData.venue;
  // If event, use EventRedirection component otherwise use Link component for performer links
  const createLink = isEvent ? getEventLink : getPerformerLink;
  const link = createLink(entityData, type, onClick, hideEventVenue, showSubGenre, className, isRedirectModalEnabled, {
    globalRegistry,
  });

  return <div className={cx('entity-card', type)}>{link}</div>;
};

EntityCard.propTypes = {
  entityData: PropTypes.object.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,

  /**
   * The Class Name used to override the Styling for the component.
   */
  className: PropTypes.string,
  hideEventVenue: PropTypes.bool,
  showSubGenre: PropTypes.bool,

  /** Is redirection modal enabled? */
  isRedirectModalEnabled: PropTypes.bool,
};

EntityCard.contextTypes = {
  globalRegistry: PropTypes.object,
  router: PropTypes.object,
};

export default EntityCard;
