import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import prefix from '../../utils/stylesPrefix';

import './index.scss';

const Tile = ({children, className, ...props}) => {
  const tileClasses = cx(`${prefix}-tile`, className);

  return (
    <div className={tileClasses} {...props}>
      {children}
    </div>
  );
};

Tile.displayName = 'Tile';
Tile.propTypes = {
  /**
   * The child nodes.
   */
  children: PropTypes.node,

  /**
   * The CSS class names.
   */
  className: PropTypes.string,
};
export default Tile;
