import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import prefix from '../../../utils/stylesPrefix';

import './CardTitle.scss';

const CardTitle = ({className, tag, children, ...props}) => {
  const Wrapper = tag || 'h2';

  return (
    <Wrapper className={cs(`${prefix}-card__title`, className)} {...props}>
      {children}
    </Wrapper>
  );
};

CardTitle.displayName = 'CardTitle';
CardTitle.propTypes = {
  /**
   * The CSS class names.
   */
  className: PropTypes.string,

  /**
   * The tag element to use for the title. h2 by default
   */
  tag: PropTypes.string,

  /**
   * The child node
   */
  children: PropTypes.node,
};

export default CardTitle;
