import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import EntityCard from '../react-entity-card';

/**
 * A component to represent a genre's performer. Uses an EntityCard for
 * performer and adds an empty placeholder if performer's information is not
 * available.
 * @param {string} className A class name for component's container.
 * @param {Object} performer A performer to be represented on the card.
 * @extends {React.Component}
 */
class GenrePerformerCard extends Component {
  static defaultProps = {
    className: '',
    performer: {},
  };

  constructor(...args) {
    super(...args);
    this.onClick = this.onClick.bind(this);
  }

  /**
   * Passes an onClick event to parent's handler and provides a performer object
   * and its index.
   */
  onClick() {
    const {onClick} = this.props;
    if (typeof onClick === 'function') {
      const {index, performer} = this.props;
      onClick(index, performer);
    }
  }

  /**
   * Renders a component. Uses class names provided by parent component. Adds
   * an empty placeholder if performer's information is not available.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {className, isMobileView, performer, showSubGenre} = this.props;
    const _ = 'GenrePerformerCard';
    const isCompact = isMobileView ? cs(`${_}--isCompact`) : '';
    const container = cs(className, _, isCompact);
    const placeholder = cs(`${_}__placeholder`);
    const placeholderImage = cs(`${_}__placeholderImage`);
    const placeholderName = cs(`${_}__placeholderName`);

    return (
      <div className={container}>
        {performer ? (
          <EntityCard entityData={performer} onClick={this.onClick} type="performer" showSubGenre={showSubGenre} />
        ) : (
          <div className={placeholder}>
            <div className={placeholderImage} />
            <div className={placeholderName} />
          </div>
        )}
      </div>
    );
  }
}

GenrePerformerCard.propTypes = {
  onClick: PropTypes.func,
  index: PropTypes.number,

  /**
   * Css class name
   */
  className: PropTypes.string,

  /**
   * If is mobile
   */
  isMobileView: PropTypes.bool,

  /**
   * The Perfomer Object
   */
  performer: PropTypes.object,
  showSubGenre: PropTypes.bool,
};

export default GenrePerformerCard;
