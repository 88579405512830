import {defineMessages} from 'react-intl';

/**
 * The definition of messages to be used in the component and available in
 * supported translations. If translation is not available the default message
 * is used.
 */
const messages = defineMessages({
  description: {
    id: 'Component.GenrePerformers.NotFound.Description',
    defaultMessage: 'Try different dates or locations.',
  },
  noResults: {
    id: 'Component.GenrePerformers.NotFound.NoResults',
    defaultMessage: 'No Results for:',
  },
  resultDetails: {
    id: 'Component.GenrePerformers.NotFound.ResultDetails',
    defaultMessage:
      '{genreName} within {radius} miles of {geoName} {dateKey, select, today {for Today} thisWeekend {for This Weekend} thisMonth {for This Month} chooseDate {on {date}} other {on Any Date}}',
  },
  resultDetailsNoGeo: {
    id: 'Component.GenrePerformers.NotFound.ResultDetailsNoGeo',
    defaultMessage:
      '{genreName} {dateKey, select, today {for Today} thisWeekend {for This Weekend} thisMonth {for This Month} chooseDate {on {date}} other {on Any Date}}',
  },
  title: {
    id: 'Component.GenrePerformers.NotFound.Title',
    defaultMessage: "Uh oh! We didn't find anything.",
  },
  tryAnother: {
    id: 'Component.GenrePerformers.NotFound.TryAnother',
    defaultMessage: 'Try another location, date or category.',
  },
  updateLocation: {
    id: 'Component.GenrePerformers.NotFound.UpdateLocation',
    defaultMessage: 'Update location',
  },
});

export default messages;
