import {createSelector} from 'reselect';

/**
 * Selects a device from the state.
 * @param {Object} state A state containing a device namespace with type.
 * @return {string?} A device type.
 */
const deviceTypeSelector = createSelector(
  (state = {}) => state.device,
  (device = '') => device.type,
);

/**
 * Selects a screen size from the state.
 * @param {Object} state A state containing a viewport namespace with screen
 *     size.
 * @return {string?} A point containing latitude and longitude.
 */
const screenSizeSelector = createSelector(
  (state = {}) => state.viewport,
  (viewport = {}) => viewport.screenSize,
);

/**
 * Selects a user's location object from the state.
 * @param {Object} state A state containing a user namespace with location.
 * @return {Object} A user's location with latitude and longitude properties.
 */
const userLocationSelector = createSelector(
  (state = {}) => state.user,
  (user = {}) => user.location,
);

/**
 * Selects a geo point from the state.
 * @param {Object} state A state containing a user namespace with location.
 * @return {string?} A point containing latitude and longitude.
 */
const geoPointSelector = createSelector(userLocationSelector, (location) =>
  location ? `${location.latitude},${location.longitude}` : null,
);

/**
 * Selects a geo name from the state.
 * @param {Object} state A state containing a user namespace with location.
 * @return {string?} A name of geo location.
 */
const geoNameSelector = createSelector(userLocationSelector, (location = {}) => location.name);

/**
 * Detects a mobile optimized view flag from device type and screen size.
 * @param {Object} state A state containing device and viewport namespaces.
 * @return {boolean} A mobile optimized view flag.
 */
const mobileViewSelector = createSelector(
  [deviceTypeSelector, screenSizeSelector],
  (deviceType = '', screenSize = '') =>
    (!screenSize && deviceType === 'phone') ||
    screenSize === 'small' ||
    (screenSize === 'medium' && deviceType === 'phone'),
);

/**
 * Selects a notFound property from the local state.
 * @param {Object} state A local state containing GenrePerformers' properties.
 * @return {boolean} A notFound property.
 */
const notFoundSelector = createSelector(
  (localState = {}) => localState.notFound,
  (notFound = false) => notFound,
);

/**
 * Selects a list of performers from the local state.
 * @param {Object} state A local state containing GenrePerformers' properties.
 * @return {Array} A list of performers.
 */
const performersSelector = createSelector(
  (localState = {}) => localState.performers,
  (performers = []) => performers,
);

/**
 * Selects a reset flag from the local state.
 * @param {Object} state A local state containing GenrePerformers' properties.
 * @return {boolean} A reset flag.
 */
const resetSelector = createSelector(
  (localState = {}) => localState.reset,
  (reset = false) => reset,
);

/**
 * Selects a total count of performers from the local state.
 * @param {Object} state A local state containing GenrePerformers' properties.
 * @return {number} A total count of performers.
 */
const totalCountSelector = createSelector(
  (localState = {}) => localState.total,
  (total = 0) => total,
);

/**
 * Provides an interface to export component's selectors.
 */
const selectors = {
  geoName: geoNameSelector,
  geoPoint: geoPointSelector,
  mobileView: mobileViewSelector,
  notFound: notFoundSelector,
  performers: performersSelector,
  reset: resetSelector,
  totalCount: totalCountSelector,
};

export default selectors;
