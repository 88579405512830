import {helper} from '@stubhub/react-cloudinary';
import {getTicketBisEventUrl, isTicketBisEvent, isPrimaryEvent, getPrimaryEventUrl} from '@stubhub/react-event-helper';

/**
 * TODO - Handle `EVENT_CATEGORY_THEATER` for theater category.
 *
 */
const EVENT_TYPE_PARKING = 'parking';
const SCHEMA_ORG_URL = 'https://schema.org';
const SCHEMA_DEFAULT_AGGREGATE_OFFER_NAME = 'Resale';
const SCHEMA_DEFAULT_AGGREGATE_OFFER_CATEGORY = 'Secondary';
const SCHEMA_INSTOCK = `${SCHEMA_ORG_URL}/InStock`;
const SCHEMA_EVENT_TYPE_THEATER = 'TheaterEvent';
const SCHEMA_EVENT_TYPE_TEAM = 'SportsEvent';
const SCHEMA_EVENT_TYPE_ARTIST = 'MusicEvent';
// Const SCHEMA_EVENT_TYPE_DEFAULT = 'Event';
const SCHEMA_PERFORMER_TYPE_DEFAULT = 'Organization';
const SCHEMA_PERFORMER_TYPE_TEAM = 'SportsOrganization';
const SCHEMA_PERFORMER_TYPE_ARTIST = 'MusicGroup';
const EVENT_CATEGORY_SPORTS_ID = 28;
const EVENT_CATEGORY_CONCERT_ID = 1;

export const SCHEMA_ORG_PREFIX = 'https://schema.org/';
export const SCHEMA_TYPE_ADDRESS = 'PostalAddress';
export const SCHEMA_TYPE_COUNTRY = 'Country';

// TODO: Hard code specific performers to use micro data to mark up instead of jsonld
// eslint-disable-next-line no-magic-numbers
export const MICRO_DATA_PERFORMER_IDS = [1516984, 23693, 11228, 1493008, 1562, 391512, 100273921, 143, 6184, 984];

/**
 * Check whether current event has specific performers
 * To decide whether to use micro data
 * If performer id is passed in, then just check whether the performer id is in the list.
 * Otherwise, use performers info in event data to check.
 * @param {object | array} event event data, might be an array or object. If array, use first element as event data
 * @param {string} eventPerformerId
 */
export const isMicroDataEnabledOnPerformer = (event, eventPerformerId) => {
  // If event is an array, use first element (event data) to check
  const eventData = Array.isArray(event) ? event[0] : event;
  let isMicroDataEnabled = false;
  if (eventData === null) {
    return isMicroDataEnabled;
  }
  const eventPerformers = eventPerformerId
    ? [{id: eventPerformerId}]
    : eventData.performersCollection || eventData.performers || eventData.groupings || [];
  eventPerformers.forEach((performer) => {
    const curId = performer.id;
    isMicroDataEnabled = MICRO_DATA_PERFORMER_IDS.indexOf(+curId) > -1 || isMicroDataEnabled;
  });

  return isMicroDataEnabled;
};

export const checkJSONLDAndMicroDataEnabled = (globalRegistry, event, eventPerformerId) => {
  let jsonldToggle = true;
  let microDataToggle = false;
  const microDataEnabled = globalRegistry.getFeatureValue('browse.microData.enabled');
  const microDataLimitedListEnabled = globalRegistry.getFeatureValue('browse.microData.limitedList.enabled');

  if (microDataEnabled) {
    // Micro data toggle enabled, then turn on micro data for all page
    microDataToggle = true;
    jsonldToggle = false;
  } else if (microDataLimitedListEnabled) {
    const isMicroDataEnabledOnPerformerLevel = isMicroDataEnabledOnPerformer(event, eventPerformerId);
    if (isMicroDataEnabledOnPerformerLevel) {
      microDataToggle = true;
      jsonldToggle = false;
    } else {
      microDataToggle = false;
      jsonldToggle = true;
    }
  }

  return {
    jsonldToggle,
    microDataToggle,
  };
};
// TODO End

const getSchemaDomainPrefixByWebTLD = (webTLD = 'com') => `https://www.stubhub.${webTLD}/`;

export const deriveCategory = (categories = []) => {
  for (let i = 0, j = categories.length; i < j; i++) {
    switch (categories[i].id) {
      case EVENT_CATEGORY_SPORTS_ID:
        return [SCHEMA_EVENT_TYPE_TEAM, SCHEMA_PERFORMER_TYPE_TEAM];

      case EVENT_CATEGORY_CONCERT_ID:
        return [SCHEMA_EVENT_TYPE_ARTIST, SCHEMA_PERFORMER_TYPE_ARTIST];
    }
  }

  return [SCHEMA_EVENT_TYPE_THEATER, SCHEMA_PERFORMER_TYPE_DEFAULT];
};

/**
 * Build events JSONLD data
 * SEO-267
 *  Add max item count param, default to be 10
 * @param {Array} events
 * @param {String} webTLD - Top Level Domain Name
 * @param {Number} maxCount - Max items count
 */
export const buildEventsJSONLD = (events, webTLD, maxCount, redirectionDomain) => {
  let out = [];
  const eventStatusMapping = {
    active: 'https://schema.org/EventScheduled',
    completed: 'https://schema.org/EventScheduled',
    scheduled: 'https://schema.org/EventScheduled',
    contingent: 'https://schema.org/EventScheduled',
    postponed: 'https://schema.org/EventPostponed',
    inactive: 'https://schema.org/EventPostponed',
    cancelled: 'https://schema.org/EventCancelled',
  };

  for (let i = 0, j = events.length; i < j; i++) {
    const item = events[i];
    const currentEventStatus = (item.status && item.status.toLowerCase()) || 'active';
    if (item && (item.eventType /* istanbul ignore next */ || '').toLowerCase() !== EVENT_TYPE_PARKING) {
      const [eventType, performerType] = deriveCategory(item.categories);
      const performers = (item.performersCollection /* istanbul ignore next */ || []).map((performer) => ({
        '@type': performerType,
        name: performer.name,
        sameAs: `${getSchemaDomainPrefixByWebTLD(webTLD)}${performer.webURI}`,
      }));
      let url = '';

      if (isTicketBisEvent(item)) {
        url = getTicketBisEventUrl(item, redirectionDomain, webTLD);
        // Add UKCMA check
        if (webTLD === 'co.uk' && item.venue.country !== 'GB') {
          url = url.replace('.co.uk', '.ie');
        }
      } else if (isPrimaryEvent(item)) {
        url = getPrimaryEventUrl(item);
      } else {
        url = `${getSchemaDomainPrefixByWebTLD(webTLD)}${item.webURI}`;
      }

      let imageUrl = '';
      if (item.images && item.images.length > 0) {
        imageUrl = helper.getCloudinaryImageURL({
          publicIdSrc: item.images[0].cloudinaryPublicId || item.images[0].url,
          useNamedTransforms: false,
          width: 280,
          height: 180,
        });
      }

      const streetAddress = item.venue.address1 + (item.venue.address2 ? '\n{item.venue.address2}' : '');

      const eventDt = new Date(item.eventDateLocal);
      const eventEndDate = `${eventDt.getFullYear()}-${eventDt.getMonth() + 1}-${eventDt.getDate()}`;

      const jsonldItem = {
        '@context': SCHEMA_ORG_URL,
        '@type': eventType,
        name: (item.seoMeta && item.seoMeta.schemaName) || item.name,
        location: {
          '@type': 'Place',
          address: {
            streetAddress,
            addressLocality: item.venue.city,
            addressRegion: item.venue.state,
            postalCode: item.venue.postalCode,
            addressCountry: item.venue.country,
          },
          name: item.venue.name,
          sameAs: `${getSchemaDomainPrefixByWebTLD(webTLD)}${item.venue.webURI}`,
        },
        description: item.description,
        eventStatus: eventStatusMapping[currentEventStatus],
        image: imageUrl,
        startDate: item.eventDateLocal,
        endDate: eventEndDate,
        eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
        doorTime: item.eventDateLocal,
        performer: performers,
        url,
        offers: {
          '@type': 'AggregateOffer',
          name: SCHEMA_DEFAULT_AGGREGATE_OFFER_NAME,
          category: SCHEMA_DEFAULT_AGGREGATE_OFFER_CATEGORY,
          url,
          availability: SCHEMA_INSTOCK,
          lowPrice: item.ticketInfo.minPrice,
          highPrice: item.ticketInfo.maxPrice,
          priceCurrency: item.ticketInfo.currencyCode,
          validFrom: item.createdDate,
          validThrough: item.ticketInfo.maxSaleEndDate,
          inventoryLevel: {
            '@type': 'QuantitativeValue',
            name: item.ticketInfo.totalTickets,
          },
        },
      };

      out.push(jsonldItem);
    }
  }

  /* istanbul ignore else */
  if (maxCount) {
    out = out.slice(0, maxCount);
  }

  return out;
};

/**
 * Sort events for mark up usage
 * SEO-243
 * Local + International case:
 *    Local events are the first to have Schema.org markup implemented.
 *    Only the international events happening in a later date than the last local one will also include schema.org tags.
 * Only International case:
 *    All international events include schema.org tag.
 * SEO-267
 * Add max item count param, default to be 10
 * @param {Array} events
 * @param {String} localCountry - Local Country Code
 */
const sortEventsByPriority = (events, localCountry) => {
  if (!localCountry) {
    return events;
  }

  const sortedEvents = [];
  let alreadyMeetLastLocalEvent = false;
  for (let idx = events.length - 1; idx >= 0; idx--) {
    const event = events[idx];
    if (!alreadyMeetLastLocalEvent) {
      sortedEvents.unshift(event);
      alreadyMeetLastLocalEvent = event.venue.country === localCountry;
    } else {
      event.venue.country === localCountry && sortedEvents.unshift(event);
    }
  }

  return sortedEvents;
};

const getEventsJSONLDMeta = (
  /* istanbul ignore next */ events = [],
  localCountry,
  webTLD,
  /* istanbul ignore next */ maxCount = 10,
  redirectionDomain,
) => {
  const sortedEvents = sortEventsByPriority(events, localCountry);
  try {
    const ldJSONMetaObj = buildEventsJSONLD(sortedEvents, webTLD, maxCount, redirectionDomain);

    return JSON.stringify(ldJSONMetaObj);
  } catch (e) {
    /* istanbul ignore next */
    return '';
  }
};

export default getEventsJSONLDMeta;
