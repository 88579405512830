import {stringify} from 'qs';

import {parseHomeRecoAPIPartnerConfig} from '@stubhub/react-partner-helper';
import {get} from '@stubhub/rest-method';

import DateTimeFormat from '../../utils/datetimeformat';

const DEFAULT_SEARCH_CACHE_MAX_AGE = 300; // 5 min
const SEARCH_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || DEFAULT_SEARCH_CACHE_MAX_AGE;

function getRecoUrl(queryParams, cookies) {
  const {
    point,
    radius = 50,
    categoryId,
    performerIds,
    handleName = 'unified_homepage',
    minAvailableTickets = 1,
    maxEventsPerPerformer = 3,
    shstore = 1,
    geoExpansion = true,
  } = queryParams;

  const {offset, limit, eventStartDate, eventEndDate, visitorId, userGuid} = getParams(queryParams, cookies);
  const opts = {
    startIndex: offset,
    point,
    radius,
    handleName,
    minAvailableTickets,
    maxEventsPerPerformer,
    limit,
    shstore,
    categoryId,
    performerIds,
    eventStartDate,
    eventEndDate,
    visitorId,
    userGuid,
    geoExpansion,
  };

  return `/recommendations/core/v2/eventgroupings?${stringify(opts)}`;
}

/**
 * Getting Search eventgrouping api
 * Temporarily used until v4/location api is moved to GCP Native
 *
 */
function getSearchUrl(queryParams, cookies) {
  const {point, radius = 50, categoryId, minAvailableTickets = 1, shstore = 1, geoExpansion = false} = queryParams;

  const {offset, eventStartDate, eventEndDate} = getParams(queryParams, cookies);

  const opts = {
    point,
    radius,
    categoryId,
    minAvailableTickets,
    shstore,
    geoExpansion,
    start: offset,
    eventStartDate,
    eventEndDate,
    groupType: 'P',
    parking: false,
    units: 'mi',
    rows: 10,
    sort: 'popularity desc',
    ir: true,
  };

  return `/search/catalog/events/v3/eventGroupings?${stringify(opts)}`;
}

/**
 * Getting PerformerByGenre api url
 * To be Used once v4/location api moved to GCP Native
 *
 */
function getPBGUrl(queryParams, cookies) {
  const {offset} = getParams(queryParams, cookies);
  const {point, categoryId} = queryParams;
  const opts = {start: offset, point, categoryId};

  return `/catalog/performer/v4/location?${stringify(opts)}`;
}

export function getRecoEntity(queryParams, cookies, lang) {
  const cache = true;
  const cacheMaxAge = SEARCH_CACHE_MAX_AGE;

  return get({
    host: queryParams.performerAPIExpEnabled ? process.env.REACT_APP_GCP_API_HOST : process.env.REACT_APP_API_HOST,
    path: queryParams.performerAPIExpEnabled
      ? getPBGUrl(queryParams, cookies)
      : queryParams.searchAPIEnabled
      ? getSearchUrl(queryParams, cookies)
      : getRecoUrl(queryParams, cookies),
    headers: _getFetchRequestHeaders(lang),
    json: true,
    cache,
    cacheMaxAge,
  }).then((body) => {
    return processBody(body, queryParams.performerAPIExpEnabled);
  });
}

function getParams(queryParams, cookies) {
  const {eventStartDate, eventEndDate, offset} = queryParams;
  const newQueryParams = {...queryParams};
  newQueryParams.eventStartDate = DateTimeFormat.toAPIString(eventStartDate);
  newQueryParams.eventEndDate = DateTimeFormat.toAPIString(eventEndDate);
  newQueryParams.offset = offset;

  if (cookies) {
    newQueryParams.visitorId = cookies.get('SH_VI');
    newQueryParams.userGuid = cookies.get('track_session_userGUID');
  }

  return newQueryParams;
}

function _getFetchRequestHeaders(language) {
  const ret = {
    Authorization: process.env.REACT_APP_API_SECRET,
  };
  /* istanbul ignore if */
  if (language) {
    ret['Accept-Language'] = language;
  }

  return ret;
}

function processBody(body, performerAPIExpEnabled) {
  /* istanbul ignore next */
  const {totalCount = 0, numFound = 0, groups = [], performers = [], impressionToken} = body;
  const groupsData = performerAPIExpEnabled ? performers : groups;
  const data = parseHomeRecoAPIPartnerConfig(groupsData);

  return {
    totalCount: performerAPIExpEnabled ? numFound : totalCount,
    data,
    impressionToken,
  };
}
