import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {connect} from '@stubhub/react-store-provider';

import EntityList from '../react-entity-list';

import controller from './controller';

import './index.scss';

export class RecentlyViewed extends Component {
  static contextTypes = {
    cookies: PropTypes.object,
    getPageTrackingInfo: PropTypes.func,
  };

  init() {
    const {loadEvents} = this.props;
    const {cookies} = this.context;
    const newProps = {...this.props, cookies};

    return loadEvents(newProps);
  }

  UNSAFE_componentWillMount() {
    this.init();
  }

  render() {
    const {events} = this.props;

    const rvTitle = <FormattedMessage id="RecentlyViewed.EntityCard.title" defaultMessage="Recently viewed" />;

    return (
      <div className="RecentlyViewed">
        <EntityList type="event" entityData={events} title={rvTitle} noFetch totalCount={events.length} useCarousel />
      </div>
    );
  }
}

RecentlyViewed.propTypes = {
  /** Array of events */
  events: PropTypes.array,

  /** Function to call for loading events */
  loadEvents: PropTypes.func.isRequired,
};

export default connect(controller)(RecentlyViewed);
