export const NAMED_TRANSFORMATIONS = {
  THUMB_169: 'thumb_169',
  THUMB_VFS: 'thumb_VFS',
  VFS: 'VFS',
};

export const DEFAULT_IMAGE_CONFIG = {
  namedTransformation: NAMED_TRANSFORMATIONS.THUMB_169,
};

export const MAIN_PERFORMER_IMAGES_CONFIG = DEFAULT_IMAGE_CONFIG;
