import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

class UIButton extends React.Component {
  getClassNames() {
    const classPrefix = 'UIButton--';
    const {flow = 'main', size = 'large', secondary = false, disabled = false} = this.props;
    const btnFlow = classPrefix + flow + (!secondary ? 'Primary' : 'Secondary') + (!disabled ? '' : 'Disabled');
    const btnSize = classPrefix + size;

    return classNames(btnFlow, btnSize);
  }

  render() {
    const {onClick, title, type, text, children} = this.props;

    return (
      <button
        onClick={onClick}
        title={title}
        // eslint-disable-next-line react/button-has-type
        type={type || 'button'}
        className={`UIButton ${this.getClassNames(this.props)}`}
      >
        {children || text}
      </button>
    );
  }
}

UIButton.propTypes = {
  flow: PropTypes.oneOf(['main', 'alternate', 'neutral']),
  size: PropTypes.oneOf(['stretch', 'large', 'tiny', 'micro']),
  secondary: PropTypes.bool,

  /** Is UI button disabled? */
  disabled: PropTypes.bool,
  text: PropTypes.element.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default UIButton;
