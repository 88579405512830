import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import pick from 'object.pick';
import qs from 'query-string';
import {injectIntl, FormattedMessage} from 'react-intl';

import {publish} from '@stubhub/pubsub-utils';
import FeatureToggle from '@stubhub/react-feature-toggle';
import GoogleSemMessage from '@stubhub/react-google-sem-message';
import InterceptModal from '@stubhub/react-intercept-modal';
import NavFilter from '@stubhub/react-nav-filter';
import {connect, ssr_wait} from '@stubhub/react-store-provider';

import ChildSeoLinksLazyLoad from '../../modules/react-child-seo-links/child-seo-links-lazy-load';
import ConnectedCMSBillboard from '../../modules/react-cms-billboard';
import Email from '../../modules/react-email-collection/src';
import JumbotronCarousel from '../../modules/react-jumbotron-carousel';
import ConnectedRecentlyViewed from '../../modules/react-recently-viewed';
import SeoBottomContent from '../../modules/react-seo-content';
import {HOME_EVENTS} from '../../tracking/track-enum';
import FEATURE_FLAGS from '../../utils/featureFlags';

import CategoryPage, {CategoryPageBanner} from './category';
import controller from './controller';
import {buildHomePageContent} from './helper';
import HomeSEOContent from './react-home-seo-content';

import './index.scss';

const ENTITY_TYPE_CATEGORY = 'category';

@connect(controller)
class HomePage extends Component {
  static contextTypes = {
    globalRegistry: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    const {deviceType} = props;
    const {globalRegistry} = context;
    const isMobile = deviceType === 'phone' || deviceType === 'tablet';
    const isEnabled =
      typeof globalRegistry.getFeatureValue === 'function'
        ? globalRegistry.getFeatureValue.bind(globalRegistry)
        : () => undefined;

    // Whether the Google SEM feature toggle is on for this page
    this.isGoogleSemMsgFeatureOn = isEnabled('homepage.ghMessage.enableFlag');

    /*
     * Merch Header Feature Toggle (only affects whether other components
     * display with merch header)
     */
    this.isMerchHeaderFeatureOn = isEnabled('common.enableMerchHeader');

    this.isJumbotronCarouselOn = isEnabled('homepage.enableJumbotronCarousel');

    this.hasCategoryPerformers = isMobile
      ? isEnabled('homepage.category.mwebPerformers')
      : isEnabled('homepage.category.desktopPerformers');

    this.hasJumbotronIntegratedCMS = isEnabled('homepage.jumbotronIntegratedCMS');

    this.currentLocale = globalRegistry.getCurrentLocale();
    this.storeId = globalRegistry.getStoreId();

    /**
     * Feature Toggle for genre modules
     *
     */
    this.genreModuleVariantsFeatureToggle = isEnabled('homepage.enableGenreModulesVariants');

    /**
     * Feature Toggle for new PerformerByGenre api
     *
     */
    this.performerByGenreApiEnabled = isEnabled('homepage.enablePerformerByGenreMweb');

    this.desktopGenreModules = isEnabled('homepage.desktop.genreModules');

    this.state = {
      // EventStartDate in URL
      urlEventStartDate: '',

      // EventEndDate in URL
      urlEventEndDate: '',
    };
  }

  @ssr_wait
  init() {
    const queue = [];
    const {categoryId, fetchCategorySEO, jumbotronArray, loadJumbotronCarouselBanners, userLocation} = this.props;
    if (categoryId) {
      queue.push(fetchCategorySEO(this.props, ENTITY_TYPE_CATEGORY));
    }

    if (this.isJumbotronCarouselOn) {
      if (!jumbotronArray || !jumbotronArray.length) {
        queue.push(loadJumbotronCarouselBanners(this.hasJumbotronIntegratedCMS, userLocation));
      }
    }

    if (queue && queue.length) {
      return Promise.all(queue);
    }
  }

  UNSAFE_componentWillMount() {
    this.init();
    const {trackGTM} = this.props;

    /*
     * Set/update the urlEventStartDate and urlEventEndDate in
     * state, if present.
     */
    this.updateUrlStartEndDate(this.props);

    trackGTM();
  }

  componentDidMount() {
    if (document) {
      document.body.classList.add('HomePageBody');
    }

    const {categoryId} = this.props;
    if (!categoryId) {
      publish(HOME_EVENTS.LOADED);
    }
  }

  componentWillUnmount() {
    const {destroy} = this.props;
    destroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {location, eventStartDate, eventEndDate, userLocation, loadJumbotronCarouselBanners} = this.props;
    const newSearch = location.search !== nextProps.location.search;
    const newKey = location.key !== nextProps.location.key;
    const newEventStartDate = nextProps.eventStartDate || eventStartDate;
    const newEventEndDate = nextProps.eventEndDate || eventEndDate;

    if (newSearch || newKey || newEventStartDate || newEventEndDate) {
      /*
       * Location (URL) has changed. Set/update the urlEventStartDate and urlEventEndDate in
       * state, if present.
       */
      this.updateUrlStartEndDate(nextProps);
    }

    if (this.isJumbotronCarouselOn) {
      if (
        (!nextProps.jumbotronArray || !nextProps.jumbotronArray.length) &&
        userLocation !== nextProps.userLocation &&
        (!userLocation ||
          !nextProps.userLocation ||
          userLocation.latitude !== nextProps.userLocation.latitude ||
          userLocation.longitude !== nextProps.userLocation.longitude)
      ) {
        loadJumbotronCarouselBanners(this.hasJumbotronIntegratedCMS, nextProps.userLocation);
      }
    }

    if (newSearch || newKey) {
      // Scroll back to the top
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    }
  }

  /**
   * Update the urlEventStartDate and urlEventEndDate fields based on URL.
   * @param {Object} props
   */
  updateUrlStartEndDate(props) {
    const {eventStartDate: urlEventStartDate, eventEndDate: urlEventEndDate} = this.getUrlDate(props);
    if (urlEventStartDate && urlEventEndDate) {
      this.setState({
        urlEventStartDate,
        urlEventEndDate,
      });
    } else if (props.eventStartDate && props.eventEndDate) {
      this.setState({
        urlEventStartDate: props.eventStartDate,
        urlEventEndDate: props.eventEndDate,
      });
    } else {
      // URL no longer contains eventStartDate and eventEndDate, reset
      this.setState({
        urlEventStartDate: '',
        urlEventEndDate: '',
      });
    }
  }

  stickyFilter({props}) {
    return <NavFilter {...props} />;
  }

  getUrlDate(props) {
    const {location} = props;
    let {search = ''} = location;
    search = search.replace(/^\?/, '');
    const query = qs.parse(search) || {};

    return pick(query, ['eventStartDate', 'eventEndDate']);
  }

  render() {
    const {urlEventStartDate, urlEventEndDate} = this.state;
    const {
      categoryId,
      defaultWebTLD,
      referrer,
      deviceType,
      jumbotronArray,
      seoMeta = {},
      currentLocation = {},
      start,
      end,
    } = this.props;
    const {seoBottomContentUrl} = seoMeta;
    const {globalRegistry} = this.context;
    const isMobile = deviceType === 'phone' || deviceType === 'tablet';

    // Added track title here only for tracking purpose for child SEO links;
    let title;
    switch (categoryId) {
      case '1':
        title = <FormattedMessage id="Concert.PageTitle" defaultMessage="Concerts and festivals near you" />;
        break;
      case '28':
        title = <FormattedMessage id="Sport.PageTitle" defaultMessage="Sports near you" />;
        break;
      case '174':
        title = (
          <FormattedMessage id="Theater.PageTitle" defaultMessage="Theater & Comedy: Live cultural events near you" />
        );
        break;
      default:
        title = <FormattedMessage id="General.PageTitle" defaultMessage="Live events near you" />;
        break;
    }

    let popularTitle;
    switch (categoryId) {
      case '1':
        popularTitle = <FormattedMessage id="Concert.PopularTitle" defaultMessage="Popular concerts and festivals" />;
        break;
      case '28':
        popularTitle = <FormattedMessage id="Sport.PopularTitle" defaultMessage="Popular sport events" />;
        break;
      case '174':
        popularTitle = <FormattedMessage id="Theater.PopularTitle" defaultMessage="Popular theatre & comedy events" />;
        break;
      default:
        popularTitle = <FormattedMessage id="General.PopularTitle" defaultMessage="Popular live events" />;
        break;
    }

    const enableJumbotronCarousel = !categoryId && !isMobile && this.isJumbotronCarouselOn;
    const noDateSelector = !globalRegistry.getFeatureValue('homepage.enableDateFilter');
    const stickyFilter = isMobile
      ? this.stickyFilter({
          props: {
            className: 'mh__nav-secondary sticky',
            globalRegistry,
            screenSize: 'small',
            homepage: true,
            noDateSelector,
            start,
            end,
          },
        })
      : null;

    const isCategoryPage = categoryId !== undefined;
    const categoryPageProps = {
      radius: 50,
      ...this.props,
    };
    const categoryPageContent =
      isCategoryPage && this.hasCategoryPerformers ? <CategoryPage {...categoryPageProps} /> : null;
    const hasCategoryPageContent = Boolean(categoryPageContent);

    const homePageContent = !hasCategoryPageContent
      ? buildHomePageContent({
          genreModuleVariantsFeatureToggle: this.genreModuleVariantsFeatureToggle,
          desktopGenreModules: this.desktopGenreModules && !isMobile,
          title,
          popularTitle,
          categoryId,
          urlEventStartDate,
          urlEventEndDate,
          storeId: this.storeId,
          deviceType,
          performerByGenreApiEnabled: this.performerByGenreApiEnabled,
        })
      : null;
    const pageClassName = cs(hasCategoryPageContent ? 'CategoryPage' : 'HomePage');
    const containerClassName = cs(hasCategoryPageContent ? 'CategoryPage__contentArea' : 'HomePage__body');

    return (
      <div
        id="app-container"
        className={pageClassName}
        ref={(node) => {
          this.scrollToTop = node;
        }}
      >
        {this.isMerchHeaderFeatureOn ? null : (
          <GoogleSemMessage isFeatureOn={this.isGoogleSemMsgFeatureOn} referrer={referrer} readCache />
        )}
        {!enableJumbotronCarousel && (
          <div className="Homepage__CombinedBillboardContainer">
            <FeatureToggle name="homepage.enableCMSBillboard">
              <div className="Homepage__CMSBillboard">
                <ConnectedCMSBillboard categoryId={categoryId} />
              </div>
            </FeatureToggle>
          </div>
        )}
        {stickyFilter}
        {enableJumbotronCarousel && <JumbotronCarousel performers={jumbotronArray} />}
        {hasCategoryPageContent && <CategoryPageBanner locale={this.currentLocale} {...this.props} />}
        <div className={containerClassName}>
          <FeatureToggle name={FEATURE_FLAGS.browse.showBannerSkeleton}>
            <div className="marketing-picture-banner" />
          </FeatureToggle>
          {/* This renders either Performer Cards or Performer Columns */}
          {hasCategoryPageContent ? categoryPageContent : homePageContent}
          {isMobile && !categoryId && (
            <FeatureToggle name="homepage.enableRecentlyViewed">
              <div className="Homepage__RecentlyViewed">
                <ConnectedRecentlyViewed />
              </div>
            </FeatureToggle>
          )}
          <FeatureToggle name="homepage.enableChildLinks" and={typeof categoryId === 'string'}>
            <div className="Homepage__seoContainer">
              <ChildSeoLinksLazyLoad
                className="HomePage__ChildSEOLinks"
                namespace="homepage.category"
                entityType="category"
                entityId={categoryId}
                title={title}
                apiParams={{parentId: categoryId}}
              />
              {seoBottomContentUrl && (
                <SeoBottomContent
                  className="HomePage__SeoBottomContent"
                  refNode={this.scrollToTop}
                  seoBottomContentUrl={seoBottomContentUrl}
                  expanded
                />
              )}
            </div>
          </FeatureToggle>
          <FeatureToggle name="homepage.enableEmailCollection">
            <Email />
          </FeatureToggle>
          {/* SEO content for homepage */}
          <HomeSEOContent defaultWebTLD={defaultWebTLD} />
          <FeatureToggle name="common.enableInterceptModalForCA">
            <InterceptModal currentLocation={currentLocation} defaultWebTLD={defaultWebTLD} />
          </FeatureToggle>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  referrer: PropTypes.string,
  categoryId: PropTypes.string, // Extracted from route
  /**
   * The router history object
   */
  history: PropTypes.object,

  /**
   * Device type
   */
  deviceType: PropTypes.string,

  /**
   * Default TLD for the site
   */
  defaultWebTLD: PropTypes.string,
  jumbotronArray: PropTypes.array,
  seoMeta: PropTypes.object,
  currentLocation: PropTypes.object,

  start: PropTypes.any,
  end: PropTypes.any,

  /**
   * Url location object
   */
  location: PropTypes.object,

  /**
   * Selected start date filter
   */
  eventStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  /**
   * Selected end date filter
   */
  eventEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  /**
   * User geo location
   */
  userLocation: PropTypes.object,
  loadJumbotronCarouselBanners: PropTypes.func,
  fetchCategorySEO: PropTypes.func,

  /**
   * Destroy function
   */
  destroy: PropTypes.func,
  trackGTM: PropTypes.func,
};

HomePage.defaultProps = {
  referrer: '',
};

export default injectIntl(HomePage);
