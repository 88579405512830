import React, {Component} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import CloudinaryMedia from '@stubhub/react-cloudinary';
import DropdownList from '@stubhub/react-dropdown-list';
import FormattedLink from '@stubhub/react-formatted-link';
import {ControllerContainer, ssr_wait} from '@stubhub/react-store-provider';

import GenreEmptyMessage from '../react-genre-empty';

import controllerFactory from './controller';
import GENRES from './genres';

import './index.scss';

const CAROUSEL_DEBOUNCE = 200;
const DEFAULT_TIMER = 1000;
const DEFAULT_IMAGE_HEIGTH = 169;
const MARGIN_CONTAINER_BREAKPOINT = 889;
const SMALL_CONTAINER_MARGIN = 15;
const CONTAINER_MARGIN = 30;
const PERCENTAGE = 100;

class GenreModule extends Component {
  static contextTypes = {
    cookies: PropTypes.object,
    queue: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const {genreModuleCategory} = this.props;
    this.subcategories = GENRES[genreModuleCategory].map((category, index) => {
      category.key = index;

      return category;
    });

    [this.category] = this.subcategories;
    this.page = 0;
    this.imageHeight = 0;
    this.timers = {
      imageHeight: null,
    };
  }

  getEntities() {
    let entities = [];
    const {performers} = this.props;
    performers.forEach((performers) => {
      entities = entities.concat(performers);
    });

    return entities;
  }

  getEntitiesDesktop() {
    const {rowCount, performers} = this.props;
    const {newCurrHeight} = this.state || {};
    const pages = [];
    const imageHeight = this.setImageHeight();
    const imgStyle = newCurrHeight || !imageHeight ? {} : {minHeight: imageHeight, height: imageHeight};

    pages.push(
      performers.map((performers, index) => {
        const entities = performers.map((entity) => {
          return (
            <li className="genre-module--entity active" key={`genre-module--entity-${entity.position}`}>
              <FormattedLink href={entity.webURI} className="genre-module--entity-name">
                <div className="genre-module--entity-image--container" style={imgStyle}>
                  <CloudinaryMedia
                    className="genre-module--entity-image"
                    src={entity.image}
                    alt={entity.name}
                    publicIdSrc={entity.cloudinaryPublicId}
                    enableCloudinary
                    width="218"
                    height="172"
                  />
                </div>
                <span>{entity.name}</span>
              </FormattedLink>
            </li>
          );
        });

        /* istanbul ignore next */
        if (entities.length < rowCount) {
          for (let i = entities.length; i < rowCount; i++) {
            entities.push(<li className="genre-module--entity" />);
          }
        }

        return (
          <ul className="genre-module--entity-list-page" key={`entity-list-page-${index}`}>
            {entities}
          </ul>
        );
      })
    );

    return pages;
  }

  renderEntitiesMobile() {
    const entities = this.getEntities();
    const {loading} = this.props;
    const DOM_entities = entities.map((entity) => {
      return (
        <li className="genre-module--entity" key={`genre-module--entity-${entity.position}`}>
          <FormattedLink href={entity.webURI} className="genre-module--entity-name">
            <CloudinaryMedia
              className="genre-module--entity-image"
              src={entity.image}
              alt={entity.name}
              publicIdSrc={entity.cloudinaryPublicId}
              enableCloudinary
              width="60"
              height="60"
            />
            <span>{entity.name}</span>
          </FormattedLink>
        </li>
      );
    });

    return (
      <div>
        <ul className="genre-module--entity-list">{DOM_entities}</ul>
        {this.showLoadMore() && (
          <button className="genre-module--load-more" onClick={() => this.loadMoreEntitiesMobile()} type="button">
            {loading ? 'Loading' : 'Load more'}
          </button>
        )}
      </div>
    );
  }

  renderEntitiesDesktop() {
    return (
      <div className="genre-module--entity-list-container">
        <ul className="genre-module--entity-list">{this.getEntitiesDesktop()}</ul>
      </div>
    );
  }

  renderNoResultsMobile() {
    const {
      eventEndDate,
      eventKeyDate,
      eventStartDate,
      geoName = '',
      radius = 50,
      selectedCategory,
      loading,
    } = this.props;

    return (
      <div>
        <div className="genre-module--no-results-mobile">
          {!loading && (
            <GenreEmptyMessage
              eventKeyDate={eventKeyDate}
              eventStartDate={eventStartDate}
              eventEndDate={eventEndDate}
              genreName={selectedCategory}
              geoName={geoName}
              isAlternate
              radius={radius}
            />
          )}
          <ul className="genre-module--no-results--background">
            <li>
              <div className="genre-module--no-results--background--icon" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            <li>
              <div className="genre-module--no-results--background--icon" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            <li>
              <div className="genre-module--no-results--background--icon" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            <li>
              <div className="genre-module--no-results--background--icon" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
          </ul>
        </div>
        <button className="genre-module--load-more disabled" type="button">
          Load more
        </button>
      </div>
    );
  }

  renderNoResultsDesktop() {
    const {
      eventEndDate,
      eventKeyDate,
      eventStartDate,
      geoName = '',
      radius = 50,
      selectedCategory,
      loading,
      isDesktop,
    } = this.props;
    const imageHeight = this.setImageHeight() || DEFAULT_IMAGE_HEIGTH;

    return (
      <div>
        <div className="genre-module--no-results-desktop">
          {!loading && (
            <GenreEmptyMessage
              eventKeyDate={eventKeyDate}
              eventStartDate={eventStartDate}
              eventEndDate={eventEndDate}
              genreName={selectedCategory}
              geoName={geoName}
              isAlternate
              radius={radius}
            />
          )}
          <ul className="genre-module--no-results--background">
            <li>
              <div className="genre-module--no-results--background--icon" style={{minHeight: imageHeight}} />{' '}
              <div className="genre-module--no-results--background--row" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            <li>
              <div className="genre-module--no-results--background--icon" style={{minHeight: imageHeight}} />{' '}
              <div className="genre-module--no-results--background--row" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            <li>
              <div className="genre-module--no-results--background--icon" style={{minHeight: imageHeight}} />{' '}
              <div className="genre-module--no-results--background--row" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            <li>
              <div className="genre-module--no-results--background--icon" style={{minHeight: imageHeight}} />{' '}
              <div className="genre-module--no-results--background--row" />{' '}
              <div className="genre-module--no-results--background--row" />
            </li>
            {isDesktop && (
              <li>
                <div className="genre-module--no-results--background--icon" style={{minHeight: imageHeight}} />{' '}
                <div className="genre-module--no-results--background--row" />{' '}
                <div className="genre-module--no-results--background--row" />
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }

  selectSubcategoryMobile = (category) => {
    this.category = category;

    this.getSearchEntity(true);
  };

  selectSubcategoryDesktop = (category) => {
    if (category === this.category) {
      return null;
    }

    this.setImageHeight();
    this.category = category;
    this.getSearchEntity(true);
  };

  setImageHeight() {
    const {namespaceCSS} = this.props;
    if (__CLIENT__) {
      const DOM_entityListImage = document.querySelector(`.${namespaceCSS} .genre-module--entity-image`);
      if (DOM_entityListImage) {
        this.imageHeight = DOM_entityListImage.offsetHeight;
      } else {
        const DOM_container = document.querySelector(`.${namespaceCSS} .genre-module--entity-list-outer-container`);
        if (DOM_container) {
          const containerWidth = DOM_container.offsetWidth;
          const cardsPerView = 5;
          const innerMargin = containerWidth < MARGIN_CONTAINER_BREAKPOINT ? SMALL_CONTAINER_MARGIN : CONTAINER_MARGIN;
          const aspectRatio = 0.785;
          this.imageHeight = ((containerWidth - innerMargin * (cardsPerView - 1)) / cardsPerView) * aspectRatio;
        }
      }
    } else {
      this.imageHeight = 169;
    }

    return this.imageHeight;
  }

  renderSubcategoriesDesktop() {
    const {genreModuleCategory} = this.props;
    const DOM_subcategories = GENRES[genreModuleCategory].map((category, index) => {
      const isActive = category === this.category;

      return (
        <li
          className={`genre-module--subcategories--subcategory${(isActive && ' active') || ''}`}
          key={`genre-module--subcategory-${index}`}
          onClick={() => this.selectSubcategoryDesktop(category)}
        >
          {category.displayName}
        </li>
      );
    });

    return <ul className="genre-module--subcategories-desktop">{DOM_subcategories}</ul>;
  }

  loadMoreEntitiesMobile = () => {
    /* istanbul ignore next */
    this.getSearchEntity();
  };

  loadMoreEntitiesDesktop = () => {
    this.scrollCarousel('right');
  };

  loadPreviousEntitiesDesktop = () => {
    this.scrollCarousel('left');
  };

  scrollCarousel = debounce((direction) => {
    const {namespaceCSS} = this.props;
    const DOM_previousArrow = document.querySelector(`.${namespaceCSS} .genre-module--entity-list--arrow-previous`);

    if (direction === 'left') {
      this.page -= 1;
    } else {
      this.page += 1;

      if (this.page >= this.getPagesLength() - 1) {
        this.getSearchEntity();
      }
    }

    if (this.page === 0) {
      DOM_previousArrow.classList.add('hidden');
    } else {
      DOM_previousArrow.classList.remove('hidden');
    }

    this.scrollCarouselToCurrentPage();
  }, CAROUSEL_DEBOUNCE);

  scrollCarouselToCurrentPage = () => {
    const {namespaceCSS} = this.props;
    const DOM_entityList = document.querySelector(`.${namespaceCSS} .genre-module--entity-list`);

    if (!DOM_entityList) {
      return;
    }

    DOM_entityList.style.marginLeft = `${-(this.page * PERCENTAGE)}%`;
  };

  getSearchEntity = (reset = false, nextProps = null) => {
    const {cookies} = this.context;
    const props = nextProps || this.props;
    const {getSearchEntity} = this.props;

    if (reset) {
      this.page = 0;
      this.scrollCarouselToCurrentPage();
    }

    return getSearchEntity(this.category, this.page, reset, {...props, cookies});
  };

  renderViewDesktop() {
    let DOM_partial = '';
    const {loading} = this.props;

    if (loading) {
      DOM_partial = <div className="genre-module--entity-list-outer-container">{this.renderNoResultsDesktop()}</div>;
    } else {
      DOM_partial = (
        <div className="genre-module--entity-list-outer-container">
          <div
            className="genre-module--entity-list--arrow genre-module--entity-list--arrow-previous hidden"
            onClick={() => this.loadPreviousEntitiesDesktop()}
          >
            <span />
          </div>
          {this.showLoadMore() && (
            <div
              className="genre-module--entity-list--arrow genre-module--entity-list--arrow-next"
              onClick={() => this.loadMoreEntitiesDesktop()}
            >
              <span />
            </div>
          )}
          {(this.getPagesLength() && this.renderEntitiesDesktop()) || this.renderNoResultsDesktop()}
        </div>
      );
    }

    return (
      <div className="genre-module--container-view-desktop">
        {this.renderSubcategoriesDesktop()}
        {DOM_partial}
      </div>
    );
  }

  renderViewMobile() {
    return (
      <div className="genre-module--container-view-mobile">
        <DropdownList
          className="event-filter"
          selectionTitle={this.category.name}
          selected={this.category}
          items={this.subcategories}
          onSelection={this.selectSubcategoryMobile}
          useCheckMark
          inPillBar
          draggable={false}
          useBackdrop
        />
        {(this.showEntities && this.renderEntitiesMobile()) || this.renderNoResultsMobile()}
      </div>
    );
  }

  componentHasPerformers() {
    return this.getPagesLength() > 0;
  }

  getPerformersLength() {
    return this.getEntities().length;
  }

  getPagesLength() {
    const {performers} = this.props;

    return performers.length;
  }

  showLoadMore() {
    const {performers, totalCount} = this.props;

    return this.page < performers.length - 1 || totalCount - this.getPerformersLength() > 0;
  }

  @ssr_wait
  UNSAFE_componentWillMount() {
    const {selectedCategory} = this.props;
    if (!this.componentHasPerformers()) {
      return this.getSearchEntity();
    } else if (selectedCategory !== this.category.name) {
      // Reset genre module to default on mount if previously selected category exists
      return this.getSearchEntity(true);
    }
  }

  componentWillUnmount() {
    /**
     *  Events
     */
    /* istanbul ignore next */
    window.removeEventListener('resize', this.onResizeEvent);

    /**
     *  Timers
     */
    /* istanbul ignore next */
    clearInterval(this.timers.imageHeight);
  }

  onResizeEvent = () => {
    if (__CLIENT__) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({newCurrHeight: this.windowWidth !== window.innerWidth});
      this.windowWidth = window.innerWidth;
    }
  };

  /* istanbul ignore next */
  componentDidMount() {
    if (!__CLIENT__) {
      return;
    }
    const {newCurrHeight: newCurrHeightState} = this.state || {};

    /**
     * Events
     */
    window.addEventListener('resize', this.onResizeEvent);

    /**
     *  Timers
     */
    this.timers.imageHeight = setInterval(() => {
      const currHeight = this.imageHeight;
      const newHeight = this.setImageHeight();
      if (currHeight !== newHeight && (!this.state || !newCurrHeightState)) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({newCurrHeight: true});
      }
    }, DEFAULT_TIMER);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {eventStartDate, eventEndDate, point} = this.props;
    if (
      nextProps.eventStartDate !== eventStartDate ||
      nextProps.eventEndDate !== eventEndDate ||
      nextProps.point !== point
    ) {
      this.getSearchEntity(true, nextProps);
    }
  }

  render() {
    this.showEntities = this.componentHasPerformers();
    const {fetchCount, namespaceCSS, isDesktop, isTablet} = this.props;

    /*
     * FetchCount is 0 initially in controller and increased by 1 in each
     * success callback. this prevents the component from showing the "no
     * results" view if it's the first fetch
     */
    if (fetchCount < 2 && !this.showEntities) {
      return null;
    }

    return (
      <div className={`${namespaceCSS} genre-module--container`}>
        {((isDesktop || isTablet) && this.renderViewDesktop()) || this.renderViewMobile()}
      </div>
    );
  }
}

GenreModule.propTypes = {
  /**
   * Configuration to Render the Tab
   */
  genreModuleCategory: PropTypes.string.isRequired,

  /**
   * Fetch count
   */
  fetchCount: PropTypes.number,

  /**
   * CSS classname
   */
  namespaceCSS: PropTypes.string,

  /**
   * If device is desktop
   */
  isDesktop: PropTypes.bool,

  /**
   * If device is tablet
   */
  isTablet: PropTypes.bool,

  /**
   * Event start date
   */
  eventStartDate: PropTypes.string,

  /**
   * Event end date
   */
  eventEndDate: PropTypes.string,
  point: PropTypes.string,

  /**
   * Event performers
   */
  performers: PropTypes.array.isRequired,

  /**
   * Name of selected category
   */
  selectedCategory: PropTypes.string,

  /**
   * Total of performers
   */
  totalCount: PropTypes.number,

  /**
   * Method to search elements by entity
   */
  getSearchEntity: PropTypes.func.isRequired,

  /**
   * If is loading
   */
  loading: PropTypes.bool,

  /**
   * Date search criteria
   */
  eventKeyDate: PropTypes.string,

  /**
   * Geo location name
   */
  geoName: PropTypes.string,

  /**
   * Radius
   */
  radius: PropTypes.number.isRequired,

  /**
   * Number of rows
   */
  rowCount: PropTypes.number,
};

const GenreModuleContainer = function (props) {
  const {genreModuleCategory} = props;

  return (
    <ControllerContainer
      namespace={`${genreModuleCategory}`}
      factory={controllerFactory}
      component={GenreModule}
      {...props}
    />
  );
};

GenreModuleContainer.propTypes = {
  /**
   * Configuration to Render the Tab
   */
  genreModuleCategory: PropTypes.string.isRequired,
};

export {GenreModule};
export default GenreModuleContainer;
