import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '@stubhub/react-spinner';

import LoadMore from '../react-load-more';
import PerformerCardList from '../react-performer-card-list';

import './index.scss';

export default function PerformerCardsGrid({eventData: performers, hasMore, loading, getMore, title, id}) {
  const morePerformers = performers?.length;

  return (
    <div id={id} className="performer-cards__container">
      {title && (
        <div className="page-title__top-category">
          <h1 className="page-title__text">{title}</h1>
        </div>
      )}
      <PerformerCardList performers={performers} />
      {loading && <Spinner expand={!morePerformers} />}
      {hasMore && !loading && <LoadMore onclickHandler={getMore} />}
    </div>
  );
}

PerformerCardsGrid.propTypes = {
  /**
   * The id of the element
   */
  id: PropTypes.string,

  /**
   * Indicates if the data is currently being loaded
   */
  loading: PropTypes.bool,

  /**
   * Indicates if there are more performers to load
   */
  hasMore: PropTypes.bool,

  /**
   * The title of the performer cards grid
   */
  title: PropTypes.object,

  /**
   * Array of performer data to be displayed
   */
  eventData: PropTypes.array,

  /**
   * Function to fetch more performers
   */
  getMore: PropTypes.func,
};
