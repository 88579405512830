import React from 'react';
import PropTypes from 'prop-types';

/**
 * JSONLD script element for SEO usage
 * Need consumer to pass in required JSONLD data in string format
 * @param {object} props
 * @param {string} props.jsonld JSONLD data in string format
 */
const SEOJSONLDElement = ({jsonld}) =>
  jsonld ? (
    // eslint-disable-next-line react/no-danger
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: jsonld}} />
  ) : null;

SEOJSONLDElement.propTypes = {
  jsonld: PropTypes.string,
};

export default SEOJSONLDElement;
