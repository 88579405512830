/**
 * @typedef Key
 * @property key {Array<string>|string}
 * @property which {number}
 * @property keyCode {number}
 */

/**
 * Check to see if the given key matches the corresponding keyboard event. Also
 * supports passing in the value directly if you can't used the given event.
 *
 * @example
 * import * as keys from '../keys';
 * import { matches } from '../match';
 *
 * const handleOnKeyDown = (event) => {
 *   if (match(event, keys.Enter) {
 *     // ...
 *   }
 * }
 *
 * @param {Event|number|string} eventOrCode
 * @param {Key} key
 * @returns {boolean}
 */
export const match = (eventOrCode, {key, which, keyCode} = {}) => {
  if (typeof eventOrCode === 'string') {
    return eventOrCode === key;
  }

  if (typeof eventOrCode === 'number') {
    return eventOrCode === which || eventOrCode === keyCode;
  }

  if (eventOrCode.key && Array.isArray(key)) {
    return key.indexOf(eventOrCode.key) !== -1;
  }

  return eventOrCode.key === key || eventOrCode.which === which || eventOrCode.keyCode === keyCode;
};

/**
 * Check to see if at least one key code matches the key code of the
 * given event.
 *
 * @example
 * import * as keys from '../keys';
 * import { matches } from '../match';
 *
 * const handleOnKeyDown = (event) => {
 *   if (matches(event, [keys.Enter, keys.Space]) {
 *     // ...
 *   }
 * }
 *
 * @param {Event} event
 * @param {Array<Key>} keysToMatch
 * @returns {boolean}
 */
export const matches = (event, keysToMatch) => {
  for (let i = 0; i < keysToMatch.length; i += 1) {
    if (match(event, keysToMatch[i])) {
      return true;
    }
  }

  return false;
};

/**
 * Get a string character for a given event or event code (useful for synthetic
 * events)
 *
 * @param {Event|number} eventOrCode
 * @returns {string}
 */
export const getCharacterFor = (eventOrCode) => {
  if (typeof eventOrCode === 'number') {
    return String.fromCharCode(eventOrCode);
  }

  return eventOrCode.key || String.fromCharCode(eventOrCode.which || eventOrCode.keyCode);
};
