import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import JumbotronBanner from '../../../modules/react-jumbotron-banner/jumbotronBanner';

import messages from './messages';

/**
 * Titles for top categories.
 */
const CATEGORIES = {
  1: messages.concertTickets,
  28: messages.sportTickets,
  174: messages.theaterTickets,
};

/**
 * A component to represent a banner on category pages.
 * @param {string} categoryId A category id for the page.
 * @extends {React.Component}
 */
class CategoryPageBanner extends Component {
  /**
   * Renders a component. Prepares parameters and iuses JumbotronBanner for
   * final rendering.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {categoryId, intl} = this.props;
    const category = {
      name: intl.formatMessage(CATEGORIES[categoryId] || messages.defaultTickets),
      webURI: '',
    };
    const breadcrumbConfig = {
      breadcrumbData: [],
      showHome: true,
    };
    const headerData = {
      breadcrumbConfig,
      heading: category.name,
      jumbotronType: 'default',
    };

    return <JumbotronBanner entity={category} headerData={headerData} {...this.props} />;
  }
}

CategoryPageBanner.propTypes = {
  /** Category ID */
  categoryId: PropTypes.string.isRequired,

  /** The intl object */
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CategoryPageBanner);
