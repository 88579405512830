import React from 'react';
import PropTypes from 'prop-types';

import {ENTITY_TYPE_VENUE} from '@stubhub/entity-helper';
import {formatLocation, formatVenue} from '@stubhub/location-util';

const FormattedLocation = ({location, format, type = 'geo'}, {globalRegistry}) => {
  const formatter = type === ENTITY_TYPE_VENUE ? formatVenue : formatLocation;
  const formattedLocation = formatter(location, globalRegistry.getCurrentCountry(), format);

  return <span>{formattedLocation}</span>;
};

FormattedLocation.propTypes = {
  /** The current location */
  location: PropTypes.object.isRequired,

  /** The format of the location */
  format: PropTypes.string,

  /** The type of the location */
  type: PropTypes.string,
};

FormattedLocation.contextTypes = {
  globalRegistry: PropTypes.object,
};

export default FormattedLocation;
