/*
 **  Configuration to determine which attributes are shown in the entity card
 */

export const entityCardMapping = {
  event: {
    showEntityGenre: true,
    showEntityTime: true,
    showEntityName: true,
    showEventVenue: true,
    showEventLocation: true,
    showFromPrice: false,
  },
  performer: {
    showEntityGenre: false,
    showEntityTime: false,
    showEntityName: true,
    showEventVenue: false,
    showEventLocation: false,
    showFromPrice: false,
  },
  test: {
    showEntityGenre: true,
    showEntityTime: true,
    showEntityName: true,
    showEventVenue: true,
    showEventLocation: true,
    showFromPrice: true,
  },
};
