import {createSelector} from 'reselect';

import {Controller} from '@stubhub/react-store-provider';

import {getScreenSize} from '../../helpers/screen-device-helper';

import {getRecoEntity} from './reco-entity';
import {getSearchEntity} from './search-entity';
const ACTION_TYPES = {
  ENTITYLIST_LOADDATA: 'EntityList.loadData',
};

const userStateSelector = (state) => state.user;
const userLocationSelector = createSelector(userStateSelector, (user) => (user ? user.location : null));
const pointSelector = createSelector(userLocationSelector, (location) =>
  location ? `${location.latitude},${location.longitude}` : null
);
const viewportSelector = (state) => state.viewport;
const screenSizeSelector = createSelector(
  viewportSelector,
  (/* istanbul ignore next */ viewport = {}) => viewport.screenSize
);
const shStoreIdSelector = createSelector(
  (state) => state.gsConfig || {},
  (gsConfig) => gsConfig && gsConfig.shstoreId
);

function generateActionCreators(instanceNamespace) {
  return {
    fetchEntity: (type, props, reset) => {
      return (dispatch, getState) => {
        dispatch({type: `${instanceNamespace}:LOADING`, reset});
        /* istanbul ignore else */
        if (type === 'performer' || type === 'event') {
          let cookies = null;
          const {useFilters = true, passUserInfo = true} = props;

          // If not using filters, we need to clear them out and pass to API
          if (useFilters === false) {
            props = removeFilterFromProps(props);
          }

          // If not using cookies for visitor or guid
          if (passUserInfo) {
            ({cookies} = props);
          }

          // Reset offset
          if (reset) {
            props.offset = 0;
          }

          return getRecoEntity(props, cookies, getState().lang).then((entityData) => {
            const processedEntityData = processEntityData(type, entityData);
            dispatchEntityLoaded(dispatch, instanceNamespace, props, reset, processedEntityData);

            return null;
          });
          /* istanbul ignore else */
        } else if (type === 'performerEventList' || type === 'eventList') {
          let cookies = null;
          const {useFilters = true, passUserInfo = true} = props;

          // If not using filters, we need to clear them out and pass to API
          if (useFilters === false) {
            props = removeFilterFromProps(props);
          }

          // If not using cookies for visitor or guid
          if (passUserInfo) {
            ({cookies} = props);
          }

          // Reset offset
          if (reset) {
            props.offset = 0;
          }

          return getSearchEntity(props, cookies, getState().lang).then((entityData) => {
            const processedEntityData = processEntityData(type, entityData);
            dispatchEntityLoaded(dispatch, instanceNamespace, props, reset, processedEntityData);

            return null;
          });
        }
      };
    },

    destroy: () => {
      return {
        type: `${instanceNamespace}:RESET`,
      };
    },
  };
}

function removeFilterFromProps(props) {
  delete props.point;
  delete props.eventEndDate;
  delete props.eventStartDate;

  return props;
}

function processEntityData(type, data) {
  /* istanbul ignore else */
  if (type === 'event') {
    // Update data to only contain events from the performer feed
    const performers = data.data;

    const events = [];
    for (let i = 0; i < performers.length; i++) {
      const performer = performers[i];
      const performerEvents = performer.events;
      /* istanbul ignore else */
      if (performerEvents && performerEvents.length > 0) {
        const [event] = performerEvents;

        /*
         * We meed to pass additional information from the parent
         * down for tracking. create link to parent
         */
        event.__parent = performer;

        // Add JSONLD required data
        event.categories = (performer.ancestors || {}).categories || [];
        event.performersCollection = [performer];
        event.eventDateLocal = event.dateLocal;

        events.push(event);
      }
    }

    data.data = events;

    return data;
  } else if (type === 'performerEventList' || type === 'eventList') {
    // Filter out all non-active events
    data.data = data.data.filter((event) => event.status === 'Active');

    return data;
  }

  // No need to do anything - existing response is fine
  return data;
}

function dispatchEntityLoaded(dispatch, instanceNamespace, props, reset, entity) {
  const {totalCount, data, impressionToken} = entity;
  let offset = reset ? 0 : props.offset || 0;
  offset += data.length;
  const hasMore = offset < totalCount;
  const loading = false;
  let entityData = reset ? [] : props.entityData;

  /**
   * Append the Event Data
   *
   */
  entityData = entityData.concat(data);

  dispatch({type: `${instanceNamespace}:LOADED`, entityData, impressionToken, totalCount, offset, hasMore, loading});
}

export default function controllerFactory(namespace) {
  const actionType = `${ACTION_TYPES.ENTITYLIST_LOADDATA}`;
  const instanceNamespace = `${actionType}:${namespace}`;

  /**
   * Derive the Filter Namespace from the context
   *
   */
  const controller = new Controller({
    namespace: instanceNamespace,
    mapStateToProps(state, ownProps) {
      const localState = this.getLocalState(state);

      return {
        resizeReset: localState.resizeReset || false,
        reset: localState.reset || false,
        // Mostly generic+
        entityData: localState.entityData || [],
        loading: localState.loading !== false,
        offset: localState.offset || 0,
        hasMore: localState.hasMore || false,
        totalCount: localState.totalCount || 0,

        /*
         * Via filters/url query parameters
         * NOTE: may not apply to all entity types
         */
        point: ownProps.point || pointSelector(state),
        screenSize: ownProps.screenSize || getScreenSize(screenSizeSelector(state), state.device),
        // Specific to reco capi
        impressionToken: localState.impressionToken,
        shstore: shStoreIdSelector(state),
      };
    },
    actionCreators: generateActionCreators(instanceNamespace),
  });

  controller.addReducer(
    `${instanceNamespace}:LOADING`,
    function (state, action) {
      const {reset} = action;
      const s = {loading: true};

      const localState = this.getLocalState(state);

      /*
       * If this is the initial run (empty state), keep track of it
       * we need this because UPDATE_SCREEN_SIZE dispatch can happen at any time (before or during load)
       * so this can help us minimize situations where we force a re-fetch resulting in multiple API calls
       */
      if (Object.keys(localState).length === 0) {
        s.initialRun = true;
      }

      /*
       * Make sure the NAMESPACE is used otherwise value will be
       * written into root which could be a wrong place
       */
      if (reset === true) {
        s.resizeReset = false;
        s.reset = false;
        s.offset = 0;
        s.totalCount = 0;
        s.hasMore = false;
        s.entityData = [];
      }

      return this.setLocalState(state, s);
    }.bind(controller)
  );

  controller.addReducer(
    `${instanceNamespace}:LOADED`,
    function (state, action) {
      const {entityData, impressionToken, offset, hasMore, loading, totalCount} = action;

      const s = {
        entityData,
        impressionToken,
        loading,
        hasMore,
        offset,
        totalCount,
      };

      // Set initial run to false, after first fun
      const localState = this.getLocalState(state);
      if (localState.initialRun === true) {
        s.initialRun = false;
      }

      return this.setLocalState(state, s);
    }.bind(controller)
  );

  // Disabling for now. Seems to report wrong size in mobile
  controller.addReducer(
    'UPDATE_SCREEN_SIZE',
    function (state) {
      const localState = this.getLocalState(state);
      const s = {};

      /*
       * If there is no viewport in globsl state
       * but there are entities, server has rendered
       * DO NOT SET RESIZERESET WHEN SERVER HAS RENDERED
       */
      const serverSideRendered = !state.viewport && localState.entityData.length > 0;

      if (!serverSideRendered) {
        /*
         * If still waiting for initial data fetch, UPDATE_SCREEN_SIZE could still be triggered
         * so in that situation, don't re-fetch data (multiple API calls)
         */
        if (localState.initialRun === false) {
          s.resizeReset = true;
        }
      }

      return this.setLocalState(state, s);
    }.bind(controller)
  );

  controller.addReducer([`${instanceNamespace}:RESET`]);
  controller.addResetReducer(['USER_LOCATION_CHANGE', 'USER_DATE_FILTER_CHANGED', `${instanceNamespace}:RESET`]);

  return controller;
}
