import {defineMessages} from 'react-intl';

/**
 * The definition of messages to be used in the component and available in
 * supported translations. If translation is not available the default message
 * is used.
 */
const messages = defineMessages({
  CBT_MODULE_TITLE: {
    id: 'CBTModule.label.title',
    defaultMessage: "Can't find the event you are looking for?",
  },
  CBT_MODULE_DESCRIPTION: {
    id: 'CBTModule.label.description',
    defaultMessage:
      'You may want to check stubhub.ie, which has our entire global catalogue of events. As that site is governed by Irish Legislation, certain features you may be used to seeing here may not be available.',
  },
  CBT_MODULE_BUTTON_TEXT: {
    id: 'CBTModule.label.button',
    defaultMessage: 'Take me there!',
  },
});

export default messages;
