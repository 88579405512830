import {ENTITY_TYPE_CATEGORY, ENTITY_TYPE_PERFORMER} from '@stubhub/entity-helper';

/**
 * Method that returns if avatar can be displayed
 * @param {string} entityType - type of entity
 * @param {string} src - image URL
 * @param {object} image - Cloudinary image
 * @returns if avatar can be displayed
 */
export const isAvatarElegible = (entityType, src, image) => {
  return !!(
    (entityType === ENTITY_TYPE_PERFORMER || entityType === ENTITY_TYPE_CATEGORY) &&
    ((image !== undefined && image !== null) || src)
  );
};
