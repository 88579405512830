/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import './index.scss';

export const MOBILE_LOAD_LIMIT = 15;

const MIN_WIDTH = 248;
const TABLET_BREAKPOINT = 950;
const PIXEL_VALUES = {
  HALF: 0.5,
  MULTIPLY: 264,
  NEXT_BUTTON_OFFSET: 280,
};

const resolvePerpage = (perPage) => {
  let resolvedPerPage = 1;
  if (typeof perPage === 'number') {
    resolvedPerPage = perPage;
  } else {
    const windowInnerWidth = window.innerWidth;
    for (const breakpoint in perPage) {
      if (windowInnerWidth >= breakpoint) {
        resolvedPerPage = perPage[breakpoint];
      }
    }
  }

  return resolvedPerPage;
};

/**
 * This implements a carousel that lazy-loads its content as user pages/scroll.
 */
class LazyLoadCarousel extends Component {
  constructor(props, context) {
    const {startPage} = props;
    super(props, context);
    const {initialSliderFrameWidth} = props;
    this.state = {
      perPage: 1,

      /*
       * Zero-based page number. This is only relevant in desktop mode. In mobile,
       * it's always 0.
       */
      currentPage: startPage,

      /*
       * Total number of pages of data that can be loaded. This is dependent upon
       * the nuber of items per page for the current screen size.
       */
      numPages: 0,
      // Width of the visible portion of the carousel
      viewportWidth: 0,

      /*
       * Total width of the carousel (including hidden part). This is computed
       * on the client, based on each item's outer width x total number of items.
       */
      sliderFrameWidth: initialSliderFrameWidth || 0,
      itemMarginLeft: 8,
      itemMarginRight: 8,

      /*
       * How much the content is scrolled from the left. Used to differentiate
       * between left and right scrolling.
       */
      scrollLeft: 0,
    };
  }

  onResize = debounce(() => {
    const {children} = this.props;
    if (children.length) {
      this.init();
    }
  }, this.props.resizeDebounce);

  componentDidMount() {
    const {children} = this.props;
    window.addEventListener('resize', this.onResize);

    const childLength = children.length;
    if (childLength > 0) {
      this.init();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    /* istanbul ignore else */
    if (newProps.resetPage) {
      // Reset the carousel's page.
      this.setState({
        currentPage: 0,
      });

      this.viewport.scrollLeft = 0;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    const {children, totalCount} = this.props;
    const childLength = children.length;
    if ((childLength > 0 && prevProps.children.length !== childLength) || totalCount !== prevProps.totalCount) {
      this.init();
    }
  }

  init() {
    const {perPage: pp} = this.props;
    const perPage = resolvePerpage(pp);
    const {children, totalCount} = this.props;
    const childLength = children.length;
    const {currentPage} = this.state;
    /* istanbul ignore next */
    if (!this.viewport) {
      /*
       * Guard against viewport not available (component in the middle of
       * being unmounted)
       */
      return;
    }

    const viewportWidth = this.viewport.getBoundingClientRect().width;
    const {marginLeft, marginRight} = this.getChildBoxMargins();

    const itemInnerWidth =
      (viewportWidth - (Math.ceil(perPage) - 1) * marginLeft - (Math.ceil(perPage) - 1) * marginRight) / perPage;
    const itemOutterWidth = itemInnerWidth + (marginLeft + marginRight);
    const sliderFrameWidth = pp
      ? (totalCount + PIXEL_VALUES.HALF) * PIXEL_VALUES.MULTIPLY
      : Math.max(childLength, perPage) * itemOutterWidth;
    const numPages = Math.ceil(totalCount / perPage);

    this.setState({
      currentPage: Math.max(Math.min(currentPage, numPages - 1), 0),
      viewportWidth,
      numPages,
      perPage,
      sliderFrameWidth,
      itemMarginLeft: marginLeft,
      itemMarginRight: marginRight,
    });
  }

  getChildBoxMargins() {
    const [firstChild] = this.sliderFrame.children;
    const childStyle = window.getComputedStyle(firstChild, null);

    return {
      marginLeft: parseFloat(childStyle.getPropertyValue('margin-left')) || 0,
      marginRight: parseFloat(childStyle.getPropertyValue('margin-right')) || 0,
    };
  }

  goToPrev = () => {
    const {currentPage} = this.state;
    this.setState({
      currentPage: Math.max(0, currentPage - 1),
    });
  };

  goToNext = () => {
    const {children, totalCount, loadEvents} = this.props;
    const {perPage, currentPage} = this.state;

    // Update page number
    this.setState({
      currentPage: currentPage + 1,
    });

    /*
     * Find the number of events we need to load to complete next page. If it's greater
     * than zero, that means we have more items to load.
     */
    const numChildren = children.length;
    const limit = perPage * (currentPage + 4) - numChildren;
    if (limit > 0 && totalCount !== numChildren) {
      loadEvents(numChildren + 1, limit);
    }
  };

  onScroll = (evt) => {
    /*
     * Cannot debounce this function directly because the evt parameter is a
     * SyntheticEvent, which is reused by the framework and cannot be accessed
     * in an asynchronous way. Extract the currentTarget instead, which is a
     * more permanent object.
     */
    this.processOnScroll(evt.currentTarget);
  };

  processOnScroll = debounce((currentTarget) => {
    const {children, totalCount, loadEvents} = this.props;
    const numChildren = children.length;
    const {scrollLeft} = currentTarget;
    const {scrollLeft: stateScrollLeft, viewportWidth, sliderFrameWidth} = this.state;
    if (scrollLeft > stateScrollLeft) {
      /*
       * When we are roughly two visible screen lengths away to the end, load more
       * events if they exist.
       */
      if (
        scrollLeft + viewportWidth * 2 >= sliderFrameWidth ||
        scrollLeft >= (numChildren - MOBILE_LOAD_LIMIT) * PIXEL_VALUES.MULTIPLY
      ) {
        // Load up to 5 more
        const limit = Math.min(MOBILE_LOAD_LIMIT, totalCount - numChildren);
        /* istanbul ignore else */
        if (limit > 0 && totalCount !== numChildren) {
          loadEvents(numChildren + 1, limit);
        }
      }
    }

    // Update scrollLeft state
    this.setState({
      scrollLeft,
    });
  }, this.props.scrollDebounce);

  bindViewport = (node) => {
    this.viewport = node;
  };

  bindSlideFrame = (node) => {
    this.sliderFrame = node;
  };

  isShowPrevButton() {
    const {currentPage} = this.state;

    return currentPage !== 0;
  }

  isShowNextButton() {
    const {currentPage, numPages} = this.state;

    return currentPage < numPages - 1;
  }

  render() {
    const {currentPage, perPage, itemMarginLeft, itemMarginRight, viewportWidth} = this.state;
    const {totalCount, className, duration, easing, carouselScrollMode, autoScaleMode, children} = this.props;
    const isMediumOrBelow = typeof window !== 'undefined' && window.innerWidth < TABLET_BREAKPOINT;
    let fullItemPerPage = parseInt(perPage);
    let transform = 0;
    let recalculatedSliderFrameWidth = 0;
    let childWidth = 0;
    let childFullWidth = 0;
    let nextButtonOffset = 0;

    if (isMediumOrBelow) {
      childWidth = MIN_WIDTH;
    } else {
      childWidth =
        (viewportWidth + itemMarginLeft + itemMarginRight) / fullItemPerPage - itemMarginLeft - itemMarginRight;
    }

    // Set perPage to be plus 0.5 under mobile scrolling mode
    if (carouselScrollMode) {
      fullItemPerPage = fullItemPerPage + PIXEL_VALUES.HALF;
      childWidth =
        (viewportWidth + itemMarginLeft + itemMarginRight) / fullItemPerPage - itemMarginLeft - itemMarginRight;
    } else if (autoScaleMode) {
      childWidth =
        (viewportWidth + itemMarginLeft + itemMarginRight) / fullItemPerPage - itemMarginLeft - itemMarginRight;
    }

    // Fix server side rendering childWidth always return 0 issue
    if (childWidth === 0) {
      childWidth = MIN_WIDTH;
    }
    childFullWidth = childWidth + itemMarginLeft + itemMarginRight;
    recalculatedSliderFrameWidth = childFullWidth * totalCount;
    nextButtonOffset = `${Math.max(childWidth - PIXEL_VALUES.NEXT_BUTTON_OFFSET, 0)}px`;

    transform = -Math.min(
      itemMarginLeft + currentPage * fullItemPerPage * childFullWidth,
      recalculatedSliderFrameWidth - viewportWidth - itemMarginRight
    );
    transform = transform <= -itemMarginLeft ? transform : -itemMarginLeft;

    const styles = {
      width: `${recalculatedSliderFrameWidth}px`,
      transform: `translateX(${transform}px)`,
      transition: `transform ${duration}ms ${easing}`,
    };

    return (
      <div className={cx('LazyLoadCarousel', className)}>
        <div className="LazyLoadCarousel__ViewPort" ref={this.bindViewport} onScroll={this.onScroll}>
          <div className="LazyLoadCarousel__ViewPort__SliderFrame" ref={this.bindSlideFrame} style={styles}>
            {React.Children.map(children, (child, index) => {
              const {flexGrow = 1, ...props} = child.props;
              const itemStyle = {flexGrow, maxWidth: childWidth, flexBasis: childWidth};

              return (
                <div key={index} className="LazyLoadCarousel__Item" style={itemStyle}>
                  <child.type {...props} />
                </div>
              );
            })}
          </div>
        </div>
        <div className={`LazyLoadCarousel__Buttons${autoScaleMode && !carouselScrollMode ? ' display' : ''}`}>
          {this.isShowPrevButton() && (
            <button
              className={`LazyLoadCarousel__Buttons__prev${carouselScrollMode ? ' hide' : ''}`}
              aria-label="Previous"
              onClick={this.goToPrev}
              type="button"
            >
              <div className="LazyLoadCarousel__PrevArrow">
                <span />
              </div>
            </button>
          )}
          {this.isShowNextButton() && (
            <button
              className={`LazyLoadCarousel__Buttons__next${carouselScrollMode ? ' hide' : ''}`}
              aria-label="Next"
              onClick={this.goToNext}
              style={{right: nextButtonOffset}}
              type="button"
            >
              <div className="LazyLoadCarousel__NextArrow">
                <span />
              </div>
            </button>
          )}
        </div>
      </div>
    );
  }
}

LazyLoadCarousel.propTypes = {
  /**
   * The Class Name used to override the Styling for the component.
   *
   */
  className: PropTypes.string,
  resizeDebounce: PropTypes.number,
  scrollDebounce: PropTypes.number,
  duration: PropTypes.number,
  easing: PropTypes.string,
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  // Whether to reset the page number (initial load always does)
  resetPage: PropTypes.bool,
  // The total number of items that can be loaded
  totalCount: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,

  /*
   * Callback to load more events when needed. It will be passed two parameters:
   * startIndex and limit.
   */
  loadEvents: PropTypes.func,

  /*
   * Carousel scroll mode will force to disable navigation button and display 1.5 / 2.5 cards each screen
   * usually used in mweb mode
   */
  carouselScrollMode: PropTypes.bool,
  autoScaleMode: PropTypes.bool, // Auto scale mode will autimatically compute card width according to screen size and card number each screen
  startPage: PropTypes.number, // Starting page index
  initialSliderFrameWidth: PropTypes.number,
};

LazyLoadCarousel.defaultProps = {
  resizeDebounce: 250,
  scrollDebounce: 250,
  duration: 1000,
  easing: 'ease-in-out',
  perPage: {
    320: 1,
    640: 2,
    960: 3,
    1280: 4,
  },
  resetPage: false,
  totalCount: 0,
  loadEvents: () => undefined,
  startPage: 0,
};

export default LazyLoadCarousel;
