import {defineMessages} from 'react-intl';

/**
 * The definition of messages to be used in the component and available in
 * supported translations. If translation is not available the default message
 * is used.
 */
const messages = defineMessages({
  concertTickets: {
    id: 'CategoryPage.Banner.Concerts.Title',
    defaultMessage: 'Concert tickets',
  },
  defaultTickets: {
    id: 'CategoryPage.Banner.Default.Title',
    defaultMessage: 'Tickets',
  },
  sportTickets: {
    id: 'CategoryPage.Banner.Sports.Title',
    defaultMessage: 'Sport tickets',
  },
  theaterTickets: {
    id: 'CategoryPage.Banner.Theater.Title',
    defaultMessage: 'Theater & Comedy tickets',
  },
});

export default messages;
