import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FormattedLink from '@stubhub/react-formatted-link';
import {connect, ssr_wait} from '@stubhub/react-store-provider';

import controller from './controller';

import './index.scss';

export class CMSBillboard extends PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  @ssr_wait
  init() {
    const {loadBillboardInfo} = this.props;

    return loadBillboardInfo(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {categoryId, loadBillboardInfo} = this.props;
    if (categoryId !== nextProps.categoryId) {
      loadBillboardInfo(nextProps);
    }
  }

  UNSAFE_componentWillMount() {
    const {loadDataFailed} = this.props;
    // This value being falsy means data has not yet been loaded.
    if (!this.getBackgroundImageUrl() && !loadDataFailed) {
      this.init();
    }
  }

  getBackgroundImageUrl() {
    const {deviceType, lang, defaultLocale, loadDataFailed, largeImgUrl, smallImgUrl} = this.props;
    if (lang !== defaultLocale || loadDataFailed) {
      return false;
    }
    if (deviceType === 'desktop' || deviceType === 'tablet') {
      return largeImgUrl;
    }

    return smallImgUrl;
  }

  render() {
    const url = this.getBackgroundImageUrl();
    const {redirectUrl} = this.props;
    let noLinkCss = '';

    if (!redirectUrl) {
      noLinkCss = 'cms-billboard__imageLink--noclick';
    }

    if (url) {
      return (
        <div className="cms-billboard__container">
          {/* Hide title until its supported */}
          {/* <div className="cms-billboard__title">{this.props.title}</div>*/}
          <FormattedLink className={cx('cms-billboard__imageLink', noLinkCss)} href={redirectUrl}>
            <img className="cms-billboard__image" src={url} alt="" />
          </FormattedLink>
        </div>
      );
    }

    return null;
  }
}

CMSBillboard.propTypes = {
  categoryId: PropTypes.string,
  deviceType: PropTypes.string,
  redirectUrl: PropTypes.string,
  smallImgUrl: PropTypes.string,
  largeImgUrl: PropTypes.string,
  loadDataFailed: PropTypes.bool,
  lang: PropTypes.string,
  defaultLocale: PropTypes.string,
  loadBillboardInfo: PropTypes.func.isRequired,
};

CMSBillboard.defaultProps = {
  categoryId: '',
  deviceType: 'phone',
  redirectUrl: '',
  smallImgUrl: '',
  largeImgUrl: '',
  loadDataFailed: false, // True means some API error has occurred loading the data
  lang: '',
  defaultLocale: '',
};

CMSBillboard.contextTypes = {
  queue: PropTypes.object,
};

// Not using decorators because there are currently some issues, e.g. with SSR
export default connect(controller)(CMSBillboard);
