import {publish} from '@stubhub/pubsub-utils';
import {selectors as dateFilterSelectors} from '@stubhub/react-date-filter';
import {Controller} from '@stubhub/react-store-provider';

import {deviceTypeSelector} from '../../store/device/selectors';
import {defaultWebTLDSelector} from '../../store/gsConfig/selectors';
import {
  userLocationSelector,
  currentLocationSelector,
  pointSelector,
  getGeoCityNameSelector,
} from '../../store/user/selectors';
import {HOME_EVENTS} from '../../tracking/track-enum';
import {get} from '../../utils/api';
import {getEntity} from '../../utils/catalog';
import {CMS_CACHE_MAX_AGE} from '../../utils/constants';

const JUMBOTRON_CMS_DMA = 2;
const JUMBOTRON_CMS_DEFAULT = 1;
const JUMBOTRON_CONTENT = 0;

const controller = new Controller({
  namespace: 'Home',
  mapStateToProps(state) {
    const homeState = this.getLocalState(state);
    const referrer = state.referrer || '';

    return {
      recoToken: homeState.recoToken,
      eventStartDate: dateFilterSelectors.startDate(state),
      eventEndDate: dateFilterSelectors.endDate(state),
      point: pointSelector(state),
      userLocation: userLocationSelector(state),
      currentLocation: currentLocationSelector(state),
      deviceType: deviceTypeSelector(state),
      seoMeta: homeState.seoMeta || {},
      referrer,
      defaultWebTLD: defaultWebTLDSelector(state),
      geoCityId: getGeoCityNameSelector(state),
      jumbotronArray: homeState.jumbotronArray,
    };
  },
  actionCreators: {
    fetchCategorySEO,
    destroy,
    trackGTM,
    loadJumbotronCarouselBanners,
  },
});

function destroy() {
  return (dispatch) => {
    dispatch({type: 'HOME_RESET'});
  };
}

function trackGTM() {
  return (dispatch) => {
    dispatch({type: 'HOME_TRACK_GTM'});
  };
}

function fetchCategorySEO({categoryId}, entityType) {
  return (dispatch, getState) => {
    const state = getState() || {};
    const {gsConfig = {}} = state;

    return getEntity(entityType, categoryId, gsConfig.shstoreId, state.lang).then((body) => {
      const {seoMeta} = body;
      dispatch({type: 'CATEGORY_SEO_LOADED', seoMeta});
      publish(HOME_EVENTS.CATEGORY, {categoryId, entityType, data: seoMeta});

      return null;
    });
  };
}

/* istanbul ignore next */

/**
 * Loads Jumbotron configuration from CMS integration or static JSON file from
 * "content-content" API.
 * @param {boolean} hasJumbotronIntegratedCMS Wether a CMS integration is
 * available.
 */
function loadJumbotronCarouselBanners(hasJumbotronIntegratedCMS, userLocation) {
  let state = JUMBOTRON_CONTENT;
  let query = {};

  if (hasJumbotronIntegratedCMS) {
    state = JUMBOTRON_CMS_DEFAULT;
    if (userLocation && userLocation.latitude && userLocation.longitude) {
      state = JUMBOTRON_CMS_DMA;
      query = {
        lat: userLocation.latitude,
        long: userLocation.longitude,
      };
    }
  }

  return (dispatch, getState, {logger}) => {
    const requestOptions = {
      host: process.env.REACT_APP_CMX_API_HOST,
      path: '/cms/content-content/homepage/components.json',
      proxy: process.env.REACT_APP_GENERAL_PROXY_SERVER,
      json: true,
      cache: true,
      cacheMaxAge: CMS_CACHE_MAX_AGE,
      logger,
    };
    const requestOptionsCMS = {
      ...requestOptions,
      host: process.env.REACT_APP_JUMBOTRON_CMS_HOST,
      path: '/api/jumbotron/cards',
      auth: false,
    };
    const requestOptionsCMSDMA = {...requestOptionsCMS, query};

    let opts = requestOptions;
    if (state === JUMBOTRON_CMS_DMA) {
      opts = requestOptionsCMSDMA;
    } else if (state === JUMBOTRON_CMS_DEFAULT) {
      opts = requestOptionsCMS;
    }

    return get(opts)
      .then((body = {}) => {
        if (state === JUMBOTRON_CMS_DMA || state === JUMBOTRON_CMS_DEFAULT) {
          const data = body;
          if (!data || !data.length || data.length < 4) {
            throw new Error('No Jumbotron CMS data.');
          }

          return data;
        }

        return body;
      })
      .catch((error) => {
        if (state !== JUMBOTRON_CMS_DMA) {
          throw error;
        }
        state = JUMBOTRON_CMS_DEFAULT;

        return get(requestOptionsCMS);
      })
      .then((body = {}) => {
        if (state === JUMBOTRON_CMS_DMA || state === JUMBOTRON_CMS_DEFAULT) {
          const data = body;
          if (!data || !data.length || data.length < 4) {
            throw new Error('No Jumbotron CMS data.');
          }

          return data;
        }

        return body;
      })
      .catch((error) => {
        if (state !== JUMBOTRON_CMS_DEFAULT) {
          throw error;
        }
        state = JUMBOTRON_CONTENT;

        return get(requestOptions);
      })
      .then((body = {}) => {
        let data = [];
        if (state === JUMBOTRON_CMS_DMA || state === JUMBOTRON_CMS_DEFAULT) {
          data = body;
        } else {
          data = body.jumbotron;
        }
        dispatch({
          type: 'JUMBOTRON_CONTENT_LOADED',
          jumbotronArray: data,
        });

        return null;
      })
      .catch((error) => {
        logger && logger.error(error);
      });
  };
}

controller.addReducer('CATEGORY_SEO_LOADED');
controller.addReducer('JUMBOTRON_CONTENT_LOADED');
controller.addResetReducer(['HOME_RESET', 'USER_LOCATION_CHANGE']);

export default controller;
