import isWithinInterval from 'date-fns/isWithinInterval';
import {createSelector} from 'reselect';

import {Controller} from '@stubhub/react-store-provider';
import {get} from '@stubhub/rest-method';

import {BILLBOARD_CACHE_MAX_AGE} from '../../utils/constants';

// TODO: implement mapping at the tool side (when we move to Drupal 8)
const STORE_ID = {
  US: 1,
  UK: 2,
  DE: 3,
  FR: 4,
  MX: 5,
  ES: 6,
  PT: 7,
  CA: 8,
  CZ: 9,
  IT: 10,
  NL: 11,
  AT: 12,
  BE: 13,
  IE: 14,
  FI: 15,
  LU: 16,
  GR: 17,
  PL: 18,
  SE: 19,
  DK: 20,
};
const NAMESPACE = 'cms-billboard';
export const CMS_API_TIMEOUT = 1000;
export const CMS_BILLBOARD_INFO_LOADED = 'CMS_BILLBOARD_INFO_LOADED';
export const CMS_BILLBOARD_INFO_LOAD_FAILED = 'CMS_BILLBOARD_INFO_LOAD_FAILED';

const deviceSelector = (state) => state.device;
const deviceTypeSelector = createSelector(deviceSelector, (device = {}) => device && device.type);

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(/* istanbul ignore next */ state = {}) {
    const {user = {}, lang, gsConfig = {}} = state;
    const userLocation = user.location;
    const localState = this.getLocalState(state);

    return {
      shstoreId: state.gsConfig && state.gsConfig.shstoreId,
      point: userLocation && `${userLocation.latitude},${userLocation.longitude}`,
      deviceType: deviceTypeSelector(state),
      title: localState.title,
      redirectUrl: localState.redirectUrl,
      smallImgUrl: localState.smallImgUrl,
      largeImgUrl: localState.largeImgUrl,
      loadDataFailed: localState.loadDataFailed,
      lang,
      defaultLocale: gsConfig.defaultLocale,
    };
  },
  actionCreators: {
    loadBillboardInfo,
  },
  reducers: [CMS_BILLBOARD_INFO_LOADED, CMS_BILLBOARD_INFO_LOAD_FAILED],
});

function getBillboardEventsUrl() {
  return '/v1/billboard';
}

function getRequestHeaders(state) {
  const language = state.lang;
  const ret = {};

  /* istanbul ignore else */
  if (language) {
    ret['Accept-Language'] = language;
  }

  return ret;
}

function sendRequest(path, headers) {
  return get({
    host: process.env.REACT_APP_CMS_HOST,
    path,
    headers,
    json: true,
    cache: true,
    cacheMaxAge: BILLBOARD_CACHE_MAX_AGE,
    proxy: process.env.REACT_APP_GENERAL_PROXY_SERVER,
  });
}

/**
 * @function
 * @memberof CMS_Billboard
 * @description loads the billboard events onto UI
 */
function loadBillboardInfo(props) {
  return (dispatch, getState) => {
    const state = getState();

    return sendRequest(getBillboardEventsUrl(), getRequestHeaders(state)).then(
      (body) => {
        const billboard = body && body.results;
        const numFound = billboard && billboard.length;
        let billboardEvent;
        let largeImgUrl;
        let smallImgUrl;

        /*
         * The list of billboards returned is ordered on the server side. Right now, the
         * requirement is to order them by published time (most recent first).
         */
        if (numFound > 0) {
          /*
           * Go through the list of billboard configurations, and find one that
           * has date range including today.
           */
          billboardEvent = processData(props, billboard);
          const {image, title, redirectUrl} = billboardEvent;
          if (image) {
            ({smallImgUrl, largeImgUrl} = image);

            dispatch({
              type: CMS_BILLBOARD_INFO_LOADED,
              title,
              redirectUrl,
              smallImgUrl,
              largeImgUrl,
              loadDataFailed: false,
            });

            return;
          }
        }
        // No billboard to show
        dispatch({type: CMS_BILLBOARD_INFO_LOAD_FAILED, loadDataFailed: true});

        return null;
      },
      () => {
        dispatch({type: CMS_BILLBOARD_INFO_LOAD_FAILED, loadDataFailed: true});
      },
    );
  };
}

/**
 * @function
 * @memberof CMS_Billboard
 * @description Process the events and get the matching one as per domain and url params.
 */
function processData(props, events) {
  const output = {};
  let item = {};
  let hasMatchedStoreId;
  let hasMatchedCategoryId;
  let storeIdArr;
  let catalogData;
  const currentDate = Date.now();
  let startDate;
  let endDate;
  const numFound = events.length;

  for (let i = 0; i < numFound; ++i) {
    let categoryId = '';
    hasMatchedStoreId = false;
    hasMatchedCategoryId = false;
    item = events[i];
    storeIdArr = item.store_data || /* istanbul ignore next */ [];
    catalogData = item.category_ids || [];

    // Traverse storeId array coming from cms
    hasMatchedStoreId = storeIdArr.some((storeItem) => {
      return STORE_ID[storeItem.store.toUpperCase()] === props.shstoreId;
    });

    if (hasMatchedStoreId) {
      startDate = new Date(`${item.start_date} UTC`);
      endDate = new Date(`${item.end_date} UTC`);

      if (isWithinInterval(currentDate, {start: startDate, end: endDate})) {
        // Traverse categoryId array coming from cms
        hasMatchedCategoryId = catalogData.some((category) => {
          categoryId = category.id;

          return categoryId === props.categoryId;
        });

        // Match categoryId coming via queryparam with cms data
        if (hasMatchedCategoryId || (categoryId === '' && props.categoryId === '')) {
          // Generate the output
          output.startDate = item.start_date;
          output.endDate = item.end_date;
          output.redirectUrl = item.redirect_url;
          output.title = item.title;
          output.image = {
            smallImgUrl: item.small_image_url,
            largeImgUrl: item.large_image_url,
          };

          return output;
        }
      }
    }
  }

  return output;
}

export default controller;
