import {createSelector} from 'reselect';

import {Controller} from '@stubhub/react-store-provider';

const NAMESPACE = 'emailCapture';

const deviceSelector = (state) => state.device;
const deviceTypeSelector = createSelector(deviceSelector, (device = {}) => device.type);

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const userState = state.user || {};
    const userLocation = userState.location;

    return {
      shstoreId: state.gsConfig.shstoreId,
      point: userLocation && /* istanbul ignore next*/ `${userLocation.name}`,
      devicetype: deviceTypeSelector(state),
    };
  },
});

export default controller;
