import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import prefix from '../../../utils/stylesPrefix';

import './CardSubtitle.scss';

const CardSubtitle = ({className, tag, children, ...props}) => {
  const Wrapper = tag || 'h3';

  return (
    <Wrapper className={cs(`${prefix}-card__subtitle`, className)} {...props}>
      {children}
    </Wrapper>
  );
};

CardSubtitle.displayName = 'CardSubtitle';
CardSubtitle.propTypes = {
  /**
   * The CSS class names.
   */
  className: PropTypes.string,

  /**
   * The tag element to use for the title. h3 by default
   */
  tag: PropTypes.string,

  /**
   * The child nodes
   */
  children: PropTypes.node,
};

export default CardSubtitle;
