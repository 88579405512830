import {stringify} from 'qs';

import {Controller} from '@stubhub/react-store-provider';
import {get} from '@stubhub/rest-method';

const NAMESPACE = 'RECENT_VIEWED';

const ACTION_TYPES = {
  CAROUSEL_EVENTS_LOADED: 'RECENT_VIEWED_EVENTS_LOADED',
};

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const localState = this.getLocalState(state);
    /* istanbul ignore next */
    const gsConfig = state.gsConfig || {};

    return {
      shstoreId: gsConfig.shstoreId, // Get shstoreId from gsConfig
      /*
       * NOTE: for jest tests, unable to get any events from recentlyViewedState
       * perhaps, it is set up incorrectly
       */
      /* istanbul ignore next */
      events: localState.events || state.events || [],
    };
  },
  actionCreators: {
    loadEvents,
  },
  reducers: [ACTION_TYPES.CAROUSEL_EVENTS_LOADED],
});

function getEventsAPIUrl(props = {}, shstoreId = 1) {
  const params = {
    limit: 18,
    shstoreId,
    handleName: 'recently_viewed',
  };

  const {cookies} = props;
  if (cookies) {
    params.visitorId = cookies.get('SH_VI');
    params.userGuid = cookies.get('track_session_userGUID');
  }

  return `/recommendations/core/v2/events?${stringify(params)}`;
}

function getRequestHeaders(state) {
  const language = state.lang;
  const ret = {
    Authorization: process.env.REACT_APP_API_SECRET,
  };
  /* istanbul ignore if */
  if (language) {
    ret['Accept-Language'] = language;
  }

  return ret;
}

function sendRequest(state, path, headers) {
  return get({path, headers, json: true, host: process.env.REACT_APP_API_HOST}); // Not set `host` to access the api from same domain
}

function loadEvents(props) {
  return (dispatch, getState) => {
    const state = getState();
    const shstoreId = state && state.gsConfig && state.gsConfig.shstoreId;

    return sendRequest(state, getEventsAPIUrl(props, shstoreId), getRequestHeaders(state)).then((body) => {
      let {events} = body;
      if (!events) {
        events = [];
      }
      dispatch({type: ACTION_TYPES.CAROUSEL_EVENTS_LOADED, events});

      return body;
    });
  };
}

export default controller;
