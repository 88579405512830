import {stringify} from 'qs';
import {createSelector} from 'reselect';

import {selectors as dateFilterSelectors, keys} from '@stubhub/react-date-filter';
import {parseHomeRecoAPIPartnerConfig} from '@stubhub/react-partner-helper';
import {Controller} from '@stubhub/react-store-provider';
import {get} from '@stubhub/rest-method';

import {
  hideCbtEventsSelector,
  isCBTModuleEnabledSelector,
  showPopularInCountrySelector,
} from '../../../store/gsConfig/FF/selectors';
import {SEARCH_CACHE_MAX_AGE} from '../../../utils/constants';
import DateTimeFormat from '../../../utils/datetimeformat';
import {getGeoId} from '../helper';

function destroy() {
  return (dispatch) => {
    dispatch({type: 'HOME_MAIN_RESET'});
  };
}

const MAX_PERFORMER_CARDS = 12;

function getParams(props, reset) {
  const {eventStartDate, eventEndDate, cookies, dateKey} = props;
  const newProps = {...props};
  newProps.eventStartDate = DateTimeFormat.toAPIString(eventStartDate);
  const endDate = eventEndDate ? new Date(eventEndDate.getTime()) : null;
  if (dateKey === keys.K_CHOOSE && endDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  newProps.eventEndDate = DateTimeFormat.toAPIString(endDate);
  if (reset) {
    newProps.offset = 0;
    newProps.offsetPopularEvents = 0;
  }
  if (cookies) {
    newProps.visitorId = cookies.get('SH_VI');
    newProps.userGuid = cookies.get('track_session_userGUID');
  }

  newProps.limit = MAX_PERFORMER_CARDS;

  return newProps;
}

/**
 * Generates a recommendation URL based on the provided properties and parameters.
 *
 * @param {Object} props - The properties used to generate the URL.
 * @param {string} props.point - The geographical point for the recommendation.
 * @param {number} [props.radius=50] - The radius for the recommendation search.
 * @param {number} props.categoryId - The category ID for the recommendation.
 * @param {string} [props.handleName='unified_homepage'] - The handle name for the recommendation.
 * @param {number} [props.minAvailableTickets=1] - The minimum number of available tickets.
 * @param {number} [props.shstore=1] - The store identifier.
 * @param {boolean} reset - Flag to reset the parameters.
 * @param {boolean} hideCbtEvents - Flag to hide CBT events.
 * @param {boolean} showPopularInCountry - Flag to show popular events in the country.
 * @returns {string} The generated recommendation URL.
 */
function getRecoUrl(props, reset, hideCbtEvents, showPopularInCountry) {
  const {point, radius = 50, categoryId, handleName = 'unified_homepage', minAvailableTickets = 1, shstore = 1} = props;
  const {offset, offsetPopularEvents, limit, eventStartDate, eventEndDate, visitorId, userGuid} = getParams(
    props,
    reset
  );
  const geoId = getGeoId(hideCbtEvents, showPopularInCountry, shstore);
  const opts = {
    startIndex: showPopularInCountry ? offsetPopularEvents : offset,
    point: showPopularInCountry ? undefined : point,
    radius: showPopularInCountry ? undefined : radius,
    handleName,
    minAvailableTickets,
    limit,
    shstore,
    categoryId,
    eventStartDate,
    eventEndDate,
    visitorId,
    userGuid,
    geoExpansion: hideCbtEvents ? false : !!showPopularInCountry,
    geoId,
  };

  return `/recommendations/core/v2/eventgroupings?${stringify(opts)}`;
}

function getSearchCatalogUrl(props, reset, hideCbtEvents, showPopularInCountry) {
  const {
    // Point,
    categoryId,
    fieldList = 'id,name,url,imageUrl,images,dateLocal,dateUTC,ticketInfo,displayAttributes,venue',
    minAvailableTickets = 1,
    shstore = 1,
    sort = 'popularity desc',
    profileName = 'reco',
    hidden = false,
    radius = 200,
    status = 'active |contingent',
    units = 'mi',
    eventRows = 3,
    groupType = 'P',
    parking = false,
    mirror = false,
    dateExpansion = true,
  } = props;

  const {offset, offsetPopularEvents, limit, eventStartDate, eventEndDate, visitorId, userGuid, point} = getParams(
    props,
    reset
  );
  const geoId = getGeoId(hideCbtEvents, showPopularInCountry, shstore);

  const opts = {
    start: showPopularInCountry ? offsetPopularEvents : offset,
    point: showPopularInCountry ? undefined : point,
    radius: showPopularInCountry ? undefined : radius,
    categoryId,
    fieldList,
    minAvailableTickets,
    eventRows,
    rows: limit,
    shstore,
    sort,
    profileName,
    hidden,
    status,
    units,
    groupType,
    parking,
    mirror,
    dateExpansion,
    geoExpansion: hideCbtEvents ? false : !!showPopularInCountry,
    geoId,
    eventStartDate,
    eventEndDate,
    visitorId,
    userGuid,
  };

  return `/search/catalog/events/v3/eventGroupings?${stringify(opts)}`;
}

/**
 * Fetches events based on the provided properties and dispatches the results.
 *
 * @param {Object} props - The properties for fetching events.
 * @param {boolean} [reset] - Flag to determine if the fetch should reset.
 * @param {string} type - The type of the action to dispatch.
 * @param {boolean} showPopularInCountry - Flag to show popular events in the country.
 * @param {Function} dispatchFn - The function to dispatch the results.
 * @returns {Function} A thunk function that performs the fetch and dispatches the results.
 */
function fetchEvents(props = {}, reset, type, showPopularInCountry, dispatchFn) {
  const cache = true;
  const cacheMaxAge = SEARCH_CACHE_MAX_AGE;
  if (!reset && props.pid !== props.categoryId) {
    reset = true;
  }

  return (dispatch, getState) => {
    dispatch({type, reset});
    const hideCbtEvents = hideCbtEventsSelector(getState());

    return get({
      host: process.env.REACT_APP_API_HOST,
      path: getRecoUrl(props, reset, hideCbtEvents, showPopularInCountry),
      headers: _getFetchRequestHeaders(getState().lang),
      json: true,
      cache,
      cacheMaxAge,
    }).then(
      (body) => {
        dispatchFn(dispatch, props, reset, body);

        return body;
      },
      () => {
        // eslint-disable-next-line promise/no-nesting
        return get({
          host: process.env.REACT_APP_API_HOST,
          path: getSearchCatalogUrl(props, reset, hideCbtEvents, showPopularInCountry),
          headers: _getFetchRequestHeaders(getState().lang),
          json: true,
          cache,
          cacheMaxAge,
          // eslint-disable-next-line promise/no-nesting
        }).then((body) => {
          dispatchFn(dispatch, props, reset, body);

          return body;
        });
      }
    );
  };
}

/**
 * Fetches recommended events based on provided properties and reset flag.
 *
 * @param {Object} [props={}] - The properties to use for fetching events.
 * @param {boolean} [reset] - Flag to determine if the cache should be reset.
 * @returns {Function} A thunk function that dispatches actions and fetches events.
 */
function fetchRecoEvents(props = {}, reset) {
  const type = 'HOME_MAIN_EVENTS_LOADING';
  const dispatchFn = dispatchEventLoaded;

  return fetchEvents(props, reset, type, false, dispatchFn);
}

/**
 * Fetches popular events based on the provided properties and reset flag.
 *
 * @param {Object} [props={}] - The properties to use for fetching popular events.
 * @param {boolean} [reset] - Flag to determine if the cache should be reset.
 * @returns {Function} A thunk that dispatches actions to load popular events.
 */
function fetchPopularEvents(props = {}, reset) {
  const type = 'HOME_MAIN_POPULAR_EVENTS_LOADING';
  const dispatchFn = dispatchPopularEventsLoaded;

  return fetchEvents(props, reset, type, true, dispatchFn);
}

function dispatchEventLoaded(dispatch, props, reset, body) {
  let {groups = []} = body;
  const {totalCount = 0, impressionToken} = body;
  let offset = reset ? 0 : props.offset || 0;
  offset += groups.length;
  const hasMore = offset < totalCount;
  const loading = false;
  let eventData = reset ? [] : props.eventData || [];
  groups = parseHomeRecoAPIPartnerConfig(groups);

  /**
   * Append the Event Data
   *
   */
  eventData = eventData.concat(groups);
  dispatch({
    type: 'HOME_MAIN_EVENTS_LOADED',
    offset,
    hasMore,
    loading,
    eventData,
    recoToken: impressionToken,
    pid: props.categoryId,
  });
}

/**
 * Dispatches an action to update the state with popular events data.
 *
 * @param {Function} dispatch - The dispatch function to send actions to the store.
 * @param {Object} props - The properties object containing current state and additional data.
 * @param {boolean} reset - Flag indicating whether to reset the current events data.
 * @param {Object} body - The response body containing the events data.
 * @param {Array} body.groups - The array of event groups.
 * @param {number} [body.totalCount=0] - The total count of events.
 * @param {string} body.impressionToken - The impression token for the events.
 */
function dispatchPopularEventsLoaded(dispatch, props, reset, body) {
  let {groups = []} = body;
  const {totalCount = 0, impressionToken} = body;
  let offset = reset ? 0 : props.offsetPopularEvents || 0;
  offset += groups.length;
  const hasMore = offset < totalCount;
  const loading = false;
  let popularEventsData = reset ? [] : props.popularEventsData || [];

  groups = parseHomeRecoAPIPartnerConfig(groups);
  popularEventsData = popularEventsData.concat(groups);

  dispatch({
    type: 'HOME_MAIN_POPULAR_EVENTS_LOADED',
    offsetPopularEvents: offset,
    hasMorePopularEvents: hasMore,
    loadingPopularEvents: loading,
    popularEventsData,
    recoTokenPopularEvents: impressionToken,
    pidPopularEvents: props.categoryId,
  });
}

function _getFetchRequestHeaders(language) {
  const ret = {
    Authorization: process.env.REACT_APP_API_SECRET,
  };
  if (language) {
    ret['Accept-Language'] = language;
  }

  return ret;
}

const userStateSelector = (state) => state.user;
const userLocationSelector = createSelector(userStateSelector, (user) => (user ? user.location : null));
const pointSelector = createSelector(userLocationSelector, (location) =>
  location ? `${location.latitude},${location.longitude}` : null
);
const eventDataSelector = createSelector(
  (homeState) => homeState.eventData,
  (data) => data || []
);

const popularEventsDataSelector = createSelector(
  (homeState) => homeState.popularEventsData,
  (data) => data || []
);

const controller = new Controller({
  namespace: 'app.home.main',
  mapStateToProps(state) {
    const homeState = this.getLocalState(state);

    return {
      pid: homeState.pid,
      eventData: eventDataSelector(homeState),
      popularEventsData: popularEventsDataSelector(homeState),
      offset: homeState.offset || 0,
      offsetPopularEvents: homeState.offsetPopularEvents || 0,
      loading: homeState.loading,
      loadingPopularEvents: homeState.loadingPopularEvents,
      hasMore: homeState.hasMore !== false, // Default true
      hasMorePopularEvents: homeState.hasMorePopularEvents,
      point: pointSelector(state),
      userLocation: userLocationSelector(state),
      eventStartDate: dateFilterSelectors.startDate(state),
      eventEndDate: dateFilterSelectors.endDate(state),
      dateKey: dateFilterSelectors.key(state),
      shstore: state.gsConfig.shstoreId,
      isCBTModuleEnabled: isCBTModuleEnabledSelector(state),
      showPopularInCountry: showPopularInCountrySelector(state),
    };
  },
  actionCreators: {
    fetchEvents: fetchRecoEvents,
    fetchPopularEvents,
    destroy,
  },
});

controller.addReducer(
  'HOME_MAIN_EVENTS_LOADING',
  function (state, {reset}) {
    // Make sure the NAMESPACE is used otherwise value will be written into root which could be a wrong place
    const s = {loading: true};
    if (reset) {
      s.offset = 0;
      s.hasMore = false;
      s.eventData = [];
    }

    return this.setLocalState(state, s);
  }.bind(controller)
);

controller.addReducer(
  'HOME_MAIN_POPULAR_EVENTS_LOADING',
  function (state, {reset}) {
    // Make sure the NAMESPACE is used otherwise value will be written into root which could be a wrong place
    const s = {loadingPopularEvents: true};
    if (reset) {
      s.offsetPopularEvents = 0;
      s.hasMorePopularEvents = false;
      s.popularEventsData = [];
    }

    return this.setLocalState(state, s);
  }.bind(controller)
);

controller.addReducer('HOME_MAIN_EVENTS_LOADED');
controller.addReducer('HOME_MAIN_POPULAR_EVENTS_LOADED');
controller.addResetReducer(['USER_LOCATION_CHANGE', 'USER_DATE_FILTER_CHANGED', 'HOME_MAIN_RESET']);

export default controller;
