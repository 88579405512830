import {Controller} from '@stubhub/react-store-provider';
import {parseUrl} from '@stubhub/sh-url-utils';

import {get} from '../../utils/api';
import {CMS_CACHE_MAX_AGE} from '../../utils/constants';

const CMX_API_HOST = process.env.REACT_APP_CMX_API_HOST;

function loadSeoBottomContent(seoBottomContentUrl) {
  return (dispatch, getState, {logger}) => {
    const state = getState();
    const {lang} = state;

    if (/\/seo\//.test(seoBottomContentUrl)) {
      /*
       * If url contains `/seo/`, this is a absolute url for drupal(CMS system)
       * and we want to keep it as what it is
       * This is a migration for move seo bottom content from cmx to drupal
       */
    } else if (typeof window === 'undefined') {
      /**
       * Use the `CMX_API_HOST` for Only for Server Side Rendering
       *
       */
      /* istanbul ignore next */
      const {pathname, search} = parseUrl(seoBottomContentUrl);
      if (pathname && search) {
        const replacedPathname = pathname.replace('cms', 'cmx');
        seoBottomContentUrl = `${CMX_API_HOST}${replacedPathname}${search}`;
      }
    }

    return get({
      path: seoBottomContentUrl,
      auth: false,
      headers: {'Accept-Language': lang},
      cache: true,
      cacheMaxAge: CMS_CACHE_MAX_AGE,
      proxy: process.env.REACT_APP_GENERAL_PROXY_SERVER,
    })
      .then((seoBottomContent) => {
        dispatch({type: 'SEO_CONTENT_LOADED', seoBottomContent});

        return null;
      })
      .catch((e) => {
        dispatch({type: 'SEO_CONTENT_LOADED', seoBottomContent: ''});
        /* istanbul ignore next */
        logger && logger.error(e);
      });
  };
}

function resetSeoBottomContent() {
  return (dispatch) => {
    dispatch({type: 'SEO_CONTENT_RESET'});
  };
}

const controller = new Controller({
  namespace: 'seo',

  reducers: ['SEO_CONTENT_LOADED'],

  actionCreators: {
    loadSeoBottomContent,
    resetSeoBottomContent,
  },

  mapStateToProps(state) {
    const localState = this.getLocalState(state);

    return {
      seoBottomContent: localState.seoBottomContent,
    };
  },
});

controller.addResetReducer('SEO_CONTENT_RESET');

export default controller;
