import React from 'react';
import PropTypes from 'prop-types';

import {SCHEMA_ORG_PREFIX, SCHEMA_TYPE_ADDRESS, SCHEMA_TYPE_COUNTRY} from '../../helpers/jsonld-helper';

const EventMicroData = ({eventJSONLD}) => {
  return (
    <>
      <meta itemProp="name" content={eventJSONLD.name} />
      <meta itemProp="description" content={eventJSONLD.description} />
      <meta itemProp="startDate" content={eventJSONLD.startDate} />
      <meta itemProp="eventAttendanceMode" content={eventJSONLD.eventAttendanceMode} />
      <meta itemProp="doorTime" content={eventJSONLD.doorTime} />
      <meta itemProp="image" content={eventJSONLD.image} />
      <meta itemProp="url" content={eventJSONLD.url} />
      {eventJSONLD.location && (
        <div itemProp="location" itemScope itemType={`${SCHEMA_ORG_PREFIX}${eventJSONLD.location['@type']}`}>
          <meta itemProp="sameAs" content={eventJSONLD.location.sameAs} />
          <meta itemProp="name" content={eventJSONLD.location.name} />
          {eventJSONLD.location.address && (
            <div itemProp="address" itemScope itemType={`${SCHEMA_ORG_PREFIX}${SCHEMA_TYPE_ADDRESS}`}>
              <meta itemProp="streetAddress" content={eventJSONLD.location.address.streetAddress} />
              <meta itemProp="postalCode" content={eventJSONLD.location.address.postalCode} />
              <meta itemProp="addressLocality" content={eventJSONLD.location.address.addressLocality} />
              <meta itemProp="addressRegion" content={eventJSONLD.location.address.addressRegion} />
              <div itemProp="addressCountry" itemScope itemType={`${SCHEMA_ORG_PREFIX}${SCHEMA_TYPE_COUNTRY}`}>
                <meta itemProp="name" content={eventJSONLD.location.address.addressCountry} />
              </div>
            </div>
          )}
        </div>
      )}
      {eventJSONLD.performer &&
        eventJSONLD.performer.map((item, index) => (
          <div key={index} itemProp="performer" itemScope itemType={`${SCHEMA_ORG_PREFIX}${item['@type']}`}>
            <meta itemProp="name" content={item.name} />
            <meta itemProp="sameAs" content={item.sameAs} />
          </div>
        ))}
      {eventJSONLD.offers && (
        <div itemProp="offers" itemScope itemType={`${SCHEMA_ORG_PREFIX}${eventJSONLD.offers['@type']}`}>
          <meta itemProp="url" content={eventJSONLD.offers.url} />
          <meta itemProp="name" content={eventJSONLD.offers.name} />
          <meta itemProp="category" content={eventJSONLD.offers.category} />
          <link itemProp="availability" href={eventJSONLD.offers.availability} />
          <meta itemProp="lowPrice" content={eventJSONLD.offers.lowPrice} />
          <meta itemProp="highPrice" content={eventJSONLD.offers.highPrice} />
          <meta itemProp="priceCurrency" content={eventJSONLD.offers.priceCurrency} />
          <meta itemProp="validFrom" content={eventJSONLD.offers.validFrom} />
          <meta itemProp="validThrough" content={eventJSONLD.offers.validThrough} />
          {eventJSONLD.offers.inventoryLevel && (
            <div
              itemProp="inventoryLevel"
              itemScope
              itemType={`${SCHEMA_ORG_PREFIX}${eventJSONLD.offers.inventoryLevel['@type']}`}
            >
              <meta itemProp="name" content={eventJSONLD.offers.inventoryLevel.name} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

EventMicroData.propTypes = {
  /** JsonLD information */
  eventJSONLD: PropTypes.object,
};

export default EventMicroData;
