import Loadable from 'react-loadable';

/*
 * Here we are using dynamic imports for heroHeader because we only require to load react-jumbotron-banner
 * module for category pages
 */
const JumbotronBanner = Loadable({
  loader: () => import(/* webpackChunkName: "swi-JumbotronBanner" */ '../react-jumbotron-banner'),
  loading: () => null,
});

export default JumbotronBanner;
