import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import prefix from '../../utils/stylesPrefix';
import {ClickableTile, Tile} from '../react-tile';

import CardContent from './CardContent/CardContent';
import CardImage from './CardImage/CardImage';
import CardSubtitle from './CardSubtitle/CardSubtitle';
import CardTitle from './CardTitle/CardTitle';

import './index.scss';

const Card = ({
  href,
  isInternal,
  onClick,
  onKeyDown,
  className,
  image,
  attribution,
  title,
  subtitle,
  content,
  children,
  ...props
}) => {
  const isClickable = href || onClick || onKeyDown;
  const Wrapper = isClickable ? ClickableTile : Tile;
  const classes = cs(`${prefix}-card`, className);
  const wrapperProps = {
    className: classes,
    href,
    onClick,
    onKeyDown,
    ...(isClickable ? {isInternal} : {}),
    ...props,
  };

  return (
    <Wrapper {...wrapperProps}>
      {image || null}
      <div className={`${prefix}-card__content-container`}>
        {title || null}
        {subtitle || null}
        {content || null}
        {children}
      </div>
    </Wrapper>
  );
};

Card.displayName = 'Card';
Card.propTypes = {
  /**
   * The CardImage component
   */
  image: PropTypes.shape({type: PropTypes.oneOf([CardImage])}),

  /** The CardTitle component */
  title: PropTypes.shape({type: PropTypes.oneOf([CardTitle])}),

  /** The CardSubtitle component */
  subtitle: PropTypes.shape({type: PropTypes.oneOf([CardSubtitle])}),

  /** The CardContent component */
  content: PropTypes.shape({type: PropTypes.oneOf([CardContent])}),

  /** Href attribute if the card is clickable */
  href: PropTypes.string,

  /** Is the link to an internal app page? */
  isInternal: PropTypes.bool,

  /** Child node to add to the card if the main components don't fulfill the needs */
  children: PropTypes.node,

  /**
   * Click function. Will convert the card to a clickable one
   */
  onClick: PropTypes.func,

  /**
   * OnKeyDown function. Will convert the card to a clickable one
   */
  onKeyDown: PropTypes.func,

  /**
   * The Class Name used to override the Styling for the component.
   */
  className: PropTypes.string,
  attribution: PropTypes.string,
};

export default Card;
export {CardTitle, CardSubtitle, CardContent, CardImage};
