import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {FormattedMessage} from 'react-intl';

import {Button} from '@stubhub/bamboo';
import {getRedirectURL} from '@stubhub/react-cbt-redirection-modal';
import {helper} from '@stubhub/react-cloudinary';

import {DEFAULT_IMAGE_CONFIG} from '../../utils/cloudinaryConfigs';

import Avatar from './components/react-avatar';
import {ALLOWED_SEARCH_PARAMS, TRACK_FEAT_NAME} from './constants';
import messages from './messages';
import {isAvatarElegible} from './utils';

import './index.scss';

const CBTModule = ({entityType, entityName, webUri, src, publicIdSrc, keepSearchQuery, className}) => {
  const [redirectURLWithTracking, setRedirectURLWithTracking] = useState(() =>
    getRedirectURL(webUri, entityType, keepSearchQuery, ALLOWED_SEARCH_PARAMS, TRACK_FEAT_NAME)
  );
  const entityImageOptions = {
    publicIdSrc,
    ...DEFAULT_IMAGE_CONFIG,
  };
  const entityImageUrl = helper.getCloudinaryImage(entityImageOptions);
  const showAvatar = isAvatarElegible(entityType, src, entityImageUrl);

  const handleClick = () => {
    setRedirectURLWithTracking(
      getRedirectURL(webUri, entityType, keepSearchQuery, ALLOWED_SEARCH_PARAMS, TRACK_FEAT_NAME)
    );
  };

  return (
    <div className={cx('CBTModule__container', className)}>
      <div className="CBTModule__image">
        {showAvatar && (
          <Avatar className="CBTModule__avatar" image={entityImageUrl} src={src} alt={entityName || 'Avatar'} />
        )}
      </div>
      <div className="CBTModule__content">
        <h1 className="CBTModule__title">
          <FormattedMessage {...messages.CBT_MODULE_TITLE} />
        </h1>
        <p role="description" className="CBTModule__description">
          <FormattedMessage {...messages.CBT_MODULE_DESCRIPTION} />
        </p>
        <Button className="CBTModule__button" kind="primary" href={redirectURLWithTracking} onClick={handleClick}>
          <FormattedMessage {...messages.CBT_MODULE_BUTTON_TEXT} />
        </Button>
      </div>
    </div>
  );
};

CBTModule.propTypes = {
  /**
   * The Entity that is being shown (performer, category, home, search...)
   */
  entityType: PropTypes.string,

  /**
   * Name used for avatar alternative text
   */
  entityName: PropTypes.string,

  /** Uri of the entity page */
  webUri: PropTypes.string,

  /**
   * Image URl
   */
  src: PropTypes.string,

  /**
   * Cloudinary ID
   */
  publicIdSrc: PropTypes.string,

  /**
   * If redirect should keep URL search params
   */
  keepSearchQuery: PropTypes.bool,

  /**
   * External className
   */
  className: PropTypes.string,
};

export default CBTModule;
