/* istanbul ignore file */
import chunk from 'lodash/chunk';
import {stringify} from 'qs';
import {createSelector} from 'reselect';

import {selectors as dateFilterSelectors, keys} from '@stubhub/react-date-filter';
import {Controller} from '@stubhub/react-store-provider';
import {get} from '@stubhub/rest-method';

import DateTimeFormat from '../../utils/datetimeformat';

const userStateSelector = (state = {}) => state.user;
const userLocationSelector = createSelector(userStateSelector, (user) => (user ? user.location : null));
const deviceSelector = createSelector(
  (state) => state.device,
  (device = {}) => device.type
);
const pointSelector = createSelector(userLocationSelector, (location) =>
  location ? `${location.latitude},${location.longitude}` : null
);

/**
 * Selects a geo name from the state.
 * @param {Object} state A state containing a user namespace with location.
 * @return {string?} A name of geo location.
 */
const geoNameSelector = createSelector(userLocationSelector, (location = {}) => (location ? location.name : null));

const DEFAULT_CACHE_MAX_AGE = 300; // 5 min
const CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || DEFAULT_CACHE_MAX_AGE;
const DEFAULT_RADIUS = 50;
// API WIKI: https://wiki.stubcorp.com/pages/viewpage.action?pageId=21008284

function controllerFactory(namespace) {
  const instanceNamespace = `GenreModule.loadData:${namespace}`;
  const namespaceCSS = `genre-module-${namespace}`;

  const controller = new Controller({
    namespace: instanceNamespace,

    mapStateToProps(state) {
      const localState = this.getLocalState(state);

      return {
        eventKeyDate: dateFilterSelectors.key(state),
        eventEndDate: dateFilterSelectors.endDate(state),
        eventStartDate: dateFilterSelectors.startDate(state),
        fetchCount: localState.fetchCount || 0,
        geoName: geoNameSelector(state),
        impressionToken: localState.impressionToken,
        isDesktop: deviceSelector(state) === 'desktop',
        isPhone: deviceSelector(state) === 'phone',
        isTablet: deviceSelector(state) === 'tablet',
        loading: localState.loading,
        namespace: localState.namespace,
        namespaceCSS,
        performers: localState.performers || [],
        point: pointSelector(state),
        reset: localState.reset,
        rowCount: localState.rowCount,
        selectedCategory: localState.selectedCategory || '',
        start: localState.start,
        totalCount: localState.totalCount || 0,
      };
    },

    reducers: [
      `${instanceNamespace}:LOADED`,
      `${instanceNamespace}:LOADING`,
      'USER_LOCATION_CHANGE',
      'USER_DATE_FILTER_CHANGED',
    ],

    actionCreators: {getSearchEntity, openLocationDialog},
  });

  /* istanbul ignore next */
  function getSearchEntity(category, page, reset, props, cookies, lang) {
    return (dispatch) => {
      dispatch({type: `${instanceNamespace}:LOADING`, loading: page === 0 && reset});

      let performers = props.performers || [];
      let start = props.start || 0;
      const rowCount = (props.isDesktop && 5) || 4;
      let count = rowCount;
      let fetchCount = props.fetchCount || 0;
      const radius = props.radius || DEFAULT_RADIUS;

      // Fetch either 15 or 12 initially for tablet and desktop
      if (page === 0 && !props.isPhone) {
        count = rowCount * 3;
      } else {
        count = rowCount;
      }

      // If user selects new subcategory, reset all these guys
      if (reset) {
        start = 0;
        performers = [];
      }

      const opts = {
        sort: 'popularity desc',
        minAvailableTickets: 1,
        shstore: 1,
        radius,
        units: 'mi',
        geoExpansion: false,
        parking: false,
        groupType: 'P',
        ir: true, // Image randomization
        // Point: props.point, // TODO: Add back after COVID or when we can change sort to weight on both radius and popularity
        rows: count,
        start: performers.length * count,
      };

      /*
       * If category includes multiple IDs (eg, "Rock" includes indie, metal, etc) then
       * join all IDs
       */
      const ids = category.ids.join(' |');

      // Pass subcategory appropriately, can either be grouping or category
      if (category.type === 'category') {
        opts.categoryId = ids;
      } else {
        opts.groupingId = ids;
      }

      if (props.eventStartDate && props.eventEndDate) {
        opts.eventStartDate = DateTimeFormat.toAPIString(props.eventStartDate);
        const {eventKeyDate, eventStartDate, eventEndDate} = props;
        opts.eventStartDate = DateTimeFormat.toAPIString(eventStartDate);
        const endDate = new Date(eventEndDate.getTime());
        if (eventKeyDate === keys.K_CHOOSE) {
          endDate.setDate(endDate.getDate() + 1);
        }
        opts.eventEndDate = DateTimeFormat.toAPIString(endDate);
      }

      const headers = {
        Authorization: process.env.REACT_APP_API_SECRET,
      };

      if (lang) {
        headers['Accept-Language'] = lang;
      }

      return get({
        host: process.env.REACT_APP_API_HOST,
        path: `/search/catalog/events/v3/eventGroupings?${stringify(opts)}`,
        json: true,
        cache: true,
        cacheMaxAge: CACHE_MAX_AGE,
        headers,
      }).then((body = {}) => {
        const {totalCount = 0, groups = []} = body;

        chunk(groups, rowCount).forEach((entities, index) => {
          performers.push(
            entities.map((entity, idx) => {
              const cloudinaryPublicId = entity.images && !!entity.images.length && entity.images[0].cloudinaryPublicId;

              return {
                id: entity.id,
                name: entity.name,
                image: entity.images && (entity.images[0].urlSsl || entity.images[0].url),
                cloudinaryPublicId,
                webURI: entity.webURI || entity.url,
                position: index * rowCount + idx,
              };
            })
          );
        });

        fetchCount += 1;

        dispatch({
          type: `${instanceNamespace}:LOADED`,
          performers,
          selectedCategory: category.name,
          loading: false,
          reset,
          start,
          fetchCount,
          totalCount,
          rowCount,
        });

        return null;
      });
    };
  }

  function openLocationDialog() {
    return (dispatch) => {
      dispatch({type: 'USER_LOCATION_OPEN'});

      return {};
    };
  }

  return controller;
}

export default controllerFactory;
