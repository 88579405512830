import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {ControllerContainer, ssr_wait} from '@stubhub/react-store-provider';

import EntityCarousel from '../react-entity-carousel';
import EntitySeries from '../react-entity-series';
import GenreEmptyMessage from '../react-genre-empty';

import ControllerFactory from './controller';
import GenrePerformerCard from './performer';

import './index.scss';

const CARD_NUMBER_MWEB_CAROUSEL = 2.5;
const DEFAULT_TIMEOUT = 500;

/**
 * A component to represent a list of performers within a genre. Initially
 * requests 10 items from an API and consequentialy requests 5 more items upon
 * scrolling towards the end of the list. Uses an EntityCarousel to represent
 * maximum ITEMS_PER_VIEW items at a time.
 * @param {string} childClassName A class name for performers.
 * @param {string} className A class name for component's container.
 * @param {Object} genre A genre for performers.
 * @param {Array} performers A list of performers. This list is retrieved by
 *     the component itself from an API using genre and filters.
 * @extends {React.Component}
 */
export class GenrePerformers extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
    };
    const {isMobileView} = this.props;
    this.view = isMobileView ? {buffer: 0, init: 4, size: 4} : {buffer: 5, init: 10, size: 5};
  }

  /**
   * Requests an initial list of performers from an API. Requires a synchronous
   * wait for server side rendering.
   */
  @ssr_wait
  init() {
    this.getPerformers();
  }

  /**
   * Initiates data retrieval before mounting a component.
   */
  UNSAFE_componentWillMount() {
    const {performers} = this.props;
    if (performers.length === 0) {
      this.init();
    }
  }

  /**
   * Initiates data retrieval if properties are changed.
   * @param {React.Props} nextProps New properties.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isMobileView, eventEndDate, eventStartDate, point, performers} = this.props;

    if (isMobileView !== nextProps.isMobileView) {
      this.view = nextProps.isMobileView ? {buffer: 0, init: 4, size: 4} : {buffer: 5, init: 10, size: 5};
    }
    if (
      eventEndDate !== nextProps.eventEndDate ||
      eventStartDate !== nextProps.eventStartDate ||
      point !== nextProps.point
    ) {
      this.getPerformers(nextProps);
    }
    if (performers !== nextProps.performers) {
      this.setState({isLoading: false});
    }
  }

  /**
   * Requests a controller to retrieve a list of performers.
   * @param {React.Props} props New properties if available.
   */
  getPerformers(props) {
    const {performers, getPerformers} = this.props;
    const reset = !!props;
    const isInitLoad = !performers || !performers.length || reset;
    const count = isInitLoad ? this.view.init : this.view.size;
    if (typeof getPerformers === 'function') {
      this.setState({isLoading: true});
      getPerformers(props || this.props, reset, count);
    }
  }

  /**
   * Requests additional chunk of performers. Adds a delay for desktop view
   * to allow smooth carousel interaction.
   */
  loadMore() {
    const {isMobileView} = this.props;
    if (isMobileView) {
      this.getPerformers();
    } else {
      setTimeout(() => {
        this.getPerformers();
      }, DEFAULT_TIMEOUT);
    }
  }

  /**
   * Requests additional chunk of performers list.
   */
  onLoadMore = () => {
    this.loadMore();
  };

  /**
   * Requests additional chunk of performers list when window's boundaries come close to
   * the end of the list.
   * @param {Object} cursor A position of carousel's sliding view window.
   */
  onNext = (cursor) => {
    const {performers, total} = this.props;
    const viewLimit = cursor + this.view.size + this.view.buffer;
    const limit = Math.min(viewLimit, total);
    if (performers.length < limit) {
      this.loadMore();
    }
  };

  /**
   * Renders a component. Uses class names provided by parent component and
   * displays a carousel with performers.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {
      childClassName,
      className,
      enableCarouselOnMweb,
      eventEndDate,
      eventKeyDate,
      eventStartDate,
      genre,
      geoName,
      isMobileView,
      notFound,
      performers,
      radius = 50,
      reset,
      showSubGenre,
      total,
    } = this.props;
    const {isLoading} = this.state;
    const _ = 'GenrePerformers';
    const isEmpty = notFound ? `${_}--isEmpty` : '';
    const container = cs(className, _, isEmpty);
    const genreView = cs(`${_}__genreView`);

    const limit = total || 5;
    let items = performers || [];
    if (isLoading || !items.length || !isMobileView) {
      for (let i = 0; i < this.view.size && items.length < limit; i++) {
        items = items.concat([null]);
      }
    }

    const EntityView = !enableCarouselOnMweb && isMobileView ? EntitySeries : EntityCarousel;
    let {size} = this.view;
    if (enableCarouselOnMweb && isMobileView) {
      size = CARD_NUMBER_MWEB_CAROUSEL;
    } else if (isMobileView) {
      size = total;
    }

    return (
      <div className={container}>
        <h2>{genre.name}</h2>
        <EntityView className={genreView} size={size} reset={reset} onNext={this.onNext} onLoadMore={this.onLoadMore}>
          {items.map((performer, index) => {
            const key = performer ? performer.id : index;

            return (
              <GenrePerformerCard
                className={childClassName}
                key={key}
                performer={performer}
                isMobileView={!enableCarouselOnMweb && isMobileView}
                index={index}
                showSubGenre={showSubGenre}
              />
            );
          })}
        </EntityView>
        {notFound && (
          <GenreEmptyMessage
            eventKeyDate={eventKeyDate}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            genreName={genre.name}
            geoName={geoName}
            radius={radius}
          />
        )}
      </div>
    );
  }
}

/**
 * Component's properties.
 */
GenrePerformers.propTypes = {
  childClassName: PropTypes.string,

  /**
   * The Class Name used to override the Styling for the component.
   *
   */
  className: PropTypes.string,
  genre: PropTypes.object.isRequired,

  /**
   * Array of performers
   */
  performers: PropTypes.array,

  /**
   * If carousel is available on Mweb
   */
  enableCarouselOnMweb: PropTypes.bool,

  isMobileView: PropTypes.bool,

  /**
   * Start date filter
   */
  eventStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  /**
   * End date filter
   */
  eventEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  point: PropTypes.string,
  getPerformers: PropTypes.func,
  total: PropTypes.number,
  eventKeyDate: PropTypes.string,
  geoName: PropTypes.string,
  notFound: PropTypes.bool,
  reset: PropTypes.func,
  radius: PropTypes.number,
  showSubGenre: PropTypes.bool,
};

/**
 * Default values for properties.
 */
GenrePerformers.defaultProps = {
  childClassName: '',
  className: '',
  genre: {},
  performers: [],
  enableCarouselOnMweb: false,
};

/**
 * A container for a GenrePerformers component which provides a namespace for
 * component's store.
 * @param {Object} genre A genre for performers.
 * @extends {React.Component}
 */
class GenrePerformersContainer extends Component {
  /**
   * Renders a GenrePerformers component and provides a unique namespace name
   * for its store.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {genre} = this.props;
    const namespace = `GenrePerformers:${(genre.ids || []).join(',')}`;

    return (
      <ControllerContainer
        namespace={namespace}
        factory={ControllerFactory}
        component={GenrePerformers}
        {...this.props}
      />
    );
  }
}

/**
 * Component's properties.
 */
GenrePerformersContainer.propTypes = {
  genre: PropTypes.object,
};

/**
 * Default values for properties.
 */
GenrePerformersContainer.defaultProps = {
  genre: {},
};

export default GenrePerformersContainer;
