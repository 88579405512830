import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {CloudinaryImage} from '@stubhub/react-cloudinary';

import './index.scss';

const Avatar = ({className, image, src, alt}) => (
  <div className={cx(className, 'avatar')}>
    {image ? (
      <CloudinaryImage className={cx({cloudinary__image: !!image})} image={image} alt={alt} />
    ) : (
      <img src={src} alt={alt} />
    )}
  </div>
);

Avatar.propTypes = {
  /**
   * Classname assigned from parent component
   */
  className: PropTypes.string,

  /**
   * Object containing the cloudinary image for avatar
   */
  image: PropTypes.object,

  /**
   * Image URL if cloudinary image is not available
   */
  src: PropTypes.string,

  /**
   * Alternative text for image
   */
  alt: PropTypes.string.isRequired,
};
export default Avatar;
