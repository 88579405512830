import React from 'react';
import PropTypes from 'prop-types';

import {CloudinaryImage, helper} from '@stubhub/react-cloudinary';
import {formatURLDomain} from '@stubhub/sh-url-utils';

import {MAIN_PERFORMER_IMAGES_CONFIG} from '../../utils/cloudinaryConfigs';
import Card, {CardTitle, CardImage} from '../react-card';

import './index.scss';

const PerformerCard = ({performer, wrapper, defaultLocale, currentLocale}) => {
  const Wrapper = wrapper || 'div';

  const {id = '', webURI = '', name = '', images = []} = performer || {};

  const link = webURI || `/performer/${id}`;
  const href = formatURLDomain({href: link, defaultLocale, currentLocale});

  let performerCloudinaryImage = null;
  let performerImageAttribution = '';
  let performerImageOptions = {};
  if (images?.length > 0) {
    const [image] = images;
    const cloudinaryPublicId = image.cloudinaryPublicId || '';
    if (cloudinaryPublicId) {
      performerImageOptions = {
        publicIdSrc: cloudinaryPublicId,
        ...MAIN_PERFORMER_IMAGES_CONFIG,
      };
      performerCloudinaryImage = helper.getCloudinaryImage(performerImageOptions);
      performerImageAttribution = image.credit;
    }
  }

  if (performerCloudinaryImage) {
    const performerImage = (
      <CloudinaryImage image={performerCloudinaryImage} alt={name} useLazyLoad lazyLoadThreshold={0.01} />
    );

    return (
      <Wrapper className="performer-card">
        <Card
          image={
            <CardImage
              className="performer-card__image"
              image={performerImage}
              attribution={performerImageAttribution}
              alt={name}
              useSkeleton={false}
            />
          }
          title={
            <CardTitle className="performer-card__title" tag="h3">
              {name}
            </CardTitle>
          }
          href={href}
          isInternal
        />
      </Wrapper>
    );
  }

  return null;
};
PerformerCard.displayName = 'PerfomerCard';

PerformerCard.propTypes = {
  /**
   * The Perfomer Object
   */
  performer: PropTypes.object.isRequired,

  /**
   * The wrapper node type, div by default
   */
  wrapper: PropTypes.node,
  defaultLocale: PropTypes.string,
  currentLocale: PropTypes.string,
};

export default React.memo(PerformerCard);
