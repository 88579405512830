import React, {Component} from 'react';
import PropTypes from 'prop-types';

import FeatureToggle from '@stubhub/react-feature-toggle';
import {connect, ssr_wait} from '@stubhub/react-store-provider';

import CBTModule from '../../../modules/react-cbt-module';
import PerformerCardsGrid from '../../../modules/react-performer-cards-grid';
import SearchNoResultsPanel from '../../../modules/react-search-no-results-panel';
import FEATURE_FLAGS from '../../../utils/featureFlags';

import controller from './controller';

class MainContent extends Component {
  constructor(props, context) {
    super(props, context);
    const {categoryId, showPopularInCountry, eventData, pid, popularEventsData} = this.props;

    if (!eventData || !eventData.length || pid !== categoryId) {
      this.init();
    }
    if (showPopularInCountry && (!popularEventsData || !popularEventsData.length || pid !== categoryId)) {
      this.initPopularEvents();
    }
  }

  @ssr_wait
  init() {
    const {fetchEvents} = this.props;

    return this.fetchData(this.props, fetchEvents);
  }

  @ssr_wait
  initPopularEvents() {
    const {fetchPopularEvents} = this.props;

    return this.fetchData(this.props, fetchPopularEvents);
  }

  componentWillUnmount() {
    const {destroy} = this.props;
    destroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      categoryId,
      eventStartDate,
      dateRangeKey,
      eventStartDateOverride,
      eventEndDate,
      eventEndDateOverride,
      point,
      showPopularInCountry,
    } = this.props;
    if (
      nextProps.categoryId !== categoryId ||
      nextProps.eventStartDate !== eventStartDate ||
      nextProps.eventEndDate !== eventEndDate ||
      nextProps.dateRangeKey !== dateRangeKey ||
      nextProps.eventStartDateOverride !== eventStartDateOverride ||
      nextProps.eventEndDateOverride !== eventEndDateOverride ||
      nextProps.point !== point
    ) {
      this.fetchData(nextProps, nextProps.fetchEvents, true);
      if (showPopularInCountry) {
        this.fetchData(nextProps, nextProps.fetchPopularEvents, true);
      }
    }
  }

  getMore = () => {
    const {fetchEvents} = this.props;

    return this.fetchData(this.props, fetchEvents);
  };

  getMorePopularEvents = () => {
    const {fetchPopularEvents} = this.props;

    return this.fetchData(this.props, fetchPopularEvents);
  };

  fetchData = (props, fetchFunction, reset = false) => {
    const {cookies} = this.context;
    const updatedProps = {
      ...props,
      cookies,
      eventStartDate: props.eventStartDateOverride ? new Date(props.eventStartDateOverride) : props.eventStartDate,
      eventEndDate: props.eventEndDateOverride ? new Date(props.eventEndDateOverride) : props.eventEndDate,
    };

    return fetchFunction(updatedProps, reset);
  };

  render() {
    const {
      eventData,
      popularEventsData,
      hasMorePopularEvents,
      hasMore,
      loading,
      loadingPopularEvents,
      title,
      popularTitle,
      isCBTModuleEnabled,
    } = this.props;

    const noEvents = eventData && eventData.length === 0 && loading === false;

    return (
      <>
        <PerformerCardsGrid
          id="events-near"
          eventData={eventData}
          hasMore={hasMore}
          loading={loading}
          getMore={this.getMore}
          title={title}
        />
        {noEvents &&
          (isCBTModuleEnabled ? (
            <CBTModule entityType="home" className="HomePage__CbtModule" webUri="/" />
          ) : (
            <SearchNoResultsPanel />
          ))}

        <FeatureToggle name={FEATURE_FLAGS.browse.popularInCountry} key="popularEvents">
          <PerformerCardsGrid
            id="events-popular"
            eventData={popularEventsData}
            hasMore={hasMorePopularEvents}
            loading={loadingPopularEvents}
            getMore={this.getMorePopularEvents}
            title={popularTitle}
          />
        </FeatureToggle>
      </>
    );
  }
}

MainContent.propTypes = {
  loading: PropTypes.bool,
  loadingPopularEvents: PropTypes.bool,
  hasMore: PropTypes.bool,
  hasMorePopularEvents: PropTypes.bool,
  title: PropTypes.object,
  popularTitle: PropTypes.object,
  categoryId: PropTypes.string,
  eventStartDateOverride: PropTypes.string, // Externally provided eventStartDate
  eventEndDateOverride: PropTypes.string, // Externally provided eventEndDate
  eventData: PropTypes.array,
  popularEventsData: PropTypes.array,
  fetchEvents: PropTypes.func.isRequired,
  fetchPopularEvents: PropTypes.func.isRequired,
  eventStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateRangeKey: PropTypes.string,
  eventEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  point: PropTypes.string,
  destroy: PropTypes.func.isRequired,
  pid: PropTypes.string,
  showPopularInCountry: PropTypes.bool,

  /** If FF for showing cbt module is enabled */
  isCBTModuleEnabled: PropTypes.bool,
};

MainContent.contextTypes = {
  cookies: PropTypes.object,
  globalRegistry: PropTypes.object,
};

export default connect(controller)(MainContent);
