import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, defineMessages} from 'react-intl';

import UIButton from '../react-uikit-button';

/**
 * This component implements a "Load more" button that's based on the UIButton.
 */

const messages = defineMessages({
  loadMoreButtonText: {
    id: 'LoadMoreButton.text',
    defaultMessage: 'See more',
    description: 'Load more text',
  },
});

const LoadMoreButton = ({onClick, intl}) => {
  const loadMoreButtonText = intl.formatMessage(messages.loadMoreButtonText);

  return <UIButton text={loadMoreButtonText} secondary size="tiny" flow="main" onClick={onClick} />;
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,

  /** The intl object */
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LoadMoreButton);
