import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders an SVG for chevron icon.
 * @param {number} width An icon's width.
 * @param {number} height An icon's height.
 * @param {string} fill An icon's color in HEX format.
 * @return {React.Element} A rendered icon.
 */
const Chevron = ({width = 11, height = 18, fill = '#000000'}) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 11 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1323.000000, -216.000000)">
          <g transform="translate(-265.000000, 115.000000)">
            <g transform="translate(353.000000, 86.000000)">
              <g transform="translate(1215.000000, 0.000000)">
                <path
                  fill={fill}
                  d="M25.2307692,27.2307692 L25.2307692,18 L28,18 L28,27.2307692 L28,30 L16,30 L16,27.2307692 L25.2307692,27.2307692 Z"
                  transform="translate(22.000000, 24.000000) rotate(-45.000000) translate(-22.000000, -24.000000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

/**
 * A collection of icons. Icons are stateless functional components returning
 * an SVG representation of an icon.
 */
const Icons = {
  Chevron,
};

Chevron.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default Icons;
