import React from 'react';
import {FormattedMessage} from 'react-intl';

import {CATEGORY_IDS, GROUPING_IDS} from '../../utils/constants';

export default {
  sports: [
    {
      name: 'Sports',
      ids: [CATEGORY_IDS.SPORTS],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.sports"
          defaultMessage="Sports"
          description="Sports Sub genre option Sports"
        />
      ),
    },
    {
      name: 'NFL',
      ids: [GROUPING_IDS.NFL],
      type: 'grouping',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.nfl"
          defaultMessage="NFL"
          description="Sports Sub genre option NFL"
        />
      ),
    },
    {
      name: 'NCAA Football',
      ids: [GROUPING_IDS.COLLEGUE_FOOTBALL],
      type: 'grouping',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.ncaafootball"
          defaultMessage="NCAA Football"
          description="Sports Sub genre option NCAA Football"
        />
      ),
    },
    {
      name: 'NBA',
      ids: [GROUPING_IDS.NBA],
      type: 'grouping',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.nba"
          defaultMessage="NBA"
          description="Sports Sub genre option NBA"
        />
      ),
    },
    {
      name: 'NHL',
      ids: [GROUPING_IDS.NHL],
      type: 'grouping',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.nhl"
          defaultMessage="NHL"
          description="Sports Sub genre option NHL"
        />
      ),
    },
    {
      name: 'NCAA Basketball',
      ids: [GROUPING_IDS.COLLEGUE_BASKETBALL],
      type: 'grouping',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.ncaabasketball"
          defaultMessage="NCAA Basketball"
          description="Sports Sub genre option NCAA Basketball"
        />
      ),
    },
    {
      name: 'MLB',
      ids: [GROUPING_IDS.MLB],
      type: 'grouping',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.mlb"
          defaultMessage="MLB"
          description="Sports Sub genre option MLB"
        />
      ),
    },
    {
      name: 'Motorsports',
      ids: [CATEGORY_IDS.MOTOR],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.motorsports"
          defaultMessage="Motorsports"
          description="Sports Sub genre option Motorsports"
        />
      ),
    },
    {
      name: 'More',
      ids: [
        CATEGORY_IDS.FOOTBALL,
        CATEGORY_IDS.TENNIS,
        CATEGORY_IDS.WRESTLING,
        CATEGORY_IDS.FIGHT,
        CATEGORY_IDS.HORSE_RACING,
        CATEGORY_IDS.GOLF,
        CATEGORY_IDS.RODEO,
        GROUPING_IDS.NFL,
        GROUPING_IDS.COLLEGUE_FOOTBALL,
      ],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Sports.Subgenre.more"
          defaultMessage="More"
          description="Sports Sub genre option More"
        />
      ),
    },
  ],
  concerts: [
    {
      name: 'Concerts',
      ids: [CATEGORY_IDS.CONCERTS],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.concerts"
          defaultMessage="Concerts"
          description="Concerts Sub genre option Concerts"
        />
      ),
    },
    {
      name: 'Pop',
      ids: [CATEGORY_IDS.POP, CATEGORY_IDS.DANCE_ELECTRONIC],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.pop"
          defaultMessage="Pop"
          description="Concerts Sub genre option Pop"
        />
      ),
    },
    {
      name: 'Rock',
      ids: [
        CATEGORY_IDS.ROCK,
        CATEGORY_IDS.ALTERNATIVE,
        CATEGORY_IDS.HARD_ROCK_METAL,
        CATEGORY_IDS.HARDCORE,
        CATEGORY_IDS.INDIE,
        CATEGORY_IDS.PUNK,
      ],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.rock"
          defaultMessage="Rock"
          description="Concerts Sub genre option Rock"
        />
      ),
    },
    {
      name: 'Country',
      ids: [CATEGORY_IDS.COUNTRY_FOLK],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.Country"
          defaultMessage="Country"
          description="Concerts Sub genre option Country"
        />
      ),
    },
    {
      name: 'Hip-Hop',
      ids: [CATEGORY_IDS.RAP_HIP_HOP],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.hiphop"
          defaultMessage="Hip-Hop"
          description="Concerts Sub genre option Hip-Hop"
        />
      ),
    },
    {
      name: 'R&B',
      ids: [CATEGORY_IDS.RB_SOUL],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.rnb"
          defaultMessage="R&B"
          description="Concerts Sub genre option R&B"
        />
      ),
    },
    {
      name: 'Festivals',
      ids: [CATEGORY_IDS.FESTIVALS],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Concerts.Subgenre.festivals"
          defaultMessage="Festivals"
          description="Concerts Sub genre option Festivals"
        />
      ),
    },
  ],
  theater: [
    {
      name: 'Theater',
      ids: [CATEGORY_IDS.THEATER],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Theater.Subgenre.theater"
          defaultMessage="Theater"
          description="Theater Sub genre option Theater"
        />
      ),
    },
    {
      name: 'Musicals',
      ids: [CATEGORY_IDS.MUSICAL],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Theater.Subgenre.musicals"
          defaultMessage="Musicals"
          description="Theater Sub genre option Musicals"
        />
      ),
    },
    {
      name: 'Plays',
      ids: [CATEGORY_IDS.STAGE_SHOWS_PLAYS],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Theater.Subgenre.plays"
          defaultMessage="Plays"
          description="Theater Sub genre option Plays"
        />
      ),
    },
    {
      name: 'Comedy',
      ids: [CATEGORY_IDS.COMEDY],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Theater.Subgenre.comedy"
          defaultMessage="Comedy"
          description="Theater Sub genre option Comedy"
        />
      ),
    },
    {
      name: 'Family',
      ids: [CATEGORY_IDS.FAMILY],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Theater.Subgenre.family"
          defaultMessage="Family"
          description="Theater Sub genre option Family"
        />
      ),
    },
    {
      name: 'More',
      ids: [CATEGORY_IDS.OPERA_CLASSICAL_MUSIC, CATEGORY_IDS.BALLET_AND_DANCE, CATEGORY_IDS.SPEAKING_TOUR_CONVENTIONS],
      type: 'category',
      displayName: (
        <FormattedMessage
          id="CityPage.Theater.Subgenre.more"
          defaultMessage="More"
          description="Theater Sub genre option More"
        />
      ),
    },
  ],
};
