import {mediaQueries} from '@stubhub/react-responsive-listener';

export function getScreenSize(defaultScreenSize, device) {
  /*
   * NOTE: in some cases such as in production (unlike local), the state doesn't
   *       have the screen size when module is getting rendered (on load) so we need to
   *       determine on our end
   */
  if (defaultScreenSize) {
    return defaultScreenSize;
  }
  // State does not have screen size
  for (const size in mediaQueries) {
    let mediaQueryList = null;
    /* istanbul ignore if */
    if (__CLIENT__ && window.matchMedia) {
      mediaQueryList = window.matchMedia(mediaQueries[size]);
      // Initialize once based on value of 'matches'
      if (mediaQueryList.matches) {
        return size;
      }
    } else {
      /*
       * Should never get here in a browser;
       * on server; we need to look at device type to
       * determine screen size
       */
      /* istanbul ignore next */
      const deviceType = device && device.type ? device.type : null;
      let screenSize = null;
      switch (deviceType) {
        case 'phone':
          screenSize = 'small';
          break;
        case 'tablet':
          screenSize = 'medium';
          break;
        case 'desktop':
        default:
          screenSize = 'large';
          break;
      }

      return screenSize;
    }
  }
}
