import {stringify} from 'qs';

import {selectors as dateFilterSelectors, keys} from '@stubhub/react-date-filter';
import {Controller} from '@stubhub/react-store-provider';
import {get} from '@stubhub/rest-method';

import DateTimeFormat from '../../utils/datetimeformat';

import selectors from './selectors';

const DEFAULT_CACHE_MAX_AGE = 300; // 5 min
const CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || DEFAULT_CACHE_MAX_AGE;

/**
 * A controller factory for GenrePerformers component. Provides controllers
 * with unique namespaces within a global store.
 * @returns {Store.Controller} A controller with a namespace for local store.
 */
function ControllerFactory(namespace) {
  /**
   * Types of events to dispatch reducers.
   */
  const DATA_LOADED = `${namespace}:LOADED`;
  const DATA_LOADING = `${namespace}:LOADING`;
  const DATA_NOT_LOADED = `${namespace}:NOTLOADED`;

  /**
   * A component's controller.
   */
  const controller = new Controller({
    namespace,
    mapStateToProps(state) {
      const localState = this.getLocalState(state);

      return {
        eventKeyDate: dateFilterSelectors.key(state),
        eventEndDate: dateFilterSelectors.endDate(state),
        eventStartDate: dateFilterSelectors.startDate(state),
        geoName: selectors.geoName(state),
        isMobileView: selectors.mobileView(state),
        notFound: selectors.notFound(localState),
        performers: selectors.performers(localState),
        point: selectors.geoPoint(state),
        reset: selectors.reset(localState),
        total: selectors.totalCount(localState),
      };
    },
    actionCreators: {
      getPerformers,
      openLocationDialog,
    },
    reducers: [DATA_LOADED, DATA_LOADING, DATA_NOT_LOADED],
  });
  controller.addResetReducer(['USER_DATE_FILTER_CHANGED', 'USER_LOCATION_CHANGE']);

  /**
   * Retrieves performers from an API.
   * @param {React.Props} props Component's properties.
   * @param {boolean} reset A flag to reset the list of properties or append
   *     new performers to the end of the list.
   * @return {Function} A function to initiate an API call and to return a REST
   *     method promise.
   */
  function getPerformers(props, reset, size) {
    return (dispatch, getState) => {
      dispatch({
        type: DATA_LOADING,
        notFound: false,
        reset,
      });
      const state = getState() || {};
      const {genre = {}, radius = 50} = props;
      const {gsConfig = {}} = state;
      const {shstore = 1} = gsConfig;
      const genreIds = (genre.ids || []).join(' |');
      let performers = (!reset && props.performers) || [];

      const opts = {
        groupType: 'P',
        minAvailableTickets: 1,
        parking: false,
        // Point: props.point, // TODO: Add back after COVID or when we can change sort to weight on both radius and popularity
        radius,
        units: 'mi',
        geoExpansion: false,
        rows: size,
        shstore,
        sort: 'popularity desc',
        start: performers.length,
        ir: true,
      };
      if (genre.type === 'category') {
        opts.categoryId = genreIds;
      } else {
        opts.groupingId = genreIds;
      }
      if (props.eventStartDate && props.eventEndDate) {
        const {eventKeyDate, eventStartDate, eventEndDate} = props;
        opts.eventStartDate = DateTimeFormat.toAPIString(eventStartDate);
        const endDate = new Date(eventEndDate.getTime());
        if (eventKeyDate === keys.K_CHOOSE) {
          endDate.setDate(endDate.getDate() + 1);
        }
        opts.eventEndDate = DateTimeFormat.toAPIString(endDate);
      }

      const headers = {
        Authorization: process.env.REACT_APP_API_SECRET,
      };

      return get({
        host: process.env.REACT_APP_API_HOST,
        path: `/search/catalog/events/v3/eventGroupings?${stringify(opts)}`,
        json: true,
        cache: true,
        cacheMaxAge: CACHE_MAX_AGE,
        headers,
      })
        .then((response = {}) => {
          const {totalCount = 0} = response;
          performers = performers.concat(response.groups || []);
          dispatch({
            type: DATA_LOADED,
            notFound: totalCount === 0,
            performers,
            reset,
            total: totalCount,
          });

          return null;
        })
        .catch(() => {
          dispatch({
            type: DATA_NOT_LOADED,
            reset,
          });
        });
    };
  }

  function openLocationDialog() {
    return (dispatch) => {
      dispatch({type: 'USER_LOCATION_OPEN'});

      return {};
    };
  }

  return controller;
}

export default ControllerFactory;
