import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {injectIntl, defineMessages, FormattedMessage} from 'react-intl';

import {Button} from '@stubhub/bamboo';
import {publish} from '@stubhub/pubsub-utils';
import '@stubhub/react-font-icons';
import FormattedLink from '@stubhub/react-formatted-link';
import {connect} from '@stubhub/react-store-provider';

import {HOME_EVENTS} from '../../../tracking/track-enum';

import controller from './controller';

import '../styles/email-collection.scss';

const LegalLink = (chunks) => (
  <FormattedLink href="/legal/?section=pp" target="_blank">
    {chunks}
  </FormattedLink>
);

@connect(controller)
class Email extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      valid: '',
    };
  }

  handleChange() {
    const email = document.getElementById('email').value;
    const {intl} = this.props;
    const updatedmessages = defineMessages({
      errormessage: {
        id: 'Email.errormessage',
        defaultMessage: 'Please enter a valid email address',
        description: 'errormessage',
      },
      successheading: {
        id: 'Email.successheading',
        defaultMessage: "Boom. You're in. Remember to add us to your safe sender list to stay in the know.",
        description: 'successheading',
      },
    });

    const errormessage = intl.formatMessage(updatedmessages.errormessage);
    const successheading = intl.formatMessage(updatedmessages.successheading);

    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      document.getElementById('email-heading').innerHTML = successheading;
      document.getElementById('email-submit').style.display = 'none';
      document.getElementById('input-container').classList.add('input-container-success');
      document.getElementById('success').style.display = 'block';
      document.getElementById('email').setAttribute('readonly', 'readonly');
      this.setState({valid: 'true'});

      publish(HOME_EVENTS.NEWSLETTER, {email});
    } else {
      document.getElementById('message').innerHTML = errormessage;
      this.setState({valid: 'false'});
    }
  }

  inputFocus() {
    const {valid} = this.state || {};
    document.getElementById('message').innerHTML = '';
    document.getElementById('submit').style.display = 'block';
    document.getElementById('success').style.display = 'none';
    if (valid === 'true') {
      document.getElementById('email').setAttribute('readonly', 'readonly');
      document.getElementById('success').style.display = 'block';
    } else {
      document.getElementById('email').value = '';
    }
  }

  render() {
    const {valid} = this.state;
    const {intl = {}} = this.props;
    const {locale = 'en-US', shStoreId = 1} = intl;
    const extendedContainerClass = shStoreId === 1 && locale !== 'en-US' ? 'extendedContainer' : '';
    let status;
    if (valid === 'true') {
      status = 'valid-email';
    } else if (valid === 'false') {
      status = 'invalid-email';
    } else {
      status = 'default-email';
    }

    const messages = defineMessages({
      placeholder: {
        id: 'Email.placeholder',
        defaultMessage: 'Email address',
        description: 'Email address',
      },
      buttonvalue: {
        id: 'Email.buttonvalue',
        defaultMessage: 'Join the list',
        description: 'buttonvalue',
      },
    });

    const placeholder = intl.formatMessage(messages.placeholder);
    const buttonvalue = intl.formatMessage(messages.buttonvalue);

    return (
      <div className={cs('email-collection-container', extendedContainerClass)}>
        <p id="email-heading">
          <FormattedMessage
            id="Email.heading"
            defaultMessage="Sign-up to receive the latest updates, exclusive offers and more!"
          />
        </p>
        <p id="emailDisclaimer">
          <FormattedMessage
            id="Email.disclaimer"
            defaultMessage="You can unsubscribe at any time using the link in our emails. Read our <link>Privacy Notice</link> for more details."
            values={{
              link: LegalLink,
            }}
          />
        </p>
        <div className="email-wrapper">
          <div className="wrapper">
            <div className="row">
              <div className="input-field col s12">
                <div id="input-container">
                  <div>
                    <input
                      id="email"
                      type="text"
                      name="DEFAULT_EMAIL"
                      className={`email-address ${status}`}
                      placeholder={placeholder}
                      onFocus={this.inputFocus.bind(this)}
                    />
                  </div>
                  <span id="success" />
                </div>
                <p id="message" />
              </div>
            </div>
            <div id="email-submit">
              <Button
                className="submit-button"
                appearance="secondary"
                type="submit"
                onClick={this.handleChange.bind(this)}
              >
                {buttonvalue}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Email.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Email);
