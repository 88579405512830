import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import prefix from '../../../utils/stylesPrefix';

import './CardImage.scss';

const CardImage = ({className, src, image, attribution, alt, lazyLoading = true, useSkeleton, ...props}) => {
  const [loading, setLoading] = useState(__CLIENT__);
  const loadingLazy = lazyLoading ? 'lazy' : null;

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div
      className={cs(
        `${prefix}-card__image-container`,
        {[`${prefix}-card__image--loading`]: loading, [`${prefix}-card__image--with-skeleton`]: useSkeleton},
        className,
      )}
      {...props}
    >
      {image ? (
        React.cloneElement(image, {loading: loadingLazy, onLoad: handleLoad})
      ) : (
        <img src={src} className={`${prefix}-card__image`} alt={alt} loading={loadingLazy} onLoad={handleLoad} />
      )}
      {attribution && <span className={`${prefix}-card__image__attribution`}>{attribution}</span>}
    </div>
  );
};

CardImage.displayName = 'CardImage';
CardImage.propTypes = {
  /* The CSS class names. */
  className: PropTypes.string,

  /* The image component to use. If provided, `src` will not have effect */
  image: PropTypes.node,

  /* The image src. One of `image` or `src` are required */
  src: PropTypes.string,

  /* Image attribution to add to the image */
  attribution: PropTypes.string,

  /* The alt text for the image */
  alt: PropTypes.string.isRequired,

  /* The loading="lazy" atribute for the image */
  lazyLoading: PropTypes.bool,

  /* If loading skeleton should be used */
  useSkeleton: PropTypes.bool,
};

export default CardImage;
