import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import './index.scss';

/**
 * A component to represent a series if entities. Entities can be any React
 * components. Series fires a callback for 'load more' events.
 * @param {string} childClassName A class name for item cells.
 * @param {string} className A class name for a component.
 * @param {Function} onLoadMore A callback handler for click event for
 *     'load more' button.
 * @param {number} size A manimum number of entities in the container.
 * @extends {React.Component}
 */
class EntitySeries extends Component {
  /**
   * Constructor, binds event handler.
   */
  constructor(...args) {
    super(...args);
    this.onLoadMore = this.onLoadMore.bind(this);
  }

  /**
   * Checks if series has more entities.
   * @return {boolean} True if series needs a 'load more' button.
   */
  hasMore() {
    const {children, size} = this.props;
    if (children && size) {
      return children.length < size;
    }

    return false;
  }

  /**
   * Handles a click to the 'load more' button. Calls parent's handler to
   * notify asbout the event.
   * @param {MouseEvent} clickEvent The event object.
   */
  onLoadMore(clickEvent) {
    const {onLoadMore} = this.props;
    if (clickEvent && clickEvent.target) {
      clickEvent.target.blur();
    }
    if (typeof onLoadMore === 'function') {
      onLoadMore();
    }
  }

  /**
   * Renders a component. Uses class names provided by parent component and
   * displays a series of entities.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {childClassName, className, children} = this.props;
    const _ = 'EntitySeries';
    const series = cs(className, _);
    const entityCollection = cs(`${_}__entityCollection`);
    const hasMore = this.hasMore();
    const loadMoreButton = cs(`${_}__loadMoreButton`);
    const entityCell = cs(`${_}__entityCell`, childClassName);
    const entities = children || [];

    return (
      <div className={series}>
        <div className={entityCollection}>
          {entities.map((entity, index) => {
            const key = (entity && entity.id) || index;

            return (
              <div className={entityCell} key={key}>
                {entity}
              </div>
            );
          })}
        </div>
        {hasMore && (
          <button className={loadMoreButton} onClick={this.onLoadMore} type="button">
            Load more
          </button>
        )}
      </div>
    );
  }
}

EntitySeries.propTypes = {
  childClassName: PropTypes.string,

  /**
   * The Class Name used to override the Styling for the component.
   */
  className: PropTypes.string,
  onLoadMore: PropTypes.func,
  size: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

EntitySeries.defaultProps = {
  childClassName: '',
  className: '',
  onLoadMore: () => undefined,
  size: 4,
};

export default EntitySeries;
