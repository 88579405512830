import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import prefix from '../../../utils/stylesPrefix';

import './CardContent.scss';

const CardContent = ({className, children, tag, ...props}) => {
  const Wrapper = tag || 'span';

  return (
    <Wrapper className={cs(`${prefix}-card__content`, className)} {...props}>
      {children}
    </Wrapper>
  );
};

CardContent.displayName = 'CardContent';
CardContent.propTypes = {
  /**
   * The CSS class names.
   */
  className: PropTypes.string,

  /**
   * The tag element to use for the wrapper. span by default
   */
  tag: PropTypes.string,

  /**
   * The child nodes
   */
  children: PropTypes.node,
};

export default CardContent;
