import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import Icons from './icons';

import './index.scss';

const DEFAULT_CELL_SIZE = 100;

/**
 * A component to represent a carousel for entities. Entities can be any React
 * components. Carousel handles scrolling to the beginning and to the end of the
 * list, and fires callbacks for these events.
 * @param {string} childClassName A class name for item cells.
 * @param {string} className A class name for a component.
 * @param {Function} onNext A callback handler for scroll to the next window
 *     event.
 * @param {Function} onPrev A callback handler for scroll to the previous
 *     window event.
 * @param {boolean} reset A flag to reset the cursor to the beginning of the
 *     list.
 * @param {number} size A manimum number of entities in the container.
 * @extends {React.Component}
 */
class EntityCarousel extends Component {
  /**
   * Constructor, initiates state and binds event handlers.
   */
  constructor(...args) {
    super(...args);
    this.state = {
      cursor: 0,
    };
    this.transition = false;
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
  }

  /**
   * Resets a cursor if reset property is present.
   * @param {React.Props} nextProps New properties.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {reset} = this.props;
    if (nextProps.reset && nextProps.reset !== reset) {
      this.setState({cursor: 0});
    }
  }

  /**
   * Checks cursor's position to the last view window.
   * @return {boolean} True if carousel needs a 'next' button.
   */
  hasNextButton() {
    const {children, size} = this.props;
    const {cursor} = this.state;
    if (children && size) {
      return cursor < children.length - size;
    }

    return false;
  }

  /**
   * Checks cursor's position to the first view window.
   * @return {boolean} True if carousel needs a 'prev' button.
   */
  hasPrevButton() {
    const {cursor} = this.state;

    return cursor > 0;
  }

  /**
   * Handles a click to the 'next' button. Verifies if scroll fits into the
   * next view window. Calls parent's handler to pass new cursor value.
   */
  onNext() {
    const {children, size, onNext} = this.props;
    const {cursor} = this.state;
    let nextCursor = cursor + size;
    if (!children || !size) {
      nextCursor = 0;
    } else if (nextCursor > children.length - size) {
      nextCursor = children.length - size;
    }
    this.transition = true;
    this.setState({cursor: nextCursor});
    if (typeof onNext === 'function') {
      onNext(nextCursor);
    }
  }

  /**
   * Handles a click to the 'prev' button. Verifies if scroll fits into the
   * previous view window. Calls parent's handler to pass new cursor value.
   */
  onPrev() {
    const {size, onPrev} = this.props;
    const {cursor} = this.state;
    let nextCursor = cursor - size || 0;
    if (nextCursor < 0) {
      nextCursor = 0;
    }
    this.transition = true;
    this.setState({cursor: nextCursor});
    if (typeof onPrev === 'function') {
      onPrev(nextCursor);
    }
  }

  /**
   * Renders a component. Uses class names provided by parent component and
   * displays a carousel with entities.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {childClassName, className, size, children} = this.props;
    const {cursor} = this.state;
    const _ = 'EntityCarousel';
    const carousel = cs(className, _);
    const container = cs(`${_}__viewContainer`);
    const viewArea = cs(`${_}__viewArea`);
    const entityCollection = cs(`${_}__entityCollection`);
    const nextButton = cs(`${_}__nextButton`, this.hasNextButton() ? `${_}__controlButton--isActive` : '');
    const prevButton = cs(`${_}__prevButton`, this.hasPrevButton() ? `${_}__controlButton--isActive` : '');
    const entityCell = cs(`${_}__entityCell`, childClassName);

    const cellSize = DEFAULT_CELL_SIZE / size;
    const offset = cursor * cellSize;
    const collectionStyle = {
      transform: `translate3d(-${offset}%,0,0)`,
      transition: this.transition ? 'transform 0.5s ease' : '',
    };
    const entityStyle = {
      maxWidth: `${cellSize}%`,
      width: `${cellSize}%`,
    };
    this.transition = false;
    const entities = children || [];

    return (
      <div className={carousel}>
        <button className={prevButton} aria-label="Previous" onClick={this.onPrev} type="button">
          <Icons.Chevron />
        </button>
        <div className={container}>
          <div className={viewArea}>
            <div className={entityCollection} style={collectionStyle}>
              {entities.map((entity, index) => {
                const key = (entity && entity.id) || index;

                return (
                  <div className={entityCell} key={key} style={entityStyle}>
                    {entity}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <button className={nextButton} aria-label="Next" onClick={this.onNext} type="button">
          <Icons.Chevron />
        </button>
      </div>
    );
  }
}

EntityCarousel.propTypes = {
  childClassName: PropTypes.string,

  /**
   * The Class Name used to override the Styling for the component.
   */
  className: PropTypes.string,

  /**
   * Next method
   */
  onNext: PropTypes.func,

  /**
   * Prev method
   */
  onPrev: PropTypes.func,

  /**
   * Reset method
   */
  reset: PropTypes.bool,

  /**
   * Carousel elements size
   */
  size: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

EntityCarousel.defaultProps = {
  childClassName: '',
  className: '',
  onNext: () => undefined,
  onPrev: () => undefined,
  reset: false,
  size: 5,
};

export default EntityCarousel;
