import Loadable from 'react-loadable';

/*
 * Here we are using dynamic imports for childlinks because we only reqire to load react-child-links
 * module for category pages
 */
const ChildSeoLinksLazyLoad = Loadable({
  loader: () => import(/* webpackChunkName: "swi-ChildLinks" */ './container'),
  loading: () => null,
});

export default ChildSeoLinksLazyLoad;
