import React from 'react';
import PropTypes from 'prop-types';

import SEOJSONLDElement from '../../../modules/react-seo-jsonld-element';

// Schema markup constants
const SCHEMA_ORG_URL = 'http://schema.org';
const SCHEMA_TYPE_ORGANIZATION = 'Organization';
const SCHEMA_TYPE_WEBSITE = 'WebSite';
const SCHEMA_TYPE_SEARCH_ACTION = 'SearchAction';
const STUBHUB_FACEBOOK_URL = 'https://www.facebook.com/StubHub';
const STUBHUB_TWITTER_URL = 'https://www.twitter.com/stubhub';
const STUBHUB_INSTAGRAM_URL = 'https://www.instagram.com/stubhub/';
const STUBHUB_WIKIPEDIA_URL = 'https://en.wikipedia.org/wiki/StubHub';
const STUBHUB_OFFICIAL_NAME = 'StubHub';
const STUBHUB_LOGO_URL =
  'https://media.stubcloudstatic.com/stubhub-product/image/upload/v1621550995/GraphicsIllustrationsMarks/StubhubLogoDefault.svg';

const getSchemaDomainPrefixByWebTLD = (/* istanbul ignore next */ webTLD = 'com') => `https://www.stubhub.${webTLD}/`;

const getSearchURLDomainPrefixByWebTLD = (/* istanbul ignore next */ webTLD = 'com') =>
  `https://www.stubhub.${webTLD}/find/s/?q={search_term}`;

/**
 * Build stubhub logo for search engine
 * @param {string} webTLD
 * @returns {string} logo related JSONLD string
 */
const getLogoJSONLD = (webTLD) => {
  const logoJSONLD = {
    '@context': SCHEMA_ORG_URL,
    '@type': SCHEMA_TYPE_ORGANIZATION,
    name: STUBHUB_OFFICIAL_NAME,
    url: getSchemaDomainPrefixByWebTLD(webTLD),
    logo: STUBHUB_LOGO_URL,
    sameAs: [STUBHUB_FACEBOOK_URL, STUBHUB_TWITTER_URL, STUBHUB_INSTAGRAM_URL, STUBHUB_WIKIPEDIA_URL],
  };

  return JSON.stringify(logoJSONLD);
};

/**
 * Build website search box for search engine
 * @param {string} webTLD
 * @returns {string} website search related JSONLD string
 */
const getWebsiteSearchJSONLD = (webTLD) => {
  const searchJSONLD = {
    '@context': SCHEMA_ORG_URL,
    '@type': SCHEMA_TYPE_WEBSITE,
    url: getSchemaDomainPrefixByWebTLD(webTLD),
    potentialAction: {
      '@type': SCHEMA_TYPE_SEARCH_ACTION,
      Target: getSearchURLDomainPrefixByWebTLD(webTLD),
      'Query-input': 'required name=search_term',
    },
  };

  return JSON.stringify(searchJSONLD);
};

const HomeSEOContent = ({defaultWebTLD}) => {
  const logoJSONLD = getLogoJSONLD(defaultWebTLD);
  const websiteSearchJSONLD = getWebsiteSearchJSONLD(defaultWebTLD);

  return (
    <div>
      <SEOJSONLDElement jsonld={logoJSONLD} />
      <SEOJSONLDElement jsonld={websiteSearchJSONLD} />
    </div>
  );
};

HomeSEOContent.propTypes = {
  defaultWebTLD: PropTypes.string,
};

export default HomeSEOContent;
