/*
 **  Configuration to determine which attributes are shown in the entity card
 **  Notes:
 **  1) Force stacked container on everything at medium and below breakpoint
 **  2) If useCarousel is set to false, use this value instead of user provided prop
 */

export const ConfigMapping = {
  event: {
    small: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 2,
      },
    },
    medium: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 6,
        subsequentFetchCount: 6,
        itemsPerRow: 3,
      },
    },
    large: {
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 4,
      },
      carousel: {
        initialFetchCount: 4,
        subsequentFetchCount: 4,
        itemsPerRow: 4,
      },
    },
    extraLarge: {
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 4,
      },
      carousel: {
        initialFetchCount: 4,
        subsequentFetchCount: 4,
        itemsPerRow: 4,
      },
    },
  },
  performer: {
    small: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 2,
      },
    },
    medium: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 6,
        subsequentFetchCount: 6,
        itemsPerRow: 3,
      },
    },
    large: {
      stacked: {
        initialFetchCount: 10,
        subsequentFetchCount: 10,
        itemsPerRow: 5,
      },
      carousel: {
        initialFetchCount: 5,
        subsequentFetchCount: 5,
        itemsPerRow: 5,
      },
    },
    extraLarge: {
      stacked: {
        initialFetchCount: 10,
        subsequentFetchCount: 10,
        itemsPerRow: 5,
      },
      carousel: {
        initialFetchCount: 5,
        subsequentFetchCount: 5,
        itemsPerRow: 5,
      },
    },
  },
  eventList: {
    small: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 2,
      },
    },
    medium: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 6,
        subsequentFetchCount: 6,
        itemsPerRow: 3,
      },
    },
    large: {
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 4,
      },
      carousel: {
        initialFetchCount: 4,
        subsequentFetchCount: 4,
        itemsPerRow: 4,
      },
    },
    extraLarge: {
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 4,
      },
      carousel: {
        initialFetchCount: 4,
        subsequentFetchCount: 4,
        itemsPerRow: 4,
      },
    },
  },
  performerEventList: {
    small: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 2,
      },
    },
    medium: {
      useCarousel: false,
      stacked: {
        initialFetchCount: 6,
        subsequentFetchCount: 6,
        itemsPerRow: 3,
      },
    },
    large: {
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 4,
      },
      carousel: {
        initialFetchCount: 4,
        subsequentFetchCount: 4,
        itemsPerRow: 4,
      },
    },
    extraLarge: {
      stacked: {
        initialFetchCount: 8,
        subsequentFetchCount: 8,
        itemsPerRow: 4,
      },
      carousel: {
        initialFetchCount: 4,
        subsequentFetchCount: 4,
        itemsPerRow: 4,
      },
    },
  },
};
