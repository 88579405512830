import {stringify} from 'qs';

import {get} from '@stubhub/rest-method';

import DateTimeFormat from '../../utils/datetimeformat';

const DEFAULT_SEARCH_CACHE_MAX_AGE = 300; // 5 min
const SEARCH_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || DEFAULT_SEARCH_CACHE_MAX_AGE;

// NOTES: search/catalog/events/v3 API https://wiki.stubcorp.com/pages/viewpage.action?pageId=20354722

function getSearchUrl(queryParams, cookies) {
  const {
    point,
    shstore = 1,
    minAvailableTickets = 1,
    geoExpansion = false,
    parking = false,
    sort = 'popularity',
    highValueProfileIndicator = false, // Disable pinned/high priority events for now
  } = queryParams;

  const {offset, limit, dateRange, id, performerId, visitorId, userGuid} = getParams(queryParams, cookies);

  const opts = {
    start: offset,
    point,
    limit,
    shstore,
    date: dateRange,
    visitorId,
    userGuid,
    geoExpansion,
    highValueProfileIndicator,
    performerId,
    id,
    minAvailableTickets,
    parking,
    sort,
  };

  return `/search/catalog/events/v3?${stringify(opts)}`;
}

export function getSearchEntity(queryParams, cookies, lang) {
  const cache = true;
  const cacheMaxAge = SEARCH_CACHE_MAX_AGE;

  return get({
    host: process.env.REACT_APP_API_HOST,
    path: getSearchUrl(queryParams, cookies),
    headers: _getFetchRequestHeaders(lang),
    json: true,
    cache,
    cacheMaxAge,
  }).then((body) => {
    return processBody(body);
  });
}

function getParams(queryParams, cookies) {
  const {eventStartDate, eventEndDate, offset, performerId, id} = queryParams;
  const newQueryParams = {...queryParams};
  newQueryParams.offset = offset;

  if (eventStartDate && eventEndDate) {
    newQueryParams.dateRange = `${DateTimeFormat.toAPIString(eventStartDate)} TO ${DateTimeFormat.toAPIString(
      eventEndDate,
    )}`;
  }

  // TODO: id and performerId list must have a space and a pipe after a value, ie: 324 |234324 |2344
  if (performerId) {
    newQueryParams.performerId = performerId.join(' |');
  }
  if (id) {
    newQueryParams.id = id.join(' |');
  }

  if (cookies) {
    newQueryParams.visitorId = cookies.get('SH_VI');
    newQueryParams.userGuid = cookies.get('track_session_userGUID');
  }

  return newQueryParams;
}

function _getFetchRequestHeaders(language) {
  const ret = {
    Authorization: process.env.REACT_APP_API_SECRET,
  };
  /* istanbul ignore if */
  if (language) {
    ret['Accept-Language'] = language;
  }

  return ret;
}

function processBody(body) {
  /* istanbul ignore next */
  const {numFound = 0, events = []} = body;

  return {
    totalCount: numFound,
    data: events,
  };
}
