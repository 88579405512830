import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GenrePerformersContainer from '../../../modules/react-genre-performers';

import GENRES from './genres.json';
export {default as CategoryPageBanner} from './banner';

import './index.scss';

/**
 * Top categories.
 */
const CATEGORIES = {
  1: 'concerts',
  28: 'sports',
  174: 'theater',
};

/**
 * A component to represent a category page.
 * @param {string} categoryId A category id for the page.
 * @extends {React.Component}
 */
class CategoryPage extends Component {
  /**
   * Renders a page. Uses genres list and renders a GenrePerformers component
   * for each individual genre.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {categoryId} = this.props;
    const categoryKey = CATEGORIES[categoryId];
    const genres = GENRES[categoryKey] || [];

    return (
      <>
        {genres.map((genre, index) => {
          if (index) {
            const key = genre.ids || index;

            return <GenrePerformersContainer key={key} genre={genre} {...this.props} />;
          }

          return null;
        })}
      </>
    );
  }
}

CategoryPage.propTypes = {
  /** Id of category */
  categoryId: PropTypes.string.isRequired,
};

CategoryPage.defaultProps = {
  categoryId: '',
};

export default CategoryPage;
