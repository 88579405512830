import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {injectIntl, defineMessages} from 'react-intl';

import {ENTITY_TYPE_EVENT, ENTITY_TYPE_PERFORMER} from '@stubhub/entity-helper';
import StackedContainer from '@stubhub/react-stacked-container';
import {ssr_wait} from '@stubhub/react-store-provider';

import getEventsJSONLDMeta, {checkJSONLDAndMicroDataEnabled} from '../../helpers/jsonld-helper';
import EntityCard from '../react-entity-card';
import LazyLoadCarousel from '../react-lazyload-carousel';
import LoadMoreButton from '../react-loadmore-button';
import SEOSJONLDElement from '../react-seo-jsonld-element';
import UIButton from '../react-uikit-button';

import {ConfigMapping} from './config-mapping';

import './index.scss';

export const STACKED = 'stacked';
export const CAROUSEL = 'carousel';

const JSONLD_MAXCOUNT = 10;

const messages = defineMessages({
  loadingButtonText: {
    id: 'GenreModule.loadMore.loading',
    defaultMessage: 'Loading',
    description: 'Loading text',
  },
});

class EntityList extends React.Component {
  constructor(props, context) {
    super(props, context);
    const {intl} = props;
    this.loadingButtonText = intl.formatMessage(messages.loadingButtonText);
  }

  @ssr_wait
  init() {
    return this.fetchEntity(true);
  }

  UNSAFE_componentWillMount() {
    const {entityData} = this.props;
    if (!entityData || entityData.length === 0) {
      this.init();
    }
  }

  componentWillUnmount() {
    const {destroy} = this.props;
    if (typeof destroy === 'function') {
      destroy();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {eventEndDate, eventStartDate, point, categoryId, performerIds} = this.props;
    if (
      nextProps.resizeReset === true ||
      nextProps.eventEndDate !== eventEndDate ||
      nextProps.eventStartDate !== eventStartDate ||
      nextProps.point !== point ||
      nextProps.categoryId !== categoryId ||
      nextProps.performerIds !== performerIds
    ) {
      return this.fetchEntity(true, nextProps);
    }
  }

  fetchEntity = (reset, nextProps = null) => {
    const {type, noFetch, initialFetchCount, subsequentFetchCount, fetchEntity} = this.props;
    const {cookies} = this.context;
    let newProps = {...this.props, cookies};

    if (noFetch) {
      return;
    }

    if (nextProps) {
      newProps = {...nextProps, cookies};
    }

    // Need to pass in new props otherwise it will fetch using previous screen size
    const config = this.getConfig(newProps);

    if (reset) {
      newProps.limit = initialFetchCount || config.initialFetchCount;
    } else {
      newProps.limit = subsequentFetchCount || config.subsequentFetchCount;
    }

    return fetchEntity(type, newProps, reset);
  };

  carouselFetchMore = () => {
    return this.fetchEntity(false);
  };

  stackedFetchMore = () => {
    return this.fetchEntity(false);
  };

  getConfig = (newProps) => {
    const {type, screenSize, useCarousel: propUseCarousel, forceCarousel} = newProps;
    const configMapping = ConfigMapping[type][screenSize];
    let config = null;

    if (forceCarousel) {
      config = configMapping.carousel || {};
      config.type = CAROUSEL;
    } else if (typeof configMapping.useCarousel === 'undefined') {
      // If config mapping doesn't have a value for useCarousel, use the provided prop or fall back to stacked
      if (propUseCarousel === true) {
        config = configMapping.carousel;
        config.type = CAROUSEL;
      } else {
        config = configMapping.stacked;
        config.type = STACKED;
      }
    } else {
      config = configMapping.stacked;
      config.type = STACKED;
    }

    return config;
  };

  createEntityList() {
    const {
      entityData,
      hasMore,
      totalCount,
      initialSliderFrameWidth,
      perPage,
      resetPage,
      hideEventVenue,
      className,
      carouselScrollMode,
      autoScaleMode,
      startPage,
      loading,
      isRedirectModalEnabled,
    } = this.props;
    let {type} = this.props;
    const config = this.getConfig(this.props);

    if (type !== ENTITY_TYPE_PERFORMER) {
      type = ENTITY_TYPE_EVENT;
    }

    // Create list of entities
    const entitiesComp = entityData.map((entity, index) =>
      entity ? (
        <EntityCard
          hideEventVenue={hideEventVenue}
          key={index}
          entityData={entity}
          type={type}
          className={className}
          isRedirectModalEnabled={isRedirectModalEnabled}
        />
      ) : (
        RenderLoadingState(index)
      )
    );

    if (config.type === CAROUSEL) {
      return (
        <LazyLoadCarousel
          perPage={perPage || config.itemsPerRow}
          totalCount={totalCount}
          initialSliderFrameWidth={initialSliderFrameWidth}
          loadEvents={this.carouselFetchMore}
          startPage={startPage}
          resetPage={resetPage}
          carouselScrollMode={carouselScrollMode}
          autoScaleMode={autoScaleMode}
        >
          {entitiesComp}
        </LazyLoadCarousel>
      );
    }
    // Stacked container

    // Set up classes for stacked container (display four or five)
    let stackedContainerClassName = '';
    /* istanbul ignore else */
    if (type === 'performer') {
      stackedContainerClassName = 'fiveItems';
    } else if (type === 'event') {
      stackedContainerClassName = 'fourItems';
    }

    // Container for loadmore button and loading states
    let loadMoreComp = null;
    if (loading) {
      loadMoreComp = (
        <div className="EntityList__LoadMore">
          <UIButton text={this.loadingButtonText} secondary size="tiny" flow="main" />
        </div>
      );
    } else if (hasMore) {
      /* Using uikit button but overriding the color for now since it doesn't support black */
      loadMoreComp = (
        <div className="EntityList__LoadMore">
          <LoadMoreButton onClick={this.stackedFetchMore} />
        </div>
      );
    }

    return (
      <div>
        <StackedContainer className={stackedContainerClassName}>{entitiesComp}</StackedContainer>
        {loadMoreComp}
      </div>
    );
  }

  render() {
    const {entityData, title, type, promoteHeaderEl} = this.props;
    const {globalRegistry} = this.context;
    const webTLD = globalRegistry.getDefaultWebTLD() || 'com';
    const redirectionDomain =
      globalRegistry.getPropertyValue(`tb.eventpage.url.domain.${webTLD}`) || 'https://intl.stubhub.com';

    if (entityData.length > 0) {
      // JSONLD meta for events
      const {jsonldToggle} = checkJSONLDAndMicroDataEnabled(globalRegistry, entityData);
      const jsonld =
        jsonldToggle && type === 'event'
          ? getEventsJSONLDMeta(entityData, '', webTLD, JSONLD_MAXCOUNT, redirectionDomain)
          : null;

      const entityListContainer = this.createEntityList();

      return (
        <div className={cx('EntityList', type)}>
          {promoteHeaderEl
            ? title && <div className="EntityList__Title">{title}</div>
            : title && <div className="EntityList__Title">{title}</div>}
          <div className="EntityList__Content">{entityListContainer}</div>
          {jsonldToggle && jsonld && <SEOSJONLDElement jsonld={jsonld} />}
        </div>
      );
    }

    return null;
  }
}

EntityList.contextTypes = {
  cookies: PropTypes.object,
  queue: PropTypes.object,
  globalRegistry: PropTypes.object,
};

EntityList.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  categoryId: PropTypes.number,
  performerIds: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  useCarousel: PropTypes.bool,
  noFetch: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  passUserInfo: PropTypes.bool,
  fetchEntity: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  screenSize: PropTypes.string.isRequired,
  entityData: PropTypes.array.isRequired,
  intl: PropTypes.object,
  initialFetchCount: PropTypes.number,
  subsequentFetchCount: PropTypes.number,

  /**
   * The Class Name used to override the Styling for the component.
   */
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  forceCarousel: PropTypes.bool,
  carouselScrollMode: PropTypes.bool,
  autoScaleMode: PropTypes.bool,

  /**
   * Event start date
   */
  eventStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  /**
   * Event end date
   */
  eventEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  point: PropTypes.string,
  destroy: PropTypes.func,
  promoteHeaderEl: PropTypes.bool,
  hasMore: PropTypes.bool,
  totalCount: PropTypes.number,
  initialSliderFrameWidth: PropTypes.number,
  perPage: PropTypes.number,
  resetPage: PropTypes.bool,
  hideEventVenue: PropTypes.bool,
  startPage: PropTypes.number,
  loading: PropTypes.bool,

  /**
   * Is redirection modal enabled?
   */
  isRedirectModalEnabled: PropTypes.bool,
};

EntityList.defaultProps = {
  title: null,
  useCarousel: false,
  categoryId: 0,
  entityData: [],
  screenSize: 'large' /* just defaulting for SSR */,
  initialFetchCount: 0,
  subsequentFetchCount: 0,
};

function RenderLoadingState(index = 0) {
  const _ = 'SubGenreEventCard';
  const placeholder = cx(`${_}__placeholder`);
  const placeholderImage = cx(`${_}__placeholderImage`);
  const placeholderName = cx(`${_}__placeholderName`);
  const placeholderTime = cx(`${_}__placeholderTime`);
  const placeholderVenue = cx(`${_}__placeholderVenue`);

  return (
    <div className={placeholder} key={index}>
      <div className={placeholderImage} />
      <div className={placeholderTime} />
      <div className={placeholderName} />
      <div className={placeholderVenue} />
    </div>
  );
}

export default injectIntl(EntityList);
export {RenderLoadingState};
