import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {Panel} from '@stubhub/bamboo';

import './index.scss';

const SearchNoResultsPanel = ({className = 'SearchNoResultsPanel', title, message}) => {
  const header = <div className={`${className}__Header`}>{title}</div>;
  const content = <div className={`${className}__Content`}>{message}</div>;

  return <Panel border={false} className={`${className} card`} header={header} content={content} />;
};

// Allow user to customize message strings
SearchNoResultsPanel.defaultProps = {
  title: <FormattedMessage id="SearchNoResultsPanel.title" defaultMessage="No events" />,
  message: (
    <FormattedMessage
      id="SearchNoResultsPanel.message"
      defaultMessage="There aren't any events on the horizon right now."
    />
  ),
};

SearchNoResultsPanel.propTypes = {
  /**
   * Heading title of panel
   */
  title: PropTypes.element,

  /**
   * Content message of panel
   */
  message: PropTypes.element,

  /**
   * Css class name
   */
  className: PropTypes.string,
};

export default SearchNoResultsPanel;
