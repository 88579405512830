import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import {keys, matches} from '../../utils/keyboard';
import prefix from '../../utils/stylesPrefix';

import './index.scss';

const ClickableTile = ({className, href, rel, clicked = false, isInternal, onClick, onKeyDown, children, ...props}) => {
  const handleClick = (evt) => {
    onClick && onClick(evt);
  };

  const handleKeyDown = (evt) => {
    if (matches(evt, [keys.Enter])) {
      evt.preventDefault();
      onKeyDown && onKeyDown(evt);
    }
  };

  const classes = cx(
    `${prefix}-tile`,
    `${prefix}-tile--clickable`,
    {
      [`${prefix}-tile--clicked`]: clicked,
    },
    className,
  );

  const linkProps = {
    className: classes,
    rel,
    onClick: handleClick,
    onKeyDown: handleKeyDown,
    children,
    ...props,
  };

  return isInternal ? <Link to={href} {...linkProps} /> : <a href={href} {...linkProps} />;
};

ClickableTile.displayName = 'ClickableTile';
ClickableTile.propTypes = {
  /**
   * The child nodes.
   */
  children: PropTypes.node,

  /**
   * The CSS class names.
   */
  className: PropTypes.string,

  /**
   * Boolean for whether a tile has been clicked.
   */
  clicked: PropTypes.bool,

  /**
   * The href for the link.
   */
  href: PropTypes.string,

  /**
   * Specify the function to run when the ClickableTile is clicked
   */
  onClick: PropTypes.func,

  /**
   * Specify the function to run when the ClickableTile is interacted with via a keyboard
   */
  onKeyDown: PropTypes.func,

  /**
   * The rel property for the link.
   */
  rel: PropTypes.string,

  /**
   * If link is internal to the app
   */
  isInternal: PropTypes.bool,
};

export default ClickableTile;
