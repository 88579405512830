import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';

import notFoundMessages from './messages';

import './index.scss';

/**
 * A component to represent an empty genre. An empty genre is a genre that
 * received a zero performers from an API call. Any genre can have an empty
 * state when applied geo and date filters.
 * @param {string} className A class name for component's container.
 * @extends {React.Component}
 */
@injectIntl
class GenreEmptyMessage extends Component {
  /**
   * Renders a component. Uses a class name provided by parent component and
   * displays a localized message.
   * @return {React.Element} A rendered component.
   */
  render() {
    const {className} = this.props;
    const _ = 'GenreEmptyMessage';
    const container = cs(className, _);
    const titleClass = cs(`${_}__title`);
    const descriptionClass = cs(`${_}__description`);

    return (
      <div className={container}>
        <div className={titleClass}>
          <FormattedMessage {...notFoundMessages.title} />
        </div>
        <div className={descriptionClass}>
          <FormattedMessage {...notFoundMessages.description} />
        </div>
      </div>
    );
  }
}

GenreEmptyMessage.propTypes = {
  /**
   * The Class Name used to override the Styling for the component.
   */
  className: PropTypes.string,
};

GenreEmptyMessage.defaultProps = {
  className: '',
};

export default GenreEmptyMessage;
